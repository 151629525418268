import React, { PropsWithChildren, ReactNode } from 'react';
import { Drawer as MuiDrawer, Box, SxProps } from '@mui/material';
import { SvgWrapper } from 'components/svg';
import { ReactComponent as CloseIcon } from 'assets/svg/chevron-right-double.svg';
import { ReactComponent as ChevronLeft } from 'assets/svg/chevronLeft.svg';
import { ReactComponent as ChevronRightDouble } from 'assets/svg/chevron-right-double.svg';
import _ from 'lodash';
import { shadowsTheme } from 'themes/shadows';

interface DrawerProps {
  open: boolean;
  onClose: () => void;
  childrenBoxStyles?: SxProps;
  header?: ReactNode;
  headerText?: boolean;
}

const Drawer: React.FC<PropsWithChildren<DrawerProps>> = ({
  children,
  open,
  onClose,
  childrenBoxStyles,
  header,
  headerText
}) => {
  const onDrawerClose = () => {
    onClose();
  };

  const childrenBoxStyleProps = {
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: 'inherit'
  };

  // If container styles are passed then use to override existing styles
  if (childrenBoxStyles) {
    _.assign(childrenBoxStyleProps, childrenBoxStyles);
  }

  return (
    <MuiDrawer
      open={open}
      onClose={onDrawerClose}
      anchor="right"
      PaperProps={{
        sx: {
          height: '100%',
          width: '40%',
          backgroundColor: '#FFFFFF',
          borderTopLeftRadius: '0.75rem',
          borderBottomLeftRadius: '0.75rem',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '0.75rem',
          boxShadow: shadowsTheme.xSmallShadowSoft
        }
      }}
      ModalProps={{
        sx: {
          '& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop': {
            background: 'rgba(0, 0, 0, 0.18)'
          }
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignSelf: 'stretch',
          flexGrow: '0',
          position: 'sticky',
          borderBottom: '0.063rem solid #F2F4F7',
          paddingLeft: '1.25rem'
        }}
      >
        {header ? (
          <Box
            display="flex"
            flexDirection={headerText ? 'row' : 'column'}
            alignItems={headerText ? 'center' : 'flex-start'}
            paddingBottom="0.5rem"
            mr="1.25rem"
            width="100%"
          >
            <Box
              sx={{
                cursor: 'pointer',
                display: 'flex',
                flexShrink: '1',
                mr: '0.5rem'
              }}
              onClick={onDrawerClose}
            >
              <SvgWrapper
                icon={headerText ? ChevronLeft : ChevronRightDouble}
                width="1.5rem"
                height="1.5rem"
                styleOverrides={{ fill: 'none', stroke: '#98A2B3' }}
              />
            </Box>
            {header}
          </Box>
        ) : (
          <Box
            sx={{
              cursor: 'pointer',
              display: 'flex',
              flexShrink: '1',
              marginRight: 'auto'
            }}
            onClick={onDrawerClose}
          >
            <SvgWrapper
              icon={CloseIcon}
              width="1.5rem"
              height="1.5rem"
              styleOverrides={{ fill: 'none', stroke: '#98A2B3' }}
            />
          </Box>
        )}
      </Box>
      <Box sx={childrenBoxStyleProps}>{children}</Box>
    </MuiDrawer>
  );
};

export default Drawer;
