import React, { useEffect } from 'react';
import { theme } from 'themes/theme';
import { Box, Typography, Paper, Stack, Button } from '@mui/material';
import { FormInput } from 'components/form';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useUpdateProfileMutation } from 'services/api/userApi';
import { toast } from 'react-toastify';

type FormValues = {
  street_address: string;
  postal_code: string;
  state: string;
  country: string;
  city: string;
};

const AddressSettings = () => {
  const [updateProfile, { isSuccess, isError, error }] =
    useUpdateProfileMutation();

  const { handleSubmit, control } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      street_address: '',
      postal_code: '',
      state: '',
      country: '',
      city: ''
    }
  });

  const onSubmit: SubmitHandler<FormValues> = async data => {
    await updateProfile({
      address: {
        street_address: data.street_address,
        locality: data.state,
        country: data.country,
        administrative_area: data.city
      }
    });
  };

  interface MyError {
    data:
      | string
      | {
          email: string[];
        };
    status?: number;
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success('Profile updated!');
    }
    if (error !== undefined) {
      const myError = error as MyError;
      if (
        typeof myError.data === 'object' &&
        Object.values(myError.data).length > 0
      ) {
        toast.error(Object.values(myError.data)[0].join(', '));
      } else if (typeof myError.data === 'string') {
        toast.error(myError.data);
      } else {
        toast.error('An error occurred');
      }
    }
  }, [isSuccess, isError]);

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: `${theme.palette.common.white}`,
        border: `0.063rem solid ${theme.palette.common.cardBorder}`,
        borderRadius: '0.75rem',
        gap: '1.5rem',
        padding: '1.5rem',
        width: '100%'
      }}
    >
      <Box width="inherit">
        <Box
          width="inherit"
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography
            variant="bodyLargeSemibold"
            sx={{
              color: `${theme.palette.common.heading}`
            }}
          >
            Address
          </Typography>
          <Typography
            variant="bodyMediumRegular"
            sx={{
              color: `${theme.palette.common.labelColor}`
            }}
          >
            Information on your business location
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box width="inherit" sx={{ mt: '1.5rem' }}>
            <FormInput<FormValues>
              control={control}
              name="street_address"
              label="STREET ADDRESS"
            />

            <Stack width="100%" direction="column">
              <Stack width="100%" gap=".75rem" direction="row">
                <FormInput<FormValues>
                  control={control}
                  name="postal_code"
                  label="POSTAL CODE"
                />

                <FormInput<FormValues>
                  control={control}
                  name="country"
                  label="COUNTRY"
                />
              </Stack>
              <Stack width="100%" gap=".75rem" direction="row">
                <FormInput<FormValues>
                  control={control}
                  name="state"
                  label="STATE"
                />
                <FormInput<FormValues>
                  control={control}
                  name="city"
                  label="CITY/TOWN"
                />
              </Stack>
            </Stack>
          </Box>

          <Stack direction="row" display="flex" justifyContent="flex-end">
            <Button
              variant="text"
              sx={{
                borderRadius: '0.5rem',
                padding: '0.5rem 0.75rem',
                gap: '0.25rem',
                mr: '1.25rem',
                border: '0.094rem solid #F2F4F7',
                backgroundColor: `${theme.palette.common.white}`
              }}
            >
              <Typography
                variant="bodyMediumSemibold"
                sx={{
                  color: `${theme.palette.common.subheading}`
                }}
              >
                Cancel
              </Typography>
            </Button>

            <Button
              type="submit"
              variant="text"
              // disabled
              sx={{
                borderRadius: '0.5rem',
                padding: '0.5rem 0.75rem',
                gap: '0.25rem',
                border: '0.094rem solid #F2F4F7',
                backgroundColor: `${theme.palette.common.btnBackground}`
              }}
            >
              <Typography
                variant="bodyMediumSemibold"
                sx={{
                  color: `${theme.palette.common.white}`
                }}
              >
                Save Changes
              </Typography>
            </Button>
          </Stack>
        </form>
      </Box>
    </Paper>
  );
};

export default AddressSettings;
