import React from 'react';
import ellipse from 'assets/svg/ellipse.svg';
import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';
import { SvgWrapper } from 'components/svg';
import { Paper, Stack, Box, Typography } from '@mui/material';
import { Chip } from 'components/chip';
import { WorkflowApiData } from 'services/types/workflow';
import _ from 'lodash';

type WorkflowCardProps = {
  onClickWorkflow: () => void;
  workflow: WorkflowApiData;
};

const ProductReceiptWorkflowCard = ({
  onClickWorkflow,
  workflow
}: WorkflowCardProps) => {
  return (
    <Paper
      elevation={0}
      sx={{
        padding: '.75rem 1.5rem',
        width: 'inherit',
        height: '4.75rem',
        borderRadius: '0.75rem',
        border: '.0625rem solid #F2F4F7'
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="1rem"
        sx={{
          height: '100%',
          width: '100%'
        }}
      >
        <Stack
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          gap="1rem"
          sx={{
            width: '80%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              padding: '0.5rem',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '0.5rem',
              background: '#F2F4F7',
              height: '2.75rem'
            }}
          >
            <Typography
              variant="bodyMediumSemibold"
              color="linear-gradient(121deg, rgba(35, 147, 196, 0.50) 0%, rgba(29, 59, 123, 0.50) 48.22%, rgba(0, 0, 0, 0.50) 100%)"
            >
              {workflow.use_case === 'assignment' ? 'PRA' : 'PRE'}
            </Typography>
          </Box>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              variant="bodyMediumSemibold"
              color="#1D2939"
              sx={{
                width: '100%',
                mb: '.25rem'
              }}
            >
              Product Receipt {_.startCase(workflow.use_case)}
            </Typography>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: '100%'
              }}
            >
              <Typography variant="bodyMediumRegular" color="#98A2B3">
                Started by Morenikeji Dada
              </Typography>

              <Box
                component="img"
                src={ellipse}
                sx={{ margin: '0rem .5rem' }}
              />

              <Typography variant="bodyMediumRegular" color="#98A2B3">
                11 Sep, 2023 | 12:24pm
              </Typography>

              <Box
                component="img"
                src={ellipse}
                sx={{ margin: '0rem .5rem' }}
              />

              <Typography variant="bodyMediumRegular" color="#98A2B3">
                13MB
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="1rem"
          sx={{
            width: 'auto'
          }}
        >
          {workflow.completed ? (
            <Chip label="Completed" size="sm" color="success" icon />
          ) : (
            <Chip label="Not Completed" size="sm" color="warning" icon />
          )}

          <Box
            sx={{
              display: 'flex',
              padding: '0.5rem',
              background: '#FCFCFD',
              borderRadius: '6.25rem',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '0.5rem',
              cursor: 'pointer'
            }}
            onClick={() => onClickWorkflow()}
          >
            <SvgWrapper
              icon={ChevronRight}
              width="1.25rem"
              height="1.25rem"
              styleOverrides={{ stroke: '#667085', fill: 'none' }}
            />
          </Box>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ProductReceiptWorkflowCard;
