import React from 'react';
import { Box, Stack } from '@mui/material';
import OrganizationSettings from './organizationSettings';
import BusinessAddress from './businessAddress';

const OrganizationPage = () => {
  return (
    <Box width="inherit" sx={{ margin: '2.25rem 0rem' }}>
      <Stack width="100%" direction="row" gap="24px">
        <OrganizationSettings />
        <BusinessAddress />
      </Stack>
    </Box>
  );
};

export default OrganizationPage;
