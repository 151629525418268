import * as Yup from 'yup';

export const addBranchFormValidator = Yup.object({
  name: Yup.string().required('This field is required'),
  street_address_1: Yup.string().required('This field is required'),
  street_address_2: Yup.string(),
  state: Yup.string().required('This field is required'),
  country: Yup.string().required('This field is required'),
  locality: Yup.string()
});

export const inviteUsersManuallyFormValidator = Yup.object({
  members: Yup.array()
    .of(Yup.string())
    .min(1, 'Add at least 1 user')
    .max(9, 'You can only add 10 users at once')
    .required('This field is required')
    .test(
      'isValidNames',
      'Invalid name format',
      value =>
        value !== undefined && value.every(el => Yup.string().isValidSync(el))
    )
    .test(
      'isUniqueEmails',
      'Duplicate names detected',
      value => value === undefined || new Set(value).size === value.length
    )
});

export const bulkUploadFormValidator = Yup.object({
  file: Yup.mixed<FileList>()
    .nullable()
    .notRequired()
    .test('fileType', value => {
      if (!value || !value[0]) return true;
      return value[0].type === 'image/*';
    })
    .test('fileSize', 'File size is too large', value => {
      if (!value || !value[0]) return true;
      const maxSize = 160 * 1024;
      return value[0].size <= maxSize;
    })
});
