import React from 'react';
import { Paper, Box, Stack, Typography } from '@mui/material';
import { ReactComponent as Profile } from 'assets/svg/profile.svg';
import { ReactComponent as Alarm } from 'assets/svg/alarm-clock.svg';
import { Chip } from 'components/chip';
import { SvgWrapper } from 'components/svg';
import _ from 'lodash';
import { Button } from 'components/button';
import { useAppSelector } from 'services/hook';
import { formattedDate } from 'utilities/helperFunc/formatter';

interface PickupAgentProps {
  agentPhoto: string | null;
  agentName: string;
  agentPhoneNumber: string;
  agentEmail?: string;
  pickupDate?: string | null;
  onReschedule?: () => void;
}

const PickupAgentDetails = ({
  agentPhoto,
  agentName,
  agentPhoneNumber,
  agentEmail,
  pickupDate,
  onReschedule
}: PickupAgentProps) => {
  const { organizationType } = useAppSelector(state => state.auth);

  return (
    <Paper
      elevation={0}
      sx={{
        padding: '1rem',
        borderRadius: '0.75rem',
        backgroundColor: '#FCFCFD',
        border: '.0625rem solid #F5F6F7',
        width: '50%',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        gap: '.75rem'
      }}
    >
      <Stack
        width="100%"
        display="flex"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        height="3.8rem"
        sx={{
          borderBottom: '.0625rem solid #F5F6F7',
          padding: '.75rem .5rem'
        }}
      >
        <Stack alignItems="center" direction="row" gap=".5rem">
          {agentPhoto ? (
            <Box
              component="img"
              src={agentPhoto}
              sx={{
                width: '2rem',
                height: '2rem',
                borderRadius: '50%',
                objectFit: 'cover'
              }}
            />
          ) : (
            <SvgWrapper icon={Profile} width="2rem" height="2rem" />
          )}

          <Stack
            direction="column"
            sx={{
              width: 'auto'
            }}
          >
            <Typography variant="bodyMediumMedium" color="#98A2B3">
              Pickup agent details
            </Typography>
            <Typography variant="bodyMediumSemibold" color="#475467">
              {_.capitalize(agentName)}
            </Typography>
          </Stack>
        </Stack>
        <Box
          sx={{
            width: 'auto'
          }}
        >
          <Chip label={agentPhoneNumber} size="sm" color="secondary" />
        </Box>
      </Stack>

      <Stack
        width="100%"
        display="flex"
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        height="3.8rem"
        sx={{
          padding: '.75rem .5rem',
          borderBottom: `${pickupDate ? '.0625rem solid #F5F6F7' : 'none'}`
        }}
      >
        <Stack alignItems="center" direction="row" gap=".69rem">
          <Box
            sx={{
              borderRadius: '6.25rem',
              padding: '.5rem',
              background: '#F5F6F7',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <SvgWrapper
              icon={Alarm}
              width="1rem"
              height="1rem"
              styleOverrides={{ stroke: '#98A2B3', fill: 'none' }}
            />
          </Box>

          <Stack
            direction="column"
            sx={{
              width: 'auto'
            }}
          >
            <Typography variant="bodyMediumMedium" color="#98A2B3">
              Email
            </Typography>
            <Typography variant="bodyMediumMedium" color="#475467">
              {agentEmail}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {pickupDate !== null && (
        <Stack
          height="3.8rem"
          width="100%"
          display="flex"
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{
            padding: '.75rem .5rem'
          }}
        >
          <Stack alignItems="center" direction="row" gap=".69rem">
            <Box
              sx={{
                borderRadius: '6.25rem',
                padding: '.5rem',
                background: '#F5F6F7',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <SvgWrapper
                icon={Alarm}
                width="1rem"
                height="1rem"
                styleOverrides={{ stroke: '#98A2B3', fill: 'none' }}
              />
            </Box>

            <Stack
              direction="column"
              sx={{
                width: 'auto'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#98A2B3">
                Pickup date
              </Typography>
              <Typography variant="bodyMediumMedium" color="#475467">
                {pickupDate && formattedDate(pickupDate, 'Do MMM, YYYY')}
              </Typography>
            </Stack>
          </Stack>

          {onReschedule && organizationType !== 'merchant' && (
            <Button
              color="grey"
              transparent
              size="md"
              text="Reschedule pickup"
              width="auto"
              onClick={onReschedule}
            />
          )}
        </Stack>
      )}
    </Paper>
  );
};

export default PickupAgentDetails;
