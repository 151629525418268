import React from 'react';
import { Typography, Box, Menu, MenuItem } from '@mui/material';
import { Row } from '@tanstack/react-table';
import { SvgWrapper } from 'components/svg';
import { shadowsTheme } from 'themes/shadows';

interface TableMenuItem<TableDataT> {
  image: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  text: string;
  onClickMenuItem?: (rowData: TableDataT) => void;
  fill?: string;
}

export interface TableMenuProps<TableDataT> {
  open: boolean;
  anchorEl: HTMLTableCellElement | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLTableCellElement | null>
  >;
  id?: string;
  menuItemProps?: TableMenuItem<TableDataT>[];
  row: Row<TableDataT> | null;
}

export default function TableMenu<TableDataT>({
  id,
  open,
  anchorEl,
  setAnchorEl,
  menuItemProps,
  row
}: TableMenuProps<TableDataT>) {
  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      sx={{
        '& .MuiMenu-paper': {
          borderRadius: '0.75rem',
          border: '.0625rem solid #EAECF0',
          boxShadow: shadowsTheme.xxSmallShadowSoft
        }
      }}
      elevation={0}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      {menuItemProps &&
        menuItemProps.map((element, index) => {
          const { onClickMenuItem, text, image, fill } = element;
          return (
            <MenuItem
              key={index}
              sx={{
                padding: '.5rem .75rem',
                display: 'flex',
                gap: '.5rem'
              }}
              onClick={() => {
                onClickMenuItem &&
                  row !== null &&
                  onClickMenuItem(row.original);
                setAnchorEl(null);
              }}
            >
              <Box
                sx={{
                  borderRadius: '6.25rem',
                  padding: '.5rem',
                  background: '#F5F6F7',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <SvgWrapper
                  icon={image}
                  width="0.75rem"
                  height="0.75rem"
                  styleOverrides={{ fill: `${fill}` }}
                />
              </Box>
              <Typography variant="bodyMediumMedium" color="#475467">
                {text}
              </Typography>
            </MenuItem>
          );
        })}
    </Menu>
  );
}
