import { api } from './baseApi';
import { VaultMetaData } from 'services/types/vault';

export const vaultApi = api.injectEndpoints({
  endpoints: builder => ({
    getVaultMetaData: builder.query<VaultMetaData[], unknown>({
      query: () => ({
        url: 'supply_chain/({supplyChainProfileId})/product_receipts/vault-metadata',
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      providesTags: ['productReceiptMetadata']
    })
  }),
  overrideExisting: false
});

export const { useGetVaultMetaDataQuery, usePrefetch } = vaultApi;
