import React from 'react';
import { Box, Stack } from '@mui/material';

import ChangePassword from './changePassword';
import ResourcesCard from './resourcesCard';

const PrivacyPage = () => {
  return (
    <Box width="inherit" sx={{ mt: '32px' }}>
      <Stack width="100%" direction="row" gap="24px">
        <ChangePassword />
        <ResourcesCard />
      </Stack>
    </Box>
  );
};

export default PrivacyPage;
