import React, { useMemo } from 'react';
import { Box, Dialog, Stack, Typography } from '@mui/material';
import { ReactComponent as BankIcon } from 'assets/svg/bank.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/x.svg';
import { SvgWrapper } from 'components/svg';
import { Button } from 'components/button';
import ShowInfo from 'components/info/showInfo';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useAssignProductReceiptToBranchMutation } from 'services/api/productReceiptApi';
import { FormSelect, FormSelectOption } from 'components/form';
import { useGetBranchesQuery } from 'services/api/branchApi';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

type ProductReceiptWorkflowModalProps = {
  open: boolean;
  onClose: () => void;
};

type FormValues = {
  branch: string;
};
const emptyBranchOptions: FormSelectOption[] = [];

const ProductReceiptAssignmentWorkflowModal: React.FC<
  ProductReceiptWorkflowModalProps
> = ({ open, onClose }) => {
  const { productReceiptId } = useParams();
  const { data: branchData } = useGetBranchesQuery({});
  const navigate = useNavigate();

  const { watch, control } = useForm<FormValues>({
    mode: 'onTouched'
  });
  const selectedBranch = watch('branch');
  const [assignProductReceipt, { isLoading: isLoadingAssignment }] =
    useAssignProductReceiptToBranchMutation();

  const validatedBranches = useMemo(() => {
    if (branchData) {
      return branchData.map(branch => ({
        title: branch.name,
        value: branch.branch_id
      }));
    }
    return emptyBranchOptions;
  }, [branchData]);

  const onClickAssignToBranch = () => {
    if (!_.isUndefined(productReceiptId)) {
      assignProductReceipt({
        productReceiptId: productReceiptId,
        data: {
          branch: selectedBranch
        }
      })
        .unwrap()
        .then(() => {
          ShowInfo({
            message: 'PRC assigned successfully',
            subText: 'PRC has been assigned to branch',
            type: 'success',
            componentType: 'toast',
            hideProgressBar: false
          });
          onClose();
          // We need to navigate away from the central vault after assignment
          setTimeout(() => navigate('/product-receipts/'), 1000);
        })
        .catch(() => {
          ShowInfo({
            message: 'PRC not assigned',
            subText: 'PRC not assigned to branch!',
            type: 'error',
            componentType: 'toast',
            hideProgressBar: false
          });
        });
    }
  };
  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '24.5rem',
            borderRadius: '0.75rem',
            border: '2px solid #F5F6F7',
            backgroundColor: '#FCFCFD'
          }
        }
      }}
    >
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1.25rem',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            borderBottom: '2px solid #F5F6F7'
          }}
        >
          <Stack width="100%" direction="row" justifyContent="space-between">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '2.25rem',
                width: '2.25rem',
                borderRadius: '6.25rem',
                backgroundColor: '#E5EEFF',
                padding: '0.5rem',
                boxShadow: '0px 0px 0px 4px rgba(200, 219, 255, 0.20)',
                marginBottom: '1rem'
              }}
            >
              <SvgWrapper
                icon={BankIcon}
                width="1.25rem"
                height="1.25rem"
                styleOverrides={{
                  opacity: 0.8
                }}
                color="#C1D6FF"
              />
            </Box>
            <Box onClick={() => onClose()}>
              <SvgWrapper icon={CloseIcon} width="1.5rem" height="1.5rem" />
            </Box>
          </Stack>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center'
            }}
          >
            <Typography variant="h7Semibold" color="#344054">
              Assign to Branch
            </Typography>
            <Typography
              variant="bodyMediumRegular"
              color="#667085"
              sx={{ mt: '.5rem' }}
            >
              Select what branches you wish to assign this ATC to
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '0.75rem',
            margin: '1rem .75rem',
            backgroundColor: 'rgba(102, 112, 133, 0.02)',
            borderRadius: '0.5rem',
            border: '1px solid #F5F6F7'
          }}
        >
          <FormSelect<FormValues>
            name="branch"
            options={validatedBranches}
            label="Select Branch"
            control={control}
            renderSelected={value => value}
            size="small"
            placeholder="Select a branch"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: '1px solid #F5F6F7',
            padding: '1.25rem 1.5rem',
            marginBottom: '0.25rem'
          }}
        >
          <Button
            color="grey"
            transparent
            size="lg"
            text="Cancel"
            width="48%"
            onClick={() => onClose()}
          />
          <Button
            submit
            color="primary"
            size="lg"
            text="Proceed"
            disabled={selectedBranch === '' || _.isUndefined(selectedBranch)}
            width="48%"
            loadingIcon={isLoadingAssignment}
            onClick={() => onClickAssignToBranch()}
          />
        </Box>
      </>
    </Dialog>
  );
};
export default ProductReceiptAssignmentWorkflowModal;
