import React from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';
import { Drawer } from 'components/drawer';
import { FormInput } from 'components/form';
import { useForm } from 'react-hook-form';
import { TabContainer } from 'components/tabs';
import PermissionsAssigned from './permissionsAssigned';
import Roles from './roles';
// import Branches from './branches';
import { MemberWithDetails } from '../types/permissionTypes';

interface DrawerProps {
  open: boolean;
  onClose: () => void;
  data: MemberWithDetails;
}

type FormValues = {
  name: string;
};

const EditPermissions: React.FC<DrawerProps> = ({ open, onClose, data }) => {
  const { control } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      name: `${data.member.firstname} ${data.member.lastname}`
    }
  });

  const tabs = [
    {
      labelText: 'Roles',
      content: <Roles roles={data.member.roles} />
    },
    // {
    //   labelText: 'Branches',
    //   content: <Branches />
    // },
    {
      labelText: 'Permissions Assigned',
      content: <PermissionsAssigned userId={data.member.userid} />
    }
  ];

  const renderDrawerHeader = () => {
    return (
      <Box padding="1.25rem 1.25rem 1.25rem 0rem">
        <Stack
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          spacing={1}
        >
          <Typography variant="h6Bold" color="#475467">
            {data.member.firstname} {data.member.lastname}
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Stack direction="column">
              <Typography variant="captionXSmall" color="#98A2B3">
                Branch
              </Typography>
              <Typography variant="bodySmallMedium" color="#667085">
                Lagos-Ojumota
              </Typography>
            </Stack>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                width: '0.0625rem',
                height: '2.375rem',
                borderRadius: '0.125rem',
                borderLeft: '0.0625rem solid #F2F4F7',
                borderRight: '0.0625rem solid #F2F4F7',
                margin: '0rem 1.5625rem'
              }}
            />
            <Stack direction="column">
              <Typography variant="captionXSmall" color="#98A2B3">
                Permissions
              </Typography>
              <Typography variant="bodySmallMedium" color="#667085">
                {data.permissions.length}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      header={renderDrawerHeader()}
      childrenBoxStyles={{
        height: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Box padding="1rem 1.25rem">
        <Stack
          display="flex"
          padding="1.25rem"
          flexDirection="column"
          alignItems="flex-start"
          gap="1rem"
          alignSelf="stretch"
          sx={{
            background: '#FBFBFC',
            border: '0.063rem solid #F2F4F7',
            borderRadius: '0.75rem'
          }}
        >
          <Typography variant="bodyLargeSemibold" color="#475467">
            About
          </Typography>
          <FormInput<FormValues> control={control} name="name" label={'NAME'} />
        </Stack>
      </Box>
      <TabContainer
        variant="fullWidth"
        tabs={tabs}
        styleOverrides={{
          height: 'inherit'
        }}
      />
    </Drawer>
  );
};

export default EditPermissions;
