import React, { useContext } from 'react';
import { Dialog } from '@mui/material';
import { TeamsContext } from './teamsContext';

type CreateTeamModalProps = {
  open: boolean;
  onClose: () => void;
};

const CreateTeamModal: React.FC<CreateTeamModalProps> = ({ open, onClose }) => {
  const { steps, currentStep } = useContext(TeamsContext);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        padding: '1.25rem',
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            borderRadius: '0.75rem',
            border: '2px solid #F5F6F7',
            backgroundColor: '#FCFCFD'
          }
        }
      }}
    >
      {steps[currentStep].element}
    </Dialog>
  );
};

export default CreateTeamModal;
