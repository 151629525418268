import React, { useContext } from 'react';
import BulkFileUpload from './bulkFileUpload';
import ViewBulkFileUpload from './viewBulkFileUpload';
import { BranchesContext } from '../../branchesContext';

const BulkFileUploadStep = () => {
  const { uploadedFile } = useContext(BranchesContext);

  return (
    <>{uploadedFile !== null ? <ViewBulkFileUpload /> : <BulkFileUpload />}</>
  );
};

export default BulkFileUploadStep;
