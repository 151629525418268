export enum OrganizationType {
  MERCHANT = 'merchant',
  LENDER = 'lender',
  DISTRIBUTOR = 'distributor',
  END_USER = 'end_user'
}

export enum OrganizationMembershipType {
  SUPER_ADMIN = 'super_admin',
  ADMIN = 'admin',
  REGULAR = 'regular'
}
