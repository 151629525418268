import { LoginApiData } from 'services/types/auth';
import { api } from './baseApi';

type RegisterData = {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  confirmpassword: string;
};

type LoginData = {
  email: string;
  password: string;
  remember: boolean;
};

export const authApi = api.injectEndpoints({
  endpoints: builder => ({
    loginUser: builder.mutation<LoginApiData, LoginData>({
      query: data => {
        return {
          url: 'auth/login/',
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
      }
    }),
    registerUser: builder.mutation({
      query: (body: RegisterData) => ({
        url: 'users/register/',
        method: 'post',
        data: body
      })
    }),
    logoutUser: builder.mutation({
      query: () => ({
        url: 'auth/logout/',
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
    })
  }),
  overrideExisting: false
});

export const {
  useLoginUserMutation,
  useRegisterUserMutation,
  useLogoutUserMutation
} = authApi;
