import React, { useState, useMemo } from 'react';
import {
  Avatar,
  Box,
  Stack,
  Typography,
  CircularProgress
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, Resolver } from 'react-hook-form';
import { ReactComponent as Plus } from 'assets/svg/plus.svg';
import { ReactComponent as TrashSolid } from 'assets/svg/trashSolidGrey.svg';
import { SvgWrapper } from 'components/svg';
import { Button } from 'components/button';
import ShowInfo from 'components/info/showInfo';
import { FormSelect, FormSelectOption } from 'components/form';
import SearchableContainer from 'components/search/searchableContainer';
import RemoveUserModal from './removeUserModal';
import { addMemberValidator } from './addMemberValidator';
import { User, RoleDetail } from '../types/rolesTypes';
import { Member } from '../types/permissionTypes';
import { useGetMembersQuery } from 'services/api/organizationApi';
import { useCreateRoleRequestMutation } from 'services/api/permissionsApi';

type FormValues = {
  members: string[];
};

interface MembersProps {
  users: User[];
  role: RoleDetail;
}

const Members: React.FC<MembersProps> = ({ users, role }) => {
  const { data: members, isLoading: isLoadingMembers } = useGetMembersQuery({});
  const [createRoleRequest, { isLoading }] = useCreateRoleRequestMutation();
  const [showAddMember, setShowAddMember] = useState(false);
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      members: []
    },
    resolver: yupResolver(addMemberValidator) as unknown as Resolver<FormValues>
  });

  const memberOptions: FormSelectOption[] = useMemo(() => {
    if (!members) return [];

    const validMembers = members.filter(
      member => !users.some(user => user.email === member.email)
    );

    return validMembers.map((member: Member) => ({
      title: `${member.firstname} ${member.lastname}`,
      subtitle: member.email,
      value: member.email
    }));
  }, [members]);

  const onSubmit: SubmitHandler<FormValues> = async data => {
    createRoleRequest({
      roleId: role.role_id,
      data: {
        users: data.members
      }
    })
      .unwrap()
      .then(() => {
        ShowInfo({
          message: 'Role request sent!',
          subText: 'Requests for these users have been sent for approval',
          type: 'success',
          componentType: 'toast',
          hideProgressBar: false
        });
        setShowAddMember(false);
      })
      .catch(() => {
        ShowInfo({
          message: 'Role request failed!',
          subText: 'Role request failed',
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
      });
  };

  const renderData = (item: User) => {
    return (
      <Stack
        key={item.email}
        width="100%"
        mr="auto"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyItems="center"
      >
        <Stack
          display="flex"
          flexDirection="row"
          padding="0.5rem 0rem 0.5rem 1.5rem"
          width="45%"
          alignItems="center"
        >
          <Avatar
            src={item.profile_picture}
            sx={{
              width: '2rem',
              height: '2rem',
              mr: '0.75rem',
              justifyContent: 'center',
              borderRadius: '12.5rem'
            }}
          />
          <Stack display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="bodyMediumMedium" color="#344054">
              {item.firstname} {item.lastname}
            </Typography>
            <Typography variant="bodyMediumRegular" color="#667085">
              {item.email}
            </Typography>
          </Stack>
        </Stack>

        <Box
          sx={{
            display: 'flex',
            ml: 'auto',
            width: '12%',
            cursor: 'pointer',
            justifyContent: 'center'
          }}
          onClick={() => {
            setShowRemoveUserModal(true);
          }}
        >
          <SvgWrapper
            icon={TrashSolid}
            width="1rem"
            height="1rem"
            styleOverrides={{ fill: '#98A2B3' }}
          />
        </Box>
      </Stack>
    );
  };

  if (isLoadingMembers) {
    return (
      <Box
        sx={{
          width: 'inherit',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress size={32} />
      </Box>
    );
  }

  return (
    <>
      <RemoveUserModal
        open={showRemoveUserModal}
        onClose={() => {
          setShowRemoveUserModal(false);
        }}
      />
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '0.75rem 1.25rem',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: 'center',
          gap: '1rem',
          alignSelf: 'stretch',
          flexWrap: 'wrap'
        }}
      >
        <Stack direction="column">
          <Typography variant="bodyMediumSemibold" color="#475467">
            Team members
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            Assigned this role
          </Typography>
        </Stack>

        <Button
          color="grey"
          transparent
          size="sm"
          text="Add New"
          icon={Plus}
          iconPosition="start"
          onClick={() => setShowAddMember(true)}
        />
      </Stack>

      {showAddMember ? (
        <Box padding="0rem 1.25rem">
          <FormSelect<FormValues>
            options={memberOptions}
            control={control}
            label="ADD NEW MEMBER"
            name="members"
            multiselect
            renderSelected={(value: string) => (
              <Box
                sx={{
                  padding: '0.25rem 0.5rem',
                  backgroundColor: '#F5F6F7',
                  display: 'inline-block',
                  borderRadius: '0.5rem',
                  marginRight: '0.5rem'
                }}
              >
                <Typography color="#475467" variant="bodyMediumMedium">
                  {value}
                </Typography>
              </Box>
            )}
          />
        </Box>
      ) : (
        <Box padding="0rem 1.25rem">
          <SearchableContainer<User>
            searchPlaceholderText="Search here"
            data={users}
            renderDataList={renderData}
            containerStyles={{
              borderRadius: '0.75rem',
              border: '.0625rem solid #F2F4F7',
              background: 'rgba(102, 112, 133, 0.02)'
            }}
            searchField={['firstname', 'lastname']}
          />
        </Box>
      )}

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack
          sx={{
            display: 'flex',
            padding: '0.75rem 1.5rem',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            gap: '1rem',
            alignSelf: 'stretch',
            borderRadius: '0rem 0rem 0rem 0.75rem',
            borderTop: '0.063rem solid #F5F6F7',
            background: '#FCFCFD',
            mt: '.75rem'
          }}
        >
          {showAddMember && (
            <Stack direction="row" spacing={1}>
              <Button
                color="grey"
                transparent
                size="md"
                text="Cancel"
                onClick={() => setShowAddMember(false)}
              />
              <Button
                submit
                color="primary"
                size="md"
                text="Add Member"
                icon={Plus}
                iconPosition="start"
                disabled={!isValid}
                loadingIcon={isLoading}
              />
            </Stack>
          )}
        </Stack>
      </form>
    </>
  );
};

export default Members;
