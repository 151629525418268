import React from 'react';
import _ from 'lodash';

import { useForm, SubmitHandler } from 'react-hook-form';
import { Box, Stack, Typography, Checkbox } from '@mui/material';

import { ReactComponent as Dot } from 'assets/svg/dot.svg';
import { ReactComponent as plusIcon } from 'assets/svg/plus.svg';
import { Accordion } from 'components/accordion';
import { Button } from 'components/button';
import { Drawer } from 'components/drawer';
import { SvgWrapper } from 'components/svg';
import SearchableContainer from 'components/search/searchableContainer';
import ShowInfo from 'components/info/showInfo';
import Loading from 'components/loader/loading';
import {
  useGetAllGroupedPermissionsQuery,
  useAddRolePermissionsMutation
} from 'services/api/permissionsApi';
import { Checked, UnChecked } from 'components/form/formCheckBox';
import {
  PermissionType,
  PermissionTypeGroup
} from 'services/enums/permissions';
import { GroupedPermission } from '../types/permissionTypes';

type FormValues = {
  [permission in PermissionType]: boolean;
};

interface DrawerProps {
  open: boolean;
  onClose: () => void;
  roleId?: string;
  existingPermissions: PermissionType[];
}

const AddPermission: React.FC<DrawerProps> = ({
  open,
  onClose,
  roleId,
  existingPermissions
}) => {
  const { handleSubmit, register } = useForm<FormValues>();
  const { data: allGroupedPermissions, isLoading: isLoadingPermissions } =
    useGetAllGroupedPermissionsQuery({});

  const [addRolePermission, { isLoading }] = useAddRolePermissionsMutation();

  // transform the permissions data to the searchable container format
  const allPermissionsData: GroupedPermission[] = allGroupedPermissions
    ? Object.keys(allGroupedPermissions).map(category => ({
        category: category as PermissionTypeGroup,
        permissions: allGroupedPermissions[category]
      }))
    : [];

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const selectedPermissions: PermissionType[] = Object.keys(data).filter(
      permission => data[permission as PermissionType]
    ) as PermissionType[];
    const newPermissions = selectedPermissions.filter(
      permission => !existingPermissions.includes(permission)
    );

    addRolePermission({
      data: {
        permissions: newPermissions
      },
      roleId: roleId
    })
      .unwrap()
      .then(() => {
        ShowInfo({
          message: 'Role updated',
          subText: 'Role permissions have been updated',
          type: 'success',
          componentType: 'toast',
          hideProgressBar: false
        });
        onClose();
      })
      .catch(() => {
        ShowInfo({
          message: 'Role update failure',
          subText: 'Role permissions could not be updated',
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
        onClose();
      });
  };

  const renderData = (item: GroupedPermission) => {
    return (
      <Box
        sx={{
          backgroundColor: '#FCFCFD',
          borderRadius: '0.75rem',
          border: '0.094rem solid #F2F4F7',
          width: '100%',
          mb: '0.75rem'
        }}
      >
        <Accordion
          summary={
            <Stack width="25.6rem">
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                mb="0.25rem"
              >
                <SvgWrapper
                  icon={Dot}
                  width="0.375rem"
                  height="0.375rem"
                  styleOverrides={{ fill: '#3E7DF8' }}
                />
                <Typography variant="captionSmall" color="#667085">
                  {item.category}
                </Typography>
              </Stack>
            </Stack>
          }
        >
          {item.permissions.map(permission => {
            const disabled = existingPermissions.includes(
              permission.permission_name
            );
            return (
              <Stack
                key={permission.permission_name}
                display="flex"
                direction="row"
                padding="0.75rem 0rem"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Stack
                  sx={{
                    width: '90%',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap'
                  }}
                >
                  <Typography
                    variant="bodyMediumMedium"
                    color="#344054"
                    sx={{
                      opacity: disabled ? 0.5 : 1
                    }}
                  >
                    {_.startCase(permission.permission_name.replace(/_/g, ' '))}
                  </Typography>
                  <Typography
                    variant="bodyMediumMedium"
                    color="#98A2B3"
                    sx={{
                      opacity: disabled ? 0.5 : 1
                    }}
                  >
                    {permission.description}
                  </Typography>
                </Stack>
                <Checkbox
                  size="medium"
                  sx={{
                    width: '1.5rem',
                    height: '1.5rem',
                    borderRadius: '0.25rem'
                  }}
                  checkedIcon={<Checked disabled={disabled} />}
                  icon={<UnChecked />}
                  defaultChecked={disabled}
                  disabled={disabled}
                  {...register(permission.permission_name)}
                />
              </Stack>
            );
          })}
        </Accordion>
      </Box>
    );
  };

  const renderDrawerHeader = () => {
    return (
      <Typography variant="bodyMediumMedium" color="#475467">
        Permissions
      </Typography>
    );
  };

  if (isLoadingPermissions) {
    return <Loading loading={isLoadingPermissions} />;
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      header={renderDrawerHeader()}
      headerText
      childrenBoxStyles={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'inherit'
      }}
    >
      <Box padding="0rem 1.25rem">
        <Box padding="1.25rem 1.25rem 1.25rem 0rem" mb="auto">
          <Stack flexDirection="column">
            <Typography variant="bodyMediumSemibold" color="#475467">
              Add Permissions
            </Typography>
            <Typography variant="bodyMediumRegular" color="#667085">
              Set permissions for this team to decide what actions they can take
              on the dashboard.
            </Typography>
          </Stack>
        </Box>

        <SearchableContainer<GroupedPermission>
          searchPlaceholderText="Search here"
          data={allPermissionsData}
          renderDataList={renderData}
          searchField={['permissions.permission_name']}
        />
      </Box>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack
          sx={{
            display: 'flex',
            padding: '0.75rem 1.5rem',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            gap: '1rem',
            mt: 'auto',
            alignSelf: 'stretch',
            borderRadius: '0rem 0rem 0rem 0.75rem',
            borderTop: '0.063rem solid  #F5F6F7',
            background: '#FCFCFD'
          }}
        >
          <Stack direction="row" spacing={1}>
            <Button
              color="grey"
              transparent
              size="md"
              text="Cancel"
              onClick={onClose}
            />
            <Button
              submit
              color="primary"
              size="md"
              text="Save changes"
              icon={plusIcon}
              iconPosition="start"
              loadingIcon={isLoading}
            />
          </Stack>
        </Stack>
      </form>
    </Drawer>
  );
};

export default AddPermission;
