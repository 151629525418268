export const colorTheme = {
  // Basic
  primary: '#3E7DF8',
  success: '#1CA477',
  error: '#E1343F',
  warning: '#F8BA3F',

  // Grey
  grey25: '#FCFCFD',
  grey50: '#F5F6F7',
  grey100: '#F2F4F7',
  grey200: '#EAECF0',
  grey300: '#D0D5DD',
  grey400: '#98A2B3',
  grey500: '#667085',
  grey600: '#475467',
  grey700: '#344054',
  grey800: '#1D2939',
  grey900: '#101828',

  // Primary (Blue)
  primary50: '#ECF2FE',
  primary100: '#C3D7FD',
  primary200: '#A6C3FC',
  primary300: '#7EA8FA',
  primary400: '#6597F9',
  primary500: '#3E7DF8',
  primary600: '#3872E2',
  primary700: '#2C59B0',
  primary800: '#224588',
  primary900: '#1A3568',

  // Success (Green)
  success25: '#F7FCFA',
  success50: '#E8F6F1',
  success100: '#B9E3D5',
  success200: '#97D5C0',
  success300: '#67C2A4',
  success400: '#49B692',
  success500: '#1CA477',
  success600: '#19956C',
  success700: '#147454',
  success800: '#0F5A41',
  success900: '#0C4532',

  // Warning (Yellow)
  warning50: '#FEF8EC',
  warning100: '#FDEAC3',
  warning200: '#FCDFA7',
  warning300: '#FAD17E',
  warning400: '#F9C865',
  warning500: '#F8BA3F',
  warning600: '#E2A939',
  warning700: '#B0842D',
  warning800: '#886623',
  warning900: '#684E1A',

  // Error (Red)
  error50: '#FCEBEC',
  error100: '#F6C0C3',
  error200: '#F1A2A7',
  error300: '#EB777E',
  error400: '#E75D65',
  error500: '#E1343F',
  error600: '#CD2F39',
  error700: '#A0252D',
  error800: '#7C1D23',
  error900: '#5F161A',

  xxSmallShadowSoft:
    'box-shadow: 0rem 0.09375rem 0.25rem -0.0625rem rgba(20, 28, 40, 0.05)',
  xSmallShadowSoft:
    '0rem 0.3125rem 0.8125rem -0.3125rem rgba(16, 25, 40, 0.05), 0rem 0.125rem 0.25rem -0.0625rem rgba(16, 25, 40, 0.02)',
  buttonShadow:
    'box-shadow: 0rem 0.09375rem 0.25rem -0.0625rem rgba(182, 182, 182, 0.07)'
};
