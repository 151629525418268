import React, { useEffect } from 'react';
import { theme } from 'themes/theme';
import { Box, Typography, Paper, Stack, Avatar, Button } from '@mui/material';
import { FormFileInput, FormInput } from 'components/form';

import image from 'assets/png/image.png';
import rectangleImage from 'assets/png/rectangleImage.png';
import upload2 from 'assets/svg/upload2.svg';
import trashSolid from 'assets/svg/trashSolid.svg';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useUpdateOrganizationMutation } from 'services/api/organizationApi';
import { toast } from 'react-toastify';
import constructErrorMessage from 'services/error';

type FormValues = {
  organization_name: string;
  product_category: string;
  logo?: unknown;
};

const OrganizationSettings = () => {
  const [updateOrganization, { isSuccess, isError, error }] =
    useUpdateOrganizationMutation();

  const { handleSubmit, control, setValue } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      organization_name: '',
      product_category: ''
    }
  });

  const onSubmit: SubmitHandler<FormValues> = async data => {
    await updateOrganization({
      name: data.organization_name,
      product_category: data.product_category
    });
  };

  interface MyError {
    data:
      | string
      | {
          email: string[];
        };
    status?: number;
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success('Organization Updated!');
    }
    if (error !== undefined) {
      const myError = error as MyError;
      try {
        toast.error(`${constructErrorMessage(myError.data)}`);
      } catch {
        toast.error('An error occurred');
      }
    }
  }, [isSuccess, isError]);

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: `${theme.palette.common.white}`,
        border: `0.063rem solid ${theme.palette.common.cardBorder}`,
        borderRadius: '0.75rem',
        gap: '1.5rem',
        padding: '1.5rem',
        width: '100%'
      }}
    >
      <Box width="inherit">
        <Box
          sx={{
            height: '3rem',
            mb: '1.5rem',
            borderRadius: '0.75rem 0.75rem 0rem 0rem',
            backgroundImage: `url(${rectangleImage})`
          }}
        />
        <Box
          width="inherit"
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography
            variant="bodyLargeSemibold"
            color={`${theme.palette.common.heading}`}
          >
            Organization Settings
          </Typography>
          <Typography
            variant="bodyMediumRegular"
            color={`${theme.palette.common.labelColor}`}
          >
            Business details that help distinguish your organization from others
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'stretch',
              padding: '1rem 2rem',
              backgroundColor: `${theme.palette.common.cardBorder}`,
              borderRadius: '0.75rem',
              gap: '1rem',
              marginTop: '1.5rem'
            }}
          >
            <Avatar
              src={image}
              sx={{
                width: '3.5rem',
                height: '3.5rem',
                borderRadius: '12.5rem'
              }}
            />

            <Stack direction="row" display="flex" alignItems="center">
              <FormFileInput<FormValues>
                control={control}
                name="logo"
                label={
                  <Typography
                    variant="bodyMediumSemibold"
                    color={`${theme.palette.common.btnColor}`}
                  >
                    Change Logo
                  </Typography>
                }
                setFile={(file: File | null) => setValue('logo', file)}
                supportedFiles="image/png,image/jpeg,image/jpg"
                startIcon={
                  <Avatar
                    src={upload2}
                    sx={{ width: '1rem', height: '1rem' }}
                  />
                }
                fileInputButtonStyle={{
                  borderRadius: '0.5rem',
                  padding: '0.5rem 0.75rem',
                  gap: '0.25rem',
                  mr: '1.25rem',
                  border: '0.094rem solid #F2F4F7',
                  backgroundColor: `${theme.palette.common.white}`
                }}
              />

              <Button
                variant="text"
                sx={{
                  borderRadius: '0.5rem',
                  padding: '0.5rem 0.75rem',
                  gap: '0.25rem',
                  border: '0.094rem solid #F2F4F7',
                  backgroundColor: `${theme.palette.common.white}`
                }}
                startIcon={
                  <Avatar
                    src={trashSolid}
                    sx={{ width: '1rem', height: '1rem' }}
                  />
                }
              >
                <Typography
                  variant="bodyMediumSemibold"
                  color={`${theme.palette.common.subheading}`}
                >
                  Delete Image
                </Typography>
              </Button>
            </Stack>
            <Typography
              variant="captionSmall"
              color={`${theme.palette.common.textColor}`}
            >
              IMG must be 256 * 256 - Max 2MB
            </Typography>
          </Box>

          <Box width="inherit" sx={{ mt: '1.5rem' }}>
            <FormInput<FormValues>
              control={control}
              name="organization_name"
              label="ORGANIZATION NAME"
            />
            <FormInput<FormValues>
              control={control}
              name="product_category"
              label="PRODUCT CATEGORY"
            />
          </Box>
          <Stack direction="row" display="flex" justifyContent="flex-end">
            <Button
              variant="text"
              sx={{
                borderRadius: '0.5rem',
                padding: '0.5rem 0.75rem',
                gap: '0.25rem',
                mr: '1.25rem',
                border: '0.094rem solid #F2F4F7',
                backgroundColor: `${theme.palette.common.white}`
              }}
            >
              <Typography
                variant="bodyMediumSemibold"
                color={`${theme.palette.common.subheading}`}
              >
                Cancel
              </Typography>
            </Button>

            <Button
              type="submit"
              variant="text"
              sx={{
                borderRadius: '0.5rem',
                padding: '0.5rem 0.75rem',
                gap: '0.25rem',
                border: '0.094rem solid #F2F4F7',
                backgroundColor: `${theme.palette.common.btnColor}`
              }}
            >
              <Typography
                variant="bodyMediumSemibold"
                color={`${theme.palette.common.white}`}
              >
                Save Changes
              </Typography>
            </Button>
          </Stack>
        </form>
      </Box>
    </Paper>
  );
};

export default OrganizationSettings;
