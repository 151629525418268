import React from 'react';
import { Switch, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import { fontFamily2, theme } from 'themes/theme';
import { FormControlProps } from 'components/form/common/formTypes';
import type { FieldValues } from 'react-hook-form';
export default function FormSwitch<FormValueT extends FieldValues>(
  props: FormControlProps<FormValueT>
) {
  const { label, name, control, ...rest } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          label={label}
          name={name}
          control={
            <Switch
              {...field}
              {...rest}
              sx={{
                padding: 0,
                marginTop: '0.125rem',
                '& .MuiSwitch-track': {
                  borderRadius: 22 / 2,
                  height: '1.25rem',
                  opacity: 1,
                  backgroundColor: '#E4E7EC',
                  '&.Mui-checked': {
                    backgroundColor: '#1671D9'
                  }
                },
                '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
                  opacity: 1
                },
                '& .MuiSwitch-switchBase': {
                  padding: '0.125rem 0.125rem',
                  '&.Mui-checked': {
                    transform: 'translateX(20px)'
                  }
                },
                '& .MuiSwitch-thumb': {
                  width: 16,
                  height: 16,
                  backgroundColor: '#FFFFFF',
                  boxShadow: 'none'
                }
              }}
              size="small"
            />
          }
          sx={{
            margin: 0,
            padding: '0.75rem',
            gap: '0.5rem',
            justifyContent: 'center',
            textAlign: 'center',
            '.MuiFormControlLabel-label': {
              color: `${theme.palette.common.subheading}`,
              fontFamily: fontFamily2,
              fontSize: '0.875rem',
              fontWeight: 600,
              lineHeight: '1.26rem',
              letterSpacing: '-0.01313rem'
            }
          }}
        />
      )}
    />
  );
}
