import React, { useEffect, useState } from 'react';
import {
  Box,
  Tab as MUITab,
  Tabs as MUITabs,
  Paper,
  Typography
} from '@mui/material';
import Loading from 'components/loader/loading';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'services/hook';
import ProductReceiptDetails from './details/productReceiptDetails';
import ProductReceiptPickup from './pickup/productReceiptPickup';
import ProductReceiptInfoTabLayout from './productReceiptInfoTabLayout';
import ProductReceiptWorkflows from './workflows/productReceiptWorkflows';
import { useGetProductReceiptQuery } from 'services/api/productReceiptApi';
import { Button } from 'components/button';
import AssignmentModal from './assignmentModal';
import EndorsementModal from './endorsementModal';
import { ReactComponent as Check } from 'assets/svg/checkSolid.svg';
import { ReactComponent as InfoSolid } from 'assets/svg/infoSolid.svg';
import WorkflowCompleteModal from 'components/modal/modal';
import SvgWrapper from 'components/svg/svgWrapper';
import { OrganizationType } from 'services/enums/organization';
import { useGetPermissionsQuery } from 'services/api/permissionsApi';
import { PermissionType } from 'services/enums/permissions';
import ShowInfo from 'components/info/showInfo';
import ProductReceiptAnalytics from './analytics/productReceiptAnalytics';

const tabList = ['details', 'workflow', 'analytics', 'pickup'];
const ProductReceiptInfoPage = () => {
  const { organizationType } = useAppSelector(state => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const { productReceiptId, tab } = useParams();
  const currentTab = tabList.indexOf(tab ?? 'details');

  const { data } = useGetProductReceiptQuery({
    productReceiptId: productReceiptId ?? ''
  });
  const [assignmentWorkflowModalOpen, setAssignmentWorkflowModalOpen] =
    useState<boolean>(false);
  const [endorsementWorkflowModalOpen, setEndorsementWorkflowModalOpen] =
    useState<boolean>(false);
  const [workflowCompleteModalOpen, setWorkflowCompleteModalOpen] =
    useState<boolean>(false);
  const [showAssignToBranchBtn, setShowAssignToBranchBtn] = useState(false);
  const { data: permissions } = useGetPermissionsQuery({});

  const handleChange = (newTabIndex: number) => {
    const lastSlashIndex = location.pathname.lastIndexOf('/');
    const basePath = location.pathname.substring(0, lastSlashIndex);
    navigate(`${basePath}/${tabList[newTabIndex]}`);
  };

  const isActiveTab = (tab: string) => {
    return tab === tabList[currentTab];
  };

  useEffect(() => {
    if (data?.branch_assignment_workflow?.completed) {
      setWorkflowCompleteModalOpen(true);
    }
  }, [data?.branch_assignment_workflow?.completed]);

  return (
    <Box sx={{ height: '100%', position: 'relative' }}>
      {isActiveTab('workflow') && (
        <Box
          sx={{
            width: 'fit-content',
            position: 'absolute',
            zIndex: 1,
            right: '1rem',
            top: '1rem'
          }}
        >
          {data?.branch_assignment_workflow?.completed &&
            data?.assigned_branch === null && (
              <Button
                color="primary"
                size="md"
                iconPosition="start"
                text="Assign To Branch"
                onClick={() => {
                  if (
                    !permissions ||
                    !permissions.includes(
                      PermissionType.PRODUCT_RECEIPT_COMPLETE_BRANCH_ASSIGNMENT
                    )
                  ) {
                    ShowInfo({
                      message: 'Cannot complete branch assignment',
                      subText: 'You do not have permissions to do this!',
                      type: 'error',
                      componentType: 'toast',
                      hideProgressBar: false
                    });
                    return;
                  }
                  setAssignmentWorkflowModalOpen(true);
                }}
                disabled={!showAssignToBranchBtn}
              />
            )}
          {data?.endorsement_workflow?.completed &&
            (data?.endorsements.length === 0 ||
              (organizationType !== OrganizationType.MERCHANT &&
                data?.endorsements.length < 2)) && (
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  color="primary"
                  size="md"
                  iconPosition="start"
                  text={
                    organizationType === OrganizationType.MERCHANT
                      ? 'Issue to Customer'
                      : 'Endorse to Customer'
                  }
                  onClick={() => {
                    if (
                      !permissions ||
                      !permissions.includes(
                        PermissionType.PRODUCT_RECEIPT_COMPLETE_ENDORSEMENT
                      )
                    ) {
                      ShowInfo({
                        message: 'Cannot complete endorsement',
                        subText: 'You do not have permissions to do this!',
                        type: 'error',
                        componentType: 'toast',
                        hideProgressBar: false
                      });
                      return;
                    }
                    setEndorsementWorkflowModalOpen(true);
                  }}
                />
              </Box>
            )}
        </Box>
      )}
      <Paper
        elevation={0}
        sx={{
          padding: '1.25rem',
          borderRadius: '0.75rem',
          backgroundColor: '#FFF',
          position: 'relative',
          height: '100%',
          border: '.0625rem solid #F5F6F7'
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            background: '#fff'
          }}
        >
          <MUITabs
            orientation="horizontal"
            value={Math.max(0, currentTab)}
            onChange={(e, value) => handleChange(value)}
            sx={{
              borderBottom: 1,
              borderColor: '#F5F6F7',
              marginBottom: '1.75rem',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <MUITab
              label={
                <Typography variant="bodyMediumMedium">Details</Typography>
              }
            />
            <MUITab
              label={
                <Typography variant="bodyMediumMedium">Workflow</Typography>
              }
            />
            <MUITab
              label={
                <Typography variant="bodyMediumMedium">Analytics</Typography>
              }
            />
            <MUITab
              label={
                <Typography variant="bodyMediumMedium">
                  Product pickup
                </Typography>
              }
            />
          </MUITabs>
          {data ? (
            <ProductReceiptInfoTabLayout>
              <Box
                role="tabpanel"
                hidden={!isActiveTab('details')}
                id="simple-tabpanel-details"
                aria-labelledby="simple-tab-details"
                sx={{ width: '100%' }}
              >
                <ProductReceiptDetails productReceipt={data} />
              </Box>
              <Box
                role="tabpanel"
                hidden={!isActiveTab('workflow')}
                id="simple-tabpanel-workflow"
                aria-labelledby="simple-tab-workflow"
                sx={{ width: '100%' }}
              >
                <ProductReceiptWorkflows productReceipt={data} />
              </Box>
              <Box
                role="tabpanel"
                hidden={!isActiveTab('analytics')}
                sx={{ width: '100%' }}
              >
                <ProductReceiptAnalytics productReceipt={data} />
              </Box>
              <Box
                role="tabpanel"
                hidden={!isActiveTab('pickup')}
                id="simple-tabpanel-pickup"
                aria-labelledby="simple-tab-pickup"
                sx={{ width: '100%' }}
              >
                <ProductReceiptPickup productReceipt={data} />
              </Box>
            </ProductReceiptInfoTabLayout>
          ) : (
            <Loading loading={true} loadingText="Loading Product Receipt" />
          )}
        </Box>
      </Paper>

      <AssignmentModal
        open={assignmentWorkflowModalOpen}
        onClose={() => {
          setAssignmentWorkflowModalOpen(!assignmentWorkflowModalOpen);
        }}
      />

      <EndorsementModal
        open={endorsementWorkflowModalOpen}
        onClose={() => {
          setEndorsementWorkflowModalOpen(!endorsementWorkflowModalOpen);
        }}
        productReceipt={data}
      />

      {data?.branch_assignment_workflow?.completed &&
        data?.assigned_branch === null &&
        isActiveTab('workflow') && (
          <WorkflowCompleteModal
            open={workflowCompleteModalOpen}
            onClose={() => {
              setShowAssignToBranchBtn(true);
              setWorkflowCompleteModalOpen(false);
            }}
            text="Workflow Complete"
            subText="Your Assign PRC workflow for Hamalaya Group is complete. Do you wish to assign this PRC to a branch?"
            src={Check}
            onClick={() => {
              setShowAssignToBranchBtn(true);
              setWorkflowCompleteModalOpen(false);
            }}
            cancelText="Assign later"
            btnProps={{ text: 'Proceed', color: 'primary' }}
            fill="#1CA477"
            svgColor="#E8F6F1"
          >
            <Box
              sx={{
                margin: '1rem .75rem',
                padding: '.75rem',
                backgroundColor: 'rgba(102, 112, 133, 0.02)',
                borderRadius: '0.5rem',
                border: '1px solid #F5F6F7',
                display: 'flex',
                flexDirection: 'row',
                gap: '.5rem',
                justifyContent: 'flex-start'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start'
                }}
              >
                <SvgWrapper
                  icon={InfoSolid}
                  width="1.25rem"
                  height="1.25rem"
                  styleOverrides={{
                    opacity: 0.8
                  }}
                  color="#D0D5DD"
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#344054">
                  Assigning #{data?.product_receipt_id}
                </Typography>
                <Typography variant="bodyMediumRegular" color="#667085">
                  Only branches assigned this PRC will be able to endorse PRC to
                  final customer
                </Typography>
              </Box>
            </Box>
          </WorkflowCompleteModal>
        )}
    </Box>
  );
};

export default ProductReceiptInfoPage;
