import React, { useMemo } from 'react';
import { Table } from 'components/table';
import { createColumnHelper } from '@tanstack/react-table';
import type { ColumnDef } from '@tanstack/react-table';
import { Typography } from '@mui/material';
import { useGetBranchesQuery } from 'services/api/branchApi';
import { BranchApiData } from 'services/types/branches';

interface TableDataT {
  name: string;
  address: string;
  state: string;
}

const columnHelper = createColumnHelper<TableDataT>();
const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('address', {
    header: 'Address',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('state', {
    header: 'State',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  })
] as ColumnDef<TableDataT>[];
// Cast is a workaround due to issues with tanstack table
// See https://github.com/TanStack/table/issues/4302

const emptyBranchesData: TableDataT[] = [];
const BranchesTable = () => {
  const { data: branches, isLoading } = useGetBranchesQuery({});

  const updatedBranchesList = useMemo(() => {
    if (branches) {
      return branches.map((branch: BranchApiData) => ({
        name: branch.name,
        address: branch.location.full_address,
        state: branch.location.administrative_area
      }));
    }
    return emptyBranchesData;
  }, [branches]);

  return (
    <Table<TableDataT>
      hover
      columns={columns}
      data={updatedBranchesList}
      onClickRow={(rowData: TableDataT) => {
        console.log(`Hi from row ${rowData.name}`);
      }}
      optionsButton
      enableFilter={false}
      filterColumns={['name']}
      searchPlaceholderText="Search for Branches"
      loadingTable={isLoading}
    />
  );
};

export default BranchesTable;
