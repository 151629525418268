import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { Button } from 'components/button';
import { useNavigate } from 'react-router-dom';

const Error404Page = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: '1'
      }}
    >
      <Stack alignItems="center" gap=".25rem" sx={{ textAlign: 'center' }}>
        <Typography
          variant="display01Bold"
          sx={{
            background:
              'linear-gradient(180deg, rgba(62, 125, 248, 0.50) 36.42%, rgba(31, 160, 209, 0.50) 71.08%)',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            padding: '0.5rem'
          }}
        >
          404
        </Typography>
        <Typography variant="h4Bold" color="#475467">
          Lagos, we have a problem
        </Typography>
        <Typography variant="bodyMediumSemibold" color="#475467">
          Something went wrong, go back to dashboard and try again later
        </Typography>
      </Stack>
      <Box sx={{ marginTop: '2.3125rem', width: '100%' }}>
        <Button
          color="primary"
          size="md"
          text="Back to dashboard"
          width="48%"
          styleOverrides={{ marginRight: '2%' }}
          onClick={() => navigate('/')}
        />
        <Button
          color="grey"
          size="md"
          text="Go to help"
          width="48%"
          onClick={() => navigate('/')}
        />
      </Box>
    </Box>
  );
};

export default Error404Page;
