import { api } from './baseApi';
import { MembersApiData } from 'services/types/organization';

export const organizationApi = api.injectEndpoints({
  endpoints: builder => ({
    createOrganization: builder.mutation({
      query: body => ({
        url: 'organizations/',
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: body
      })
    }),
    updateOrganization: builder.mutation({
      query: body => ({
        url: 'organizations/({organizationId})/',
        method: 'patch',
        headers: {
          'Content-Type': 'application/json'
        },
        data: body
      })
    }),
    getOrganization: builder.query({
      query: (organizationId: string) => ({
        url: `organizations/(${organizationId})/`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getMembers: builder.query<MembersApiData, unknown>({
      query: () => ({
        url: 'organizations/({organizationId})/members/',
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getMember: builder.query({
      query: ({ memberUserId }) => ({
        url: `organizations/({organizationId})/members/(${memberUserId})/`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    deleteMember: builder.mutation({
      query: ({ memberUserId }) => ({
        url: `organizations/({organizationId})/members/(${memberUserId})/`,
        method: 'delete',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    addMember: builder.mutation({
      query: ({ data, organizationId }) => ({
        url: `organizations/(${organizationId})/members/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
    }),
    updateMemberType: builder.mutation({
      query: ({ data }) => ({
        url: 'organizations/({organizationId})/members/type/',
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
    })
  }),
  overrideExisting: false
});

export const {
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useGetOrganizationQuery,
  useGetMembersQuery,
  useUpdateMemberTypeMutation,
  useGetMemberQuery,
  useAddMemberMutation,
  useDeleteMemberMutation
} = organizationApi;
