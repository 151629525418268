import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from 'services/hook';
import SingleProductReceiptsTable from './tables/singleProductReceiptsTable';
import { ProductReceiptApiData } from 'services/types/productReceipt';
import { useGetBranchVaultProductReceiptsQuery } from 'services/api/productReceiptApi';
import { useParams } from 'react-router-dom';

const ProductReceiptBranchVaultPage = () => {
  const { organizationType } = useAppSelector(state => state.auth);
  const { distributorId, branchId, merchantId } = useParams();

  const { data, isLoading } = useGetBranchVaultProductReceiptsQuery({
    distributorId: distributorId || '',
    merchantId: merchantId || '',
    params: {
      branch: branchId || ''
    }
  });

  const branchVaultData = useMemo(() => {
    const emptyBranchVaultData: ProductReceiptApiData[] = [];
    if (data) {
      return Object.values(data.results);
    }
    return emptyBranchVaultData;
  }, [data]);

  return (
    <Box>
      <SingleProductReceiptsTable
        data={branchVaultData}
        fromBranchVault
        organizationType={organizationType}
        loading={isLoading}
        distributorId={distributorId}
        branchId={branchId}
        merchantId={merchantId}
      />
    </Box>
  );
};

export default ProductReceiptBranchVaultPage;
