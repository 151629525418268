import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../services/hook';

type Props = {
  children: JSX.Element;
};

const ProtectedRoute = ({ children }: Props) => {
  const location = useLocation();
  const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated);

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to={'/login'} state={{ from: location }} replace />
  );
};

export default ProtectedRoute;
