import React, { useContext } from 'react';
import { Dialog } from '@mui/material';
import { PickupRequestContext } from './pickupRequestContext';

type PickupRequestModalProps = {
  open: boolean;
  onClose: () => void;
};

const PickupRequestModal: React.FC<PickupRequestModalProps> = ({
  open,
  onClose
}) => {
  const { steps, currentStep } = useContext(PickupRequestContext);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        padding: '1.25rem',
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '24.5rem',
            borderRadius: '0.75rem',
            border: '2px solid #F5F6F7',
            backgroundColor: '#FCFCFD'
          }
        }
      }}
    >
      {steps[currentStep].element}
    </Dialog>
  );
};

export default PickupRequestModal;
