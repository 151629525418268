import React from 'react';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Box, Stack, Typography } from '@mui/material';
import { theme } from 'themes/theme';
import IndicatorCard from './cards/indicatorCard';
import {
  TopProductsChart,
  TotalPrcValueChart,
  TopBranchesChart
} from './charts';

Chart.register(CategoryScale);

const indicatorItems = [
  {
    title: 'Total PRC Value',
    value: '500,000,000',
    orderStat: '3'
  },
  {
    title: 'Total PRC Issued',
    value: '500',
    orderStat: '3'
  },
  {
    title: 'Pickup Rates',
    value: '10%',
    orderStat: '3'
  }
];

const AnalyticsPage = () => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginBottom: '2.06rem'
        }}
      >
        <Stack
          width="inherit"
          direction="column"
          spacing={0.25}
          sx={{ textAlign: 'left' }}
        >
          <Typography
            variant="h7Semibold"
            sx={{
              color: `${theme.palette.common.subheading}`,
              fontWeight: 600,
              lineHeight: '1.5rem',
              letterSpacing: '0.005rem'
            }}
          >
            Analytics
          </Typography>
          <Typography
            variant="bodyMediumMedium"
            sx={{
              color: '#98A2B3'
            }}
          >
            Data driven ATC management decisions
          </Typography>
        </Stack>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1.375rem',
          width: 'inherit'
        }}
      >
        {indicatorItems?.map((item, index) => (
          <IndicatorCard
            key={index}
            title={item.title}
            value={item.value}
            orderStat={item.orderStat}
          />
        ))}
      </Box>

      <Box
        sx={{
          marginTop: '2rem',
          display: 'flex',
          gap: '1.75rem',
          width: 'inherit',
          backgroundColor: '#FFFFFF',
          borderRadius: '0.5rem',
          border: '1px solid #F5F6F7',
          padding: '1rem'
        }}
      >
        <TotalPrcValueChart />
      </Box>

      <Box
        sx={{
          marginTop: '2rem',
          display: 'flex',
          gap: '1.75rem',
          width: 'inherit',
          backgroundColor: '#FFFFFF',
          borderRadius: '0.5rem',
          border: '1px solid #F5F6F7',
          padding: '1rem'
        }}
      >
        <TopBranchesChart />
      </Box>
      <Box
        sx={{
          marginTop: '2rem',
          display: 'flex',
          gap: '1.75rem',
          width: 'inherit',
          backgroundColor: '#FFFFFF',
          borderRadius: '0.5rem',
          border: '1px solid #F5F6F7',
          padding: '1rem'
        }}
      >
        <TopProductsChart />
      </Box>
    </Box>
  );
};

export default AnalyticsPage;
