import React from 'react';
import {
  Box,
  FormControlLabel,
  Paper,
  Stack,
  Avatar,
  Typography,
  SvgIcon,
  Checkbox
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { FormCheckGroupProps } from 'components/form/common/formTypes';
import type { FieldValues } from 'react-hook-form';

export default function FormCheckGroup<FormValueT extends FieldValues>(
  props: FormCheckGroupProps<FormValueT>
) {
  const { label, control, name, checkData, setValue, getValues, ...rest } =
    props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (getValues && setValue !== undefined) {
      const productArr = getValues();
      if (e.target.checked) {
        return setValue([...productArr, e.target.value]);
      } else {
        const newProductArr = productArr.filter(
          (item: string) => item !== e.target.value
        );
        setValue(newProductArr);
      }
    }
  };

  return (
    <Controller
      rules={{ required: true }}
      control={control}
      name={name}
      render={({ field }) => (
        <Box>
          {checkData.map((element, index) => {
            return (
              <Paper
                key={index}
                elevation={0}
                sx={{
                  width: '100%',
                  mb: '1rem',
                  borderRadius: '0.75rem',
                  border: '1.5px solid #F2F4F7',
                  background: '#FFF',
                  padding: '1rem',
                  boxShadow: '0px 4px 4px 0px rgba(158, 158, 158, 0.10)'
                }}
              >
                <Stack
                  width="inherit"
                  justifyContent="space-between"
                  alignItems="center"
                  direction="row"
                >
                  <Stack direction="row">
                    <Avatar
                      src={element.image}
                      sx={{
                        borderRadius: '0.75rem',
                        border: '1px solid #F9F9F9',
                        background: '#FFF',
                        padding: '.75rem'
                      }}
                    />
                    <Box ml=".5rem">
                      <Typography variant="subtitle1" sx={{ color: '#475467' }}>
                        {element.heading}
                      </Typography>
                      <Typography variant="subtitle2" sx={{ color: '#98A2B3' }}>
                        {element.subHeading}
                      </Typography>
                    </Box>
                  </Stack>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checkedIcon={<Checked />}
                        value={element.value}
                        checked={
                          getValues !== undefined &&
                          getValues().includes(element.value)
                        }
                        onChange={e => handleChange(e)}
                      />
                    }
                    label={label}
                    labelPlacement="start"
                    {...rest}
                    {...field}
                  />
                </Stack>
              </Paper>
            );
          })}
        </Box>
      )}
    />
  );
}

const Checked = () => {
  return (
    <SvgIcon inheritViewBox>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="20" height="20" rx="4" fill="#2563EB" />
        <path
          d="M6.11133 9.99995L8.88911 12.7777L14.4447 7.22217"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
