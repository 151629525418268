import React from 'react';
import { Backdrop, Box, Typography } from '@mui/material';
import logo from 'assets/custom-svg/flux-logo.svg';
import { PulseLoader } from 'react-spinners';
import { colorTheme } from 'themes/colors';
interface Props {
  loading: boolean;
  loadingText?: string;
}

const Loading = ({ loading, loadingText }: Props) => {
  return (
    <Backdrop
      open={loading}
      sx={{
        zIndex: theme => theme.zIndex.drawer + 1,
        background: 'rgba(0, 0, 0, 0.18)'
      }}
    >
      <Box
        width="auto"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            mb: '1rem'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '.5rem'
            }}
          >
            <Box component="img" src={logo} />
            <Typography variant="h4Bold" color="#475467">
              Flux
            </Typography>
          </Box>

          <Typography variant="bodySmallMedium" color="#475467">
            {loadingText ?? 'Getting your dashboard ready'}
          </Typography>
        </Box>

        <PulseLoader
          color={colorTheme.primary}
          loading={loading}
          size="1rem"
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </Box>
    </Backdrop>
  );
};
export default Loading;
