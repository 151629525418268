import React from 'react';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import _ from 'lodash';

export default function Breadcrumb() {
  const location = useLocation();
  const crumbs = location.pathname.split('/').filter(crumb => crumb !== '');

  const crumbName = (element: string) => {
    if (element.startsWith('product-receipts/branch-vault')) {
      return 'Branch Vault';
    } else if (element.startsWith('product-receipts/central-vault')) {
      return 'Central Vault';
    } else if (element.startsWith('branches-teams')) {
      return 'Branches & Teams';
    } else if (element.endsWith('product-receipts')) {
      return 'Vault';
    } else return _.startCase(element.replace(/-/g, ' '));
  };

  return (
    <Breadcrumbs maxItems={2} separator="/" aria-label="breadcrumb">
      {crumbs.map((element, index) => {
        const routeTo = `/${crumbs.slice(0, index + 1).join('/')}`;
        if (index > 1) return null;
        return index === 1 ? (
          <Typography key={index} variant="bodyMediumMedium" color="#3E7DF8">
            {crumbName(element)}
          </Typography>
        ) : (
          <Link
            style={{
              color: index > 0 ? '#98A2B3' : '#667085',
              textDecoration: 'none'
            }}
            key={index}
            to={routeTo}
          >
            <Typography variant="bodyMediumSemibold" color="#667085">
              {crumbName(element)}
            </Typography>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}
