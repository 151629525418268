import React, { useState, createContext, PropsWithChildren } from 'react';
import CreateTeamStep from '../teams/steps/base/createTeamStep';
import InviteTeamMemberStep from '../teams/steps/base/inviteTeamMemberStep';
import ManualTeamUpload from '../teams/steps/manual-upload/manualTeamUpload';
import BulkUploadTeamStep from '../teams/steps/bulk-upload/bulkUploadTeamStep';

type TeamsData = {
  name: string;
  description: string;
  members: string[];
  file: File | null;
};

const initialValues: TeamsData = {
  name: '',
  members: [],
  file: null,
  description: ''
};

type TeamsContextWrapperProps = {
  setCreateTeamModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type TeamsContextTypes = {
  data: TeamsData;
  setData: React.Dispatch<React.SetStateAction<TeamsData>>;
  currentStep: number;
  steps: { element: JSX.Element }[];
  handleNextStep: (newData?: Partial<TeamsData>) => void;
  handlePrevStep: () => void;
  goToStep: (index: number) => void;
  uploadedFile: File | null;
  setUploadedFile: React.Dispatch<React.SetStateAction<File | null>>;
  setCreateTeamModalOpen: TeamsContextWrapperProps['setCreateTeamModalOpen'];
};

const TeamsContextDefaults: TeamsContextTypes = {
  data: initialValues,
  setData: () => undefined,
  currentStep: 0,
  steps: [],
  handleNextStep: () => undefined,
  handlePrevStep: () => undefined,
  goToStep: () => undefined,
  uploadedFile: null,
  setUploadedFile: () => undefined,
  setCreateTeamModalOpen: () => undefined
};

export const TeamsContext =
  createContext<TeamsContextTypes>(TeamsContextDefaults);

const TeamsContextWrapper = ({
  children,
  setCreateTeamModalOpen
}: PropsWithChildren<TeamsContextWrapperProps>) => {
  const [data, setData] = useState<TeamsData>(initialValues);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [uploadedFile, setUploadedFile] = useState<null | File>(null);

  const steps = [
    { element: <CreateTeamStep /> },
    { element: <InviteTeamMemberStep /> },
    { element: <ManualTeamUpload /> },
    { element: <BulkUploadTeamStep /> }
  ];

  const handleNextStep = (newData?: Partial<TeamsData>) => {
    if (newData) {
      setData(prev => ({ ...prev, ...newData }));
    }
    setCurrentStep(i => {
      if (i === 2) return i;
      if (i >= steps.length - 1) return i;
      return i + 1;
    });
  };

  const handlePrevStep = () => {
    setCurrentStep(i => {
      if (i <= 0) return i;
      return i - 1;
    });
  };

  const goToStep = (index: number) => {
    setCurrentStep(index);
  };

  const values: TeamsContextTypes = {
    data,
    setData,
    currentStep,
    steps,
    handleNextStep,
    handlePrevStep,
    goToStep,
    uploadedFile,
    setUploadedFile,
    setCreateTeamModalOpen
  };

  return (
    <TeamsContext.Provider value={values}>{children}</TeamsContext.Provider>
  );
};

export default TeamsContextWrapper;
