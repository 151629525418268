import { api } from './baseApi';
import { WithIdTransformed } from 'services/types/common';

type OrganizationTypeData = {
  organization_type: string;
};

export type DiscoverableProfileApiData = {
  organization_id: string;
  name: string;
  logo: string;
  address: string;
};

type GetDiscoverableProfilesQueryArgs = {
  params: { organization_type: string };
};

export const supplyChainApi = api.injectEndpoints({
  endpoints: builder => ({
    getSupplyChainProfile: builder.query({
      query: () => ({
        url: 'supply_chain/({organizationId})/profiles/',
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    activateSupplyChainProfile: builder.mutation({
      query: ({
        body,
        organizationId
      }: {
        body: OrganizationTypeData;
        organizationId: string;
      }) => ({
        url: `supply_chain/(${organizationId})/profiles/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: body
      })
    }),
    getDiscoverableProfiles: builder.query<
      WithIdTransformed<DiscoverableProfileApiData>,
      GetDiscoverableProfilesQueryArgs
    >({
      query: ({ params }) => {
        const queryParams = new URLSearchParams(params).toString();
        return {
          url: `supply_chain/discoverable-profiles/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    })
  }),
  overrideExisting: false
});

export const {
  useGetDiscoverableProfilesQuery,
  useActivateSupplyChainProfileMutation,
  useGetSupplyChainProfileQuery
} = supplyChainApi;
