import React from 'react';
import waves from 'assets/svg/waves.svg';
import { theme } from 'themes/theme';
import { Box } from '@mui/material';
import SettingsNavbar from 'pages/settings/nav/settingsNavbar';

const SettingsLayout: React.FunctionComponent<React.PropsWithChildren> = ({
  children
}) => {
  return (
    <Box
      sx={{
        height: '100%',
        backgroundImage: `url(${waves})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: `${theme.palette.common.layoutBackground}`,
        overflow: 'hidden'
      }}
    >
      <SettingsNavbar />
      <Box
        sx={{
          padding: '2rem 3rem',
          height: '100%',
          overflow: 'hidden',
          background: '#FCFCFD'
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default SettingsLayout;
