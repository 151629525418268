import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import TabPanel from './tabPanel';
import Tab from './tab';
import { SxProps } from '@mui/material';

interface TabObject {
  labelText: string;
  content: React.ReactNode;
}

interface TabContainerProps {
  tabs: TabObject[];
  styleOverrides?: SxProps;
  orientation?: 'horizontal' | 'vertical';
  variant?: 'standard' | 'fullWidth' | 'scrollable';
}

const TabContainer: React.FC<TabContainerProps> = ({
  tabs,
  styleOverrides,
  orientation,
  variant
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: orientation === 'vertical' ? 'row' : 'column'
      }}
    >
      <Tabs
        variant={variant}
        orientation={orientation}
        value={value}
        onChange={handleChange}
        aria-label="settings tabs"
        sx={
          orientation === 'vertical'
            ? { borderRight: 1, borderColor: '#F5F6F7', width: '10rem' }
            : {
                borderBottom: 1,
                borderColor: '#F5F6F7',
                marginBottom: '1.75rem'
              }
        }
      >
        {tabs.map((tab, index) => (
          <Tab key={index} labelText={tab.labelText} index={index} />
        ))}
      </Tabs>

      {tabs.map((tab, index) => (
        <TabPanel
          key={index}
          value={value}
          index={index}
          styleOverrides={styleOverrides}
        >
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabContainer;
