import React from 'react';
import { TabContainer } from 'components/tabs';
import RoleRequestTable from './tables/rolesRequestsTable';

const AccessRequestsPage = () => {
  const tabs = [
    {
      labelText: 'Roles',
      content: <RoleRequestTable />
    }
  ];

  return <TabContainer tabs={tabs} />;
};

export default AccessRequestsPage;
