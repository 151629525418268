import React from 'react';
import { Table } from 'components/table';

import { createColumnHelper } from '@tanstack/react-table';
import type { ColumnDef } from '@tanstack/react-table';
import { Typography } from '@mui/material';

interface NotInOrg {
  name: string;
  email: string;
}

const data: NotInOrg[] = [
  {
    name: 'Evelyn',
    email: 'evenlyn@gmail.com'
  },
  {
    name: 'Evelyn',
    email: 'evenlyn@gmail.com'
  },
  {
    name: 'Evelyn',
    email: 'evenlyn@gmail.com'
  },
  {
    name: 'Evelyn',
    email: 'evenlyn@gmail.com'
  },
  {
    name: 'Evelyn',
    email: 'evenlyn@gmail.com'
  }
];

const columnHelper = createColumnHelper<NotInOrg>();
const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('email', {
    header: 'Address',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  })
] as ColumnDef<NotInOrg>[];
// Cast is a workaround due to issues with tanstack table
// See https://github.com/TanStack/table/issues/4302

const NotInOrgTable = () => {
  return (
    <Table<NotInOrg>
      hover
      columns={columns}
      data={data}
      onClickRow={(rowData: NotInOrg) => {
        console.log(`Hi from row ${rowData.name}`);
      }}
      optionsButton
      enableFilter={true}
      filterColumns={['name']}
      searchPlaceholderText="Search here"
    />
  );
};

export default NotInOrgTable;
