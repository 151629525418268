import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type RegisterUser = {
  user: {
    userid: string;
    email: string;
    firstname: string;
    lastname: string;
    email_validated: boolean;
    user_profile: {
      phone_number: null | '';
      phone_number_validated: boolean;
      profile_picture: null | '';
    };
  };
  token: string;
};

const initialState: RegisterUser = {
  user: {
    userid: '',
    email: '',
    firstname: '',
    lastname: '',
    email_validated: false,
    user_profile: {
      phone_number: null,
      phone_number_validated: false,
      profile_picture: null
    }
  },
  token: ''
};

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    setRegisterUser(state: RegisterUser, action: PayloadAction<RegisterUser>) {
      sessionStorage.setItem('user', JSON.stringify({ ...action.payload }));
      return { ...state, ...action.payload };
    }
  }
});

export const { setRegisterUser } = registerSlice.actions;
export default registerSlice;
