import React, { useContext } from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { ReactComponent as UploadIcon } from 'assets/svg/upload2.svg';
import { ReactComponent as Trash } from 'assets/svg/trash.svg';
import profile from 'assets/svg/profile.svg';
import { Button } from 'components/button';
import { SvgWrapper } from 'components/svg';
import { FormSelect } from 'components/form';
import type { FormSelectOption } from 'components/form';
import { toast } from 'react-toastify';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { theme } from 'themes/theme';
import { yupResolver } from '@hookform/resolvers/yup';
import { inviteUsersManuallyFormValidator } from '../../branchesValidators';
import { BranchesContext } from '../../branchesContext';

type FormValues = {
  members: string[];
};

const sampleOptions: FormSelectOption[] = [
  {
    title: 'Evelyn Jack',
    subtitle: 'Operations Lead',
    value: 'Evelyn Jack'
  },
  {
    title: 'Chika Jack',
    subtitle: 'Operations Junior',
    value: 'Chika Jack'
  },
  {
    title: 'Evelyn Jack',
    subtitle: 'Operations Lead',
    value: 'Evelyn Jack1'
  },
  {
    title: 'Chika Jack',
    subtitle: 'Operations Junior',
    value: 'Chika Jack1'
  },
  {
    title: 'Evelyn Jack',
    subtitle: 'Operations Lead',
    value: 'Evelyn Jack2'
  },
  {
    title: 'Chika Jack',
    subtitle: 'Operations Junior',
    value: 'Chika Jack2'
  },
  {
    title: 'Evelyn Jack',
    subtitle: 'Operations Lead',
    value: 'Evelyn Jack3'
  },
  {
    title: 'Chika Jack',
    subtitle: 'Operations Junior',
    value: 'Chika Jack3'
  }
];

const ManualUploadStartStep = () => {
  const { data, handleNextStep, handlePrevStep, setAddBranchOpen } =
    useContext(BranchesContext);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      members: data.members
    },
    resolver: yupResolver(
      inviteUsersManuallyFormValidator
    ) as unknown as Resolver<FormValues>
  });

  const selectedMembers = watch('members');

  const onSubmit: SubmitHandler<FormValues> = data => {
    handleNextStep({
      members: data.members
    });
    toast.success('Users have been added to the Lagos Ijumota-branch');
    setAddBranchOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1.25rem',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          borderBottom: '2px solid #F5F6F7',
          width: '24.5rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '2.25rem',
            width: '2.25rem',
            borderRadius: '6.25rem',
            backgroundColor: '#E5EEFF',
            padding: '0.5rem',
            boxShadow: '0px 0px 0px 4px rgba(200, 219, 255, 0.20)',
            marginBottom: '1rem'
          }}
        >
          <SvgWrapper
            icon={UploadIcon}
            width="1.25rem"
            height="1.25rem"
            styleOverrides={{
              opacity: '0.8rem',
              fill: '#C1D6FF'
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h7Semibold" color="#344054">
            Manual Upload
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            Invite users manually
          </Typography>
        </Box>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box
          sx={{
            padding: '1rem 1.5rem',
            backgroundColor: '#FCFCFC',
            width: '24.5rem'
          }}
        >
          <Box
            sx={{
              padding: '0rem 0.75rem',
              backgroundColor: '#F9F9FA',
              borderRadius: '0.5rem',
              border: '0.125rem solid #F5F6F7'
            }}
          >
            <FormSelect<FormValues>
              options={sampleOptions}
              control={control}
              label="Name or Email"
              name="members"
              multiselect
              renderSelected={(value: string) => (
                <Box
                  sx={{
                    padding: '0.25rem 0.5rem',
                    backgroundColor: '#F5F6F7',
                    display: 'inline-block',
                    borderRadius: '0.5rem',
                    marginRight: '0.5rem'
                  }}
                >
                  <Typography color="#475467" variant="bodyMediumMedium">
                    {value}
                  </Typography>
                </Box>
              )}
            />
          </Box>
        </Box>

        <Box
          sx={{
            maxHeight: '15rem',
            overflowY: 'auto'
          }}
        >
          {selectedMembers.length >= 1 &&
            selectedMembers.map((element, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '1rem .75rem',
                    margin: ' 0.5rem .75rem',
                    borderBottom: '1px solid #F5F6F7',
                    overflowY: 'auto'
                  }}
                >
                  <Stack
                    direction="row"
                    gap=".75rem"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Avatar
                      src={profile}
                      sx={{ width: '2rem', height: '2rem' }}
                    />

                    <Stack direction="column">
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: `${theme.palette.common.heading}`,
                          fontWeight: 600,
                          maxWidth: '21rem'
                        }}
                      >
                        {element}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: `${theme.palette.common.textColor}`,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '14rem'
                        }}
                      >
                        {element}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack direction="row">
                    <Box
                      onClick={() => {
                        const filteredArray = (
                          selectedMembers as string[]
                        ).filter((value: string) => value !== element);
                        setValue('members', filteredArray);
                      }}
                    >
                      <SvgWrapper
                        icon={Trash}
                        width="1.5rem"
                        height="1.5rem"
                        styleOverrides={{
                          opacity: '0.8rem',
                          cursor: 'pointer'
                        }}
                        color="#98A2B3"
                      />
                    </Box>
                  </Stack>
                </Box>
              );
            })}
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: '1px solid #F5F6F7',
            padding: '1.25rem 1.5rem',
            marginBottom: '0.25rem',
            width: '24.5rem'
          }}
        >
          <Button
            color="grey"
            transparent
            size="lg"
            onClick={() => {
              handlePrevStep();
            }}
            text="Back"
            width="48%"
          />
          <Button
            submit
            disabled={!isValid}
            color="primary"
            size="lg"
            text="Invite"
            width="48%"
          />
        </Box>
      </form>
    </>
  );
};

export default ManualUploadStartStep;
