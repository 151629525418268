import React, { useState } from 'react';
import {
  Box,
  MenuItem,
  Checkbox,
  Stack,
  Typography,
  TextField
} from '@mui/material';
import {
  Column,
  ColumnFilter,
  ColumnFiltersState,
  Table
} from '@tanstack/react-table';
import _ from 'lodash';

import { Button } from 'components/button';
import SearchableContainer from 'components/search/searchableContainer';
import { Checked, UnChecked } from '../subComponents/tableButtons';
import { theme } from 'themes/theme';

interface ColumnMeta {
  filterVariant?: 'range' | 'select' | 'range-slider';
}

interface FilterProps<TableDataT> {
  setAnchorFilterEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  setFilterPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setColumnFilters: React.Dispatch<React.SetStateAction<ColumnFiltersState>>;
  tableData: TableDataT[];
  filterTabs?: (keyof TableDataT)[];
  table: Table<TableDataT>;
}

interface SelectedFilters {
  id: string;
  value: string[];
}

export default function FilterModal<TableDataT>({
  setColumnFilters,
  setFilterPopup,
  setAnchorFilterEl,
  tableData,
  table,
  filterTabs
}: FilterProps<TableDataT>) {
  const [filterId, setFilterId] = useState<keyof TableDataT | undefined>(
    filterTabs && filterTabs[0]
  );
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [filterValues, setFilterValues] = useState<Record<string, any>>({});

  const getFilterTabColumnFn = (filterTabs: (keyof TableDataT)[]) => {
    type ColumnWithMeta = Column<TableDataT> & {
      columnDef: { meta?: ColumnMeta };
    };
    const filterTabColumns: ColumnWithMeta[] = [];

    table.getAllColumns().forEach(column => {
      if (filterTabs.includes(column.id as keyof TableDataT)) {
        filterTabColumns.push(column);
      }
    });

    const filterTabElements = filterTabColumns.map((column: ColumnWithMeta) => {
      const { filterVariant } = column.columnDef.meta ?? {};
      if (filterVariant === 'range') {
        return (
          <Box
            key={column.id}
            width="100%"
            gap=".75rem"
            display="flex"
            flexDirection="row"
            mb="1.5rem"
          >
            <TableInput
              type="number"
              label="from"
              placeholder={`Min ${
                column.getFacetedMinMaxValues()?.[0] !== undefined
                  ? `(${column.getFacetedMinMaxValues()?.[0]})`
                  : ''
              }`}
              value={filterValues['from'] ?? ''}
              onChange={value =>
                setFilterValues(prev => ({
                  ...prev,
                  ['from']: value
                }))
              }
            />
            <TableInput
              type="number"
              label="to"
              placeholder={`Max ${
                column.getFacetedMinMaxValues()?.[1]
                  ? `(${column.getFacetedMinMaxValues()?.[1]})`
                  : ''
              }`}
              value={filterValues['to'] ?? ''}
              onChange={value =>
                setFilterValues(prev => ({
                  ...prev,
                  ['to']: value
                }))
              }
            />
          </Box>
        );
      }
    });

    return { filterTabColumns, filterTabElements };
  };

  const applyFilter = () => {
    type ColumnWithMeta = Column<TableDataT> & {
      columnDef: { meta?: ColumnMeta };
    };

    const filtersToApply: ColumnFilter[] = [];
    selectedFilters.forEach(filter => {
      filter.value.forEach(value => {
        filtersToApply.push({ id: filter.id, value });
      });
    });

    filterTabColumns.forEach((column: ColumnWithMeta) => {
      const { filterVariant } = column.columnDef.meta ?? {};

      if (filterVariant === 'range') {
        const fromValue = filterValues['from'];
        const toValue = filterValues['to'];
        if (fromValue !== undefined && toValue !== undefined) {
          column.setFilterValue([Number(fromValue), Number(toValue)]);
        }
      }
    });

    setColumnFilters(prevFilters => {
      const filtersToKeep = prevFilters.filter(
        filter => !filtersToApply.some(newFilter => newFilter.id === filter.id)
      );
      return [...filtersToKeep, ...filtersToApply];
    });

    setFilterPopup(false);
    setAnchorFilterEl(null);
  };

  const handleFilterSelection = (id: string, value: string) => {
    setSelectedFilters(prev => {
      const existingFilter = prev.find(filter => filter.id === id);
      if (existingFilter) {
        const newValues = existingFilter.value.includes(value)
          ? existingFilter.value.filter(v => v !== value)
          : [...existingFilter.value, value];

        return prev.map(filter =>
          filter.id === id ? { ...filter, value: newValues } : filter
        );
      } else {
        return [...prev, { id, value: [value] }];
      }
    });
  };

  const StringDataType = () => {
    if (filterId && tableData.length > 0) {
      return typeof tableData[0]?.[filterId as keyof TableDataT] === 'string'
        ? true
        : false;
    }
  };

  const renderData = (item: string) => {
    const isSelected = selectedFilters.some(
      filter => filter.id === filterId && filter.value.includes(item)
    );

    return (
      <Box
        sx={{
          backgroundColor: '#FCFCFD',
          borderRadius: '0.75rem',
          border: '0.0625rem solid #F5F6F',
          width: '100%',
          display: 'flex',
          padding: '0.75rem',
          alignItems: 'center',
          gap: '0.75rem',
          mb: '1rem'
        }}
        onClick={() => handleFilterSelection(filterId as string, item)}
      >
        <Checkbox
          sx={{
            width: '1.25rem',
            height: '1.25rem'
          }}
          checked={isSelected}
          checkedIcon={<Checked />}
          icon={<UnChecked />}
        />
        {item}
      </Box>
    );
  };

  const { filterTabElements, filterTabColumns } = getFilterTabColumnFn(
    filterTabs as (keyof TableDataT)[]
  );

  return (
    <Box
      width="30rem"
      height="25rem"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <Stack
        direction="column"
        sx={{
          width: '30%',
          borderRight: '.0625rem solid #F5F6F7',
          maxHeight: 'inherit',
          overflowY: 'auto',
          padding: '0.75rem'
        }}
      >
        {filterTabs &&
          filterTabs.map((element: keyof TableDataT, index: number) => {
            const isActiveTab = filterId === filterTabs[index];
            return (
              <MenuItem
                key={index}
                sx={{
                  padding: '.5rem .75rem',
                  height: '2.25rem',
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '.5rem'
                }}
                onClick={() => setFilterId(element)}
              >
                <Typography
                  variant="bodyMediumMedium"
                  color={`${isActiveTab ? '#344054' : '#98A2B3'}`}
                >
                  {_.startCase(`${element as string}`.replace(/_/g, ' '))}
                </Typography>
              </MenuItem>
            );
          })}
      </Stack>

      <Stack
        direction="column"
        display="flex"
        justifyContent="space-between"
        sx={{
          padding: '0.75rem',
          width: '70%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            width: '100%'
          }}
        >
          {StringDataType() ? (
            <SearchableContainer<string>
              searchPlaceholderText="Search"
              data={Array.from(
                new Set(
                  tableData.map(
                    item => item[filterId as keyof TableDataT] as string
                  )
                )
              )}
              renderDataList={renderData}
              searchField={[String(filterId as keyof TableDataT)]}
              containerStyles={{
                maxHeight: '15rem',
                overflowY: 'auto',
                mt: '1.5rem'
              }}
            />
          ) : (
            <Stack width="100%" direction="column">
              <Stack width="100%" gap=".75rem" direction="row">
                {filterTabElements &&
                  filterTabElements.map((input, index) => (
                    <React.Fragment key={index}>{input}</React.Fragment>
                  ))}
              </Stack>
            </Stack>
          )}
        </Box>

        <Box width="100%" mt="1.5rem">
          <Button
            color="primary"
            size="lg"
            onClick={applyFilter}
            text="Apply Filter"
            width="inherit"
            disabled={selectedFilters.length === 0}
          />
        </Box>
      </Stack>
    </Box>
  );
}

function TableInput({
  value: initialValue,
  label,
  type,
  placeholder,
  onChange,
  debounce = 1000,
  ...props
}: {
  value: string | number;
  label: string;
  placeholder: string;
  type: 'number' | 'text';
  onChange: (value: string | number) => void;
  debounce?: number;
}) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Stack width="100%" gap=".25rem" direction="column">
      <Typography variant="inputLabel" color="#667085">
        {label}
      </Typography>
      <TextField
        {...props}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={e => setValue(e.target.value)}
        sx={{
          '& .MuiOutlinedInput-root': {
            height: '2.25rem',
            borderRadius: '0.5rem',
            border: '1.5px solid #EAECF0',
            background: '#FEFFFF',
            boxShadow: '0px 4px 4px 0px rgba(220, 220, 220, 0.02)',
            '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent'
            },
            '&.Mui-focused fieldset': {
              borderColor: `${theme.palette.common.btnColor}`,
              boxShadow: `${theme.palette.common.boxShadow}`
            }
          }
        }}
      />
    </Stack>
  );
}
