import React, { useState } from 'react';

import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getFacetedMinMaxValues
} from '@tanstack/react-table';
import type {
  ColumnDef,
  ColumnFilter,
  ColumnFiltersState,
  ExpandedState,
  Row as RowT
} from '@tanstack/react-table';

import {
  Table as MUITable,
  TableContainer as MUITableContainer,
  Box,
  Avatar,
  Button as MUIButton,
  Typography,
  Popover
} from '@mui/material';

import filterIcon from 'assets/svg/filter.svg';

import _ from 'lodash';
import { SearchBar } from 'components/search';
import { Chip } from 'components/chip';

import { shadowsTheme } from 'themes/shadows';

import FilterModal from './filter';
import TableHeader from './subComponents/tableHeader';
import TableBody from './subComponents/body/tableBody';
import TableFooter from './subComponents/tableFooter';

interface TableDataWithSubRows<TableDataT> {
  subRows?: TableDataT[];
}

interface ColumnMeta {
  filterVariant?: 'range' | 'select' | 'range-slider';
}

interface TableProps<TableDataT> {
  columns: ColumnDef<TableDataT>[] & { meta?: ColumnMeta };
  data: TableDataT[];
  /**
   *  A callback for when the row is clicked, by default,
   * this will make the rows have focus styling on hover,
   *  but if optionsButton  is also set then the rows will not
   * have hover styling and instead an options button will be
   * rendered to the side of each row and made clickable
   */
  onClickRow?: (rowData: TableDataT) => void;
  filterColumns: string[];
  hover?: boolean;
  optionsButton?: boolean;
  showSearch?: boolean;
  showFooter?: boolean;
  searchPlaceholderText?: string;
  title?: string;
  subtitle?: string;
  enableFilter: boolean;
  filterTabs?: (keyof TableDataT)[];
  headerButton?: React.ReactNode;
  getRowCanExpand?: (row: RowT<TableDataT>) => boolean;
  showMenu?: boolean;
  menuItemProps?: {
    image: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string;
      }
    >;
    text: string;
    onClickMenuItem?: (rowData: TableDataT) => void;
    fill?: string;
  }[];
  hideTableHead?: boolean;
  loadingTable?: boolean;
}

export default function Table<TableDataT>({
  columns,
  data,
  onClickRow,
  filterColumns,
  hover,
  optionsButton,
  showSearch = true,
  showFooter = true,
  searchPlaceholderText,
  title,
  subtitle,
  enableFilter,
  headerButton,
  showMenu,
  hideTableHead,
  menuItemProps,
  getRowCanExpand,
  filterTabs,
  loadingTable
}: TableProps<TableDataT>) {
  const rowShouldHover = (hover || onClickRow) && !optionsButton;
  const [currentSearchText, setCurrentSearchText] = useState('');
  const [anchorFilterEl, setAnchorFilterEl] =
    useState<HTMLButtonElement | null>(null);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [filterPopup, setFilterPopup] = useState(false);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const showHeaders =
    title || subtitle || showSearch || enableFilter || headerButton;

  const filterFn = (
    row: RowT<TableDataT>,
    columnId: string,
    value: unknown
  ) => {
    const rowVal = _.lowerCase(_.toString(row.getValue(columnId)));
    const caseRemovedValue = _.lowerCase(_.toString(value));

    if (_.startsWith(rowVal, caseRemovedValue)) {
      return true;
    } else {
      return false;
    }
  };

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageSize: 5
      }
    },
    //State
    state: {
      expanded,
      columnFilters,
      globalFilter: currentSearchText
    },
    //Filter
    getColumnCanGlobalFilter: column => {
      if (filterColumns && _.isArray(filterColumns)) {
        return filterColumns.includes(column.id);
      }
      return false;
    },
    globalFilterFn: filterFn,
    onColumnFiltersChange: setColumnFilters,
    // Pipeline
    onExpandedChange: setExpanded,
    getSubRows: row => (row as TableDataWithSubRows<TableDataT>).subRows,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowCanExpand
  });

  const removeFilter = (id: string) => {
    setColumnFilters((columnFilters: ColumnFilter[]) => {
      return columnFilters.filter(column => column.id !== id);
    });
  };

  return (
    <Box
      sx={{
        padding: '0px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'stretch'
      }}
    >
      {showHeaders && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: title ? '1.5rem' : '0.75rem',
            height: '4.25rem',
            flexGrow: 1,
            maxWidth: '100%',
            backgroundColor: '#FFF',
            borderRadius: '0.75rem 0.75rem 0rem 0rem',
            border: '1px solid #F5F6F7',
            mb: columnFilters.length >= 1 ? '0rem' : '0.75rem'
          }}
        >
          {title && (
            <Box
              sx={{
                justifyContent: 'center',
                alignItems: 'start',
                flexWrap: 'wrap',
                flexDirection: 'column',
                display: 'flex',
                minWidth: '33%'
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '1rem',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '145%',
                  letterSpacing: '-0.035rem'
                }}
                color="#354554"
              >
                {title}
              </Typography>
              {subtitle && (
                <Typography
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '0.75rem',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '145%',
                    letterSpacing: '-0.0225rem'
                  }}
                  color="#617D99"
                >
                  {subtitle}
                </Typography>
              )}
            </Box>
          )}

          {showSearch && (
            <Box sx={{ flexGrow: '1' }}>
              <SearchBar
                size="sm"
                id="tableSearchBar"
                options={[]}
                onChange={value => {
                  setCurrentSearchText(value);
                }}
                placeholderText={searchPlaceholderText ?? 'Search'}
              />
            </Box>
          )}

          {enableFilter === true && (
            <MUIButton
              sx={{
                borderRadius: '0.5rem;',
                border: '0.09375em solid #EAECF0',
                backgroundColor: '#FFF',
                color: '#475467',
                display: 'flex',
                padding: '0.5rem 0.5rem 0.5rem 0.75rem',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '1rem',
                boxShadow: shadowsTheme.xxSmallShadowSoft,
                height: '2.25rem'
              }}
              disabled={data.length === 0 || !filterTabs}
              onClick={e => {
                setFilterPopup(true);
                setAnchorFilterEl(e.currentTarget);
              }}
              aria-describedby={filterPopup ? 'filter-popover' : undefined}
            >
              <Avatar
                src={filterIcon}
                sx={{ width: '16px', height: '16px', marginRight: '5px' }}
              />
              Filter
            </MUIButton>
          )}

          <Popover
            id={filterPopup ? 'filter-popover' : undefined}
            open={Boolean(anchorFilterEl)}
            anchorEl={anchorFilterEl}
            onClose={() => setAnchorFilterEl(null)}
            sx={{
              '& .MuiPopover-paper': {
                borderRadius: '.75rem',
                border: '.0625rem solid #EAECF0'
              }
            }}
            elevation={0}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <FilterModal<TableDataT>
              setAnchorFilterEl={setAnchorFilterEl}
              setFilterPopup={setFilterPopup}
              setColumnFilters={setColumnFilters}
              tableData={data}
              table={table}
              filterTabs={filterTabs}
            />
          </Popover>

          {headerButton && headerButton}
        </Box>
      )}

      {columnFilters.length >= 1 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '0.75rem',
            height: '3.75rem',
            flexGrow: 1,
            maxWidth: '100%',
            overflowX: 'auto',
            backgroundColor: '#FFF',
            borderTop: '.0625rem solid #F5F6F7',
            mb: '0.75rem'
          }}
        >
          {columnFilters.length >= 1
            ? columnFilters.map((el: ColumnFilter, index) => {
                return (
                  <Box key={index} sx={{ ml: '1rem' }}>
                    <Chip
                      label={`${el.value}`}
                      size={'md'}
                      onDelete={() => removeFilter(el.id)}
                    />
                  </Box>
                );
              })
            : null}
        </Box>
      )}

      <MUITableContainer
        component={Box}
        sx={{
          borderRadius: '12px',
          border: '1px solid #F5F6F7',
          backgroundColor: '#FFF',
          width: '100%'
        }}
      >
        <MUITable>
          {!hideTableHead && (
            <TableHeader<TableDataT>
              table={table}
              optionsButton={optionsButton}
            />
          )}
          <TableBody<TableDataT>
            table={table}
            optionsButton={optionsButton}
            rowShouldHover={rowShouldHover}
            showMenu={showMenu}
            menuItemProps={menuItemProps}
            hideTableHead={hideTableHead}
            onClickRow={onClickRow}
            loading={loadingTable}
            columns={columns}
          />
        </MUITable>
      </MUITableContainer>

      {showFooter && <TableFooter<TableDataT> table={table} />}
    </Box>
  );
}
