import storage from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

export const authPersistConfig = {
  key: 'auth',
  storage: storage,
  whitelist: [
    'isAuthenticated',
    'organizationId',
    'supplyChainProfileId',
    'organizationType',
    'userId',
    'permissions',
    'membershipType',
    'expiry',
    'devAuthToken',
    'branch'
  ],
  stateReconciler: autoMergeLevel2
};

export const themePersistConfig = {
  key: 'theme',
  storage,
  whitelist: ['isDarkMode'],
  stateReconciler: autoMergeLevel2
};

export const appPersistConfig = {
  key: 'app',
  storage: storage,
  whitelist: ['isLoaded'],
  stateReconciler: autoMergeLevel2
};
