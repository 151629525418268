import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { OnboardingContext } from './context/onboardingContext';

const OnboardingPage = () => {
  const { steps, currentStep } = useContext(OnboardingContext);

  return (
    <Box
      sx={{
        width: '34rem',
        margin: {
          md: '0 auto'
        },
        padding: '2.5rem',
        pt: '3rem',
        pb: '4rem'
      }}
    >
      {steps[currentStep].element}
    </Box>
  );
};

export default OnboardingPage;
