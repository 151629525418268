import React from 'react';
import { theme } from 'themes/theme';
import { Box, Typography, Paper, Stack } from '@mui/material';
import Resources from './resources';

const ResourcesCard = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: `${theme.palette.common.white}`,
        border: `0.063rem solid ${theme.palette.common.cardBorder}`,
        borderRadius: '0.75rem',
        gap: '1.5rem',
        padding: '1.5rem',
        width: '100%'
      }}
    >
      <Box
        width="inherit"
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography
          variant="bodyLargeSemibold"
          sx={{
            color: `${theme.palette.common.heading}`
          }}
        >
          Resources
        </Typography>
        <Typography
          variant="bodyMediumRegular"
          color={`${theme.palette.common.labelColor}`}
        >
          Useful articles on our privacy policies
        </Typography>
      </Box>

      <Box width="inherit" sx={{ mt: '1.5rem' }}>
        <Stack width="100%" direction="column" gap="1.5rem">
          <Resources label="Terms of Service" pdfLink="" />
          <Resources label="Privacy Policy" pdfLink="" />
          <Resources label="Data Processing Agreements" pdfLink="" />
        </Stack>
      </Box>
    </Paper>
  );
};

export default ResourcesCard;
