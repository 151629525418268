import React, { useState, useEffect } from 'react';
import mail from 'assets/svg/mail.svg';
import AuthModal from 'pages/auth/common/authModal';
import { useRegisterUserMutation } from 'services/api/authApi';
import { useAppDispatch } from 'services/hook';
import { fontFamily2, theme } from 'themes/theme';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Typography,
  Stack,
  CircularProgress,
  Link,
  Button
} from '@mui/material';
import { useForm, Resolver, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { setRegisterUser } from 'services/slices/registerSlice';
import { useGetTimer } from 'utilities/helperFunc/getTimer';
import { orgAuthValidator } from 'pages/auth/validators/authFormValidators';
import { FormInput, FormPassword } from 'components/form';

type FormValues = {
  firstname: string;
  lastname: string;
  password: string;
  email: string;
  confirmpassword: string;
};

const RegisterPage = () => {
  const [verifyEmail, setVerifyEmail] = useState(false);
  const { formattedTime, reset } = useGetTimer();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      confirmpassword: ''
    },
    resolver: yupResolver(orgAuthValidator) as unknown as Resolver<FormValues>
  });

  const [registerUser, { data, isSuccess, isError, error }] =
    useRegisterUserMutation();

  const onSubmit: SubmitHandler<FormValues> = async data => {
    await registerUser({
      password: data.password,
      confirmpassword: data.password,
      email: data.email,
      firstname: data?.firstname,
      lastname: data?.lastname
    });
  };

  interface MyError {
    data:
      | string
      | {
          email: string[];
        };
    status?: number;
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(setRegisterUser({ ...data }));
      toast.success('You`ve been registered successfully');
      setVerifyEmail(!verifyEmail);
    }
    if (error !== undefined) {
      const myError = error as MyError;
      if (
        typeof myError.data === 'object' &&
        Object.values(myError.data).length > 0
      ) {
        toast.error(Object.values(myError.data)[0].join(', '));
      } else if (typeof myError.data === 'string') {
        toast.error(myError.data);
      } else {
        toast.error('An error occurred');
      }
    }
  }, [isSuccess, isError]);

  const note = () => {
    return (
      <Typography
        variant="subtitle1"
        sx={{
          color: `${theme.palette.common.labelColor}`,
          fontWeight: 400
        }}
      >
        We sent an link to{' '}
        <span style={{ color: '#475467', fontWeight: 500 }}>
          jane@fmggroup.com.
        </span>{' '}
        If you do not have access to your email, contact{' '}
      </Typography>
    );
  };

  return (
    <>
      {verifyEmail ? (
        <AuthModal
          src={mail}
          text={'You’ve Got Mail'}
          subText={note()}
          onClick={() => navigate('/getting-started')}
          disabled={reset}
          skip={'Do this later'}
          btnText={reset ? `Resend email in ${formattedTime}` : 'Resend email'}
        />
      ) : (
        <Box
          sx={{
            width: '34rem',
            margin: {
              md: '0 auto'
            },
            padding: '2.5rem',
            pt: '7rem'
          }}
        >
          <Box sx={{ width: { lg: '29.125rem' } }}>
            <Box width="inherit" sx={{ mb: '2.5rem' }}>
              <Typography
                variant="h5"
                sx={{
                  color: `${theme.palette.common.heading}`
                }}
              >
                Welcome To Flux
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: `${theme.palette.common.labelColor}`
                }}
              >
                Your Gateway to Effortless Receipt Management.
              </Typography>
            </Box>

            <Box width="inherit" sx={{ mb: '1.75rem' }}>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Stack
                  width="100%"
                  direction="row"
                  justifyContent="space-between"
                  gap="1.5rem"
                >
                  <FormInput<FormValues>
                    control={control}
                    label="FIRST NAME"
                    name="firstname"
                  />

                  <FormInput<FormValues>
                    control={control}
                    label="LAST NAME"
                    name="lastname"
                  />
                </Stack>

                <FormInput<FormValues>
                  control={control}
                  label="WORK EMAIL"
                  name="email"
                />

                <FormPassword<FormValues>
                  control={control}
                  label="PASSWORD"
                  name="password"
                  checkPassword
                />

                <Button
                  type="submit"
                  variant="contained"
                  disabled={!isDirty || !isValid}
                  sx={{
                    padding: '0.75rem 1rem',
                    color: 'white',
                    width: '100%',
                    fontSize: '1rem'
                  }}
                >
                  {isSubmitting ? (
                    <CircularProgress
                      size={22}
                      sx={{
                        color: 'common.white',
                        margin: '0px 10px'
                      }}
                    />
                  ) : (
                    'Sign up'
                  )}
                </Button>
              </form>
            </Box>

            <Stack
              direction="row"
              width="inherit"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                color: '#98A2B3',
                fontFamily: fontFamily2,
                fontSize: '0.875rem',
                fontWeight: 400,
                lineHeight: '1.225rem',
                letterSpacing: '-0.01313rem'
              }}
            >
              <Typography component="span">
                By clicking sign up, I agree to TafiCasa{' '}
              </Typography>{' '}
              <Link
                href="/"
                sx={{
                  color: '#3E7DF8',
                  textDecoration: 'none'
                }}
              >
                <Typography component="span">Terms of use </Typography>
              </Link>
              <Typography component="span"> & </Typography>{' '}
              <Link
                href="/"
                sx={{
                  color: '#3E7DF8',
                  textDecoration: 'none'
                }}
              >
                <Typography component="span">Privacy Policy </Typography>
              </Link>
            </Stack>
          </Box>
        </Box>
      )}
    </>
  );
};

export default RegisterPage;
