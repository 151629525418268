import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { ReactComponent as Truck } from 'assets/svg/truck.svg';
import { FormDateInput, FormSelect } from 'components/form';
import { Button } from 'components/button';
import { SvgWrapper } from 'components/svg';
import { shadowsTheme } from 'themes/shadows';
import { schedulePickupValidator } from '../validator/pickupValidators';
import { Chip } from 'components/chip';
import ShowInfo from 'components/info/showInfo';
import {
  useGetPickupDepotsQuery,
  useSchedulePickupMutation
} from 'services/api/productReceiptApi';
import { ProductReceiptApiData } from 'services/types/productReceipt';

type FormValues = {
  pickup_date: string;
  depot: string;
};

type FormProps = {
  setSchedulePickupModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  productReceipt: ProductReceiptApiData;
};

const SchedulePickupForm = ({
  setSchedulePickupModalOpen,
  productReceipt
}: FormProps) => {
  const { data } = useGetPickupDepotsQuery({});
  const pickupRequestId = productReceipt.pickup_request?.product_request_id;
  const [schedulePickup, { isLoading }] = useSchedulePickupMutation();

  const customer = productReceipt.pickup_request?.customer;

  const depotOptions =
    data?.map(depot => ({
      title: depot.name,
      value: depot.depot_id
    })) || [];

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      pickup_date: '',
      depot: ''
    },
    resolver: yupResolver(
      schedulePickupValidator
    ) as unknown as Resolver<FormValues>
  });

  const depot = watch('depot');
  const pickup_date = watch('pickup_date');

  const onSubmit: SubmitHandler<FormValues> = async data => {
    pickupRequestId &&
      schedulePickup({
        pickupRequestId: pickupRequestId,
        productReceiptId: productReceipt.product_receipt_id,
        data: {
          collection_depot: data.depot,
          pickup_date: data.pickup_date
        }
      })
        .unwrap()
        .then(() => {
          ShowInfo({
            message: 'Pickup scheduled',
            subText: `Pickup date has been sent to ${customer?.name}`,
            type: 'success',
            componentType: 'toast',
            hideProgressBar: false
          });
          setSchedulePickupModalOpen(false);
        })
        .catch(() => {
          ShowInfo({
            message: 'Request failed',
            subText: 'Pickup has not been scheduled!',
            type: 'error',
            componentType: 'toast',
            hideProgressBar: false
          });
          setSchedulePickupModalOpen(false);
        });
  };

  return (
    <>
      <Stack
        display="flex"
        direction="column"
        alignItems="flex-start"
        sx={{
          padding: '1.25rem 1.25rem 1rem 1.25rem',
          borderBottom: '2px solid #FCFCFD',
          backgroundColor: '#FCFCFD',
          borderRadius: '0.75rem 0.75rem 0rem 0rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '2.25rem',
            width: '2.25rem',
            borderRadius: '6.25rem',
            backgroundColor: '#F8FBFE',
            padding: '0.5rem',
            boxShadow: shadowsTheme.iconShadow,
            marginBottom: '1rem'
          }}
        >
          <SvgWrapper
            icon={Truck}
            width="1.25rem"
            height="1.25rem"
            styleOverrides={{
              opacity: 0.8,
              fill: '#C3D7FD'
            }}
          />
        </Box>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h7Semibold" color="#344054">
            Schedule Pickup
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            Choose a date for product pickup for {customer?.name}
          </Typography>
          <Chip label={customer?.organization_id || ''} size="sm" icon />
        </Stack>
      </Stack>

      <Box
        sx={{
          mt: '1rem',
          padding: '1.25rem'
        }}
      >
        <FormSelect<FormValues>
          control={control}
          name="depot"
          label="DEPOT"
          options={depotOptions}
          renderSelected={(value: string) => value}
        />

        <FormDateInput<FormValues>
          control={control}
          name="pickup_date"
          label="PICKUP DATE"
          format="YYYY-MM-DD"
          disablePast
        />
      </Box>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: '0.0625rem solid #F5F6F7',
            padding: '1.25rem 1.5rem',
            marginBottom: '0.25rem'
          }}
        >
          <Button
            color="grey"
            transparent
            size="lg"
            text="Back"
            width="48%"
            onClick={() => setSchedulePickupModalOpen(false)}
          />
          <Button
            submit
            color="primary"
            size="lg"
            disabled={!isValid || !depot || !pickup_date}
            text="Schedule"
            width="48%"
            loadingIcon={isLoading}
          />
        </Box>
      </form>
    </>
  );
};

export default SchedulePickupForm;
