import dayjs from 'dayjs';

const units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

export const kbFormatter = (bytes: string) => {
  let l = 0,
    n = parseInt(bytes, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
};

export const formattedDate = (
  date: dayjs.Dayjs | string | number,
  formatString: string
) => {
  if (!date || date === '') {
    return '---';
  }
  return dayjs(date).locale('en').format(formatString);
};

export const formattedNumber = (
  value: number,
  isCurrency = false,
  locale = 'en-NG'
) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: isCurrency ? 'currency' : 'decimal',
    currency: isCurrency ? 'NGN' : undefined
  });

  return formatter.format(value);
};

export const formattedQuantity = (value: string) => {
  const [numberPart, unitPart] = value.split(' ');
  const number = Number(numberPart).toFixed(2);
  const formattedNumberPart = formattedNumber(Number(number), false);
  return `${formattedNumberPart} ${unitPart}`;
};
