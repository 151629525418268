import { api } from './baseApi';
import { setPermissions } from '../slices/authSlice';
import { providesObjectWithIds } from './utils';
import {
  RoleApiData,
  RolesApiData,
  PermissionsApiData,
  RoleRequestApiData,
  PermissionsInfoApiData,
  GroupedPermissionsApiData,
  GroupedRolePermissionsApiData
} from 'services/types/permissions';

type CreateRoleQueryArgs = {
  data: {
    name: string;
    description: string;
  };
};

type RoleRequestQueryArgs = {
  roleId: string;
  data: {
    users: string[];
  };
};

type AssignRoleQueryArgs = {
  roleRequestId: string;
};

export const permissionApi = api.injectEndpoints({
  endpoints: builder => ({
    getPermissions: builder.query<PermissionsApiData, unknown>({
      query: () => {
        return {
          url: 'permissions/users/({userId})',
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled
          .then(resp => {
            dispatch(
              setPermissions({
                permissions: resp.data
              })
            );
          })
          .catch(error => {
            console.error(error);
          });
      },
      providesTags: ['permissions']
    }),
    getGroupedPermissions: builder.query({
      query: ({ userId }) => {
        return {
          url: `permissions/users/(${userId})/?group=True`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      providesTags: ['groupedPermissions']
    }),
    getAllPermissions: builder.query<PermissionsInfoApiData, unknown>({
      query: () => {
        return {
          url: 'permissions/all/',
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      providesTags: ['permissions']
    }),
    getAllGroupedPermissions: builder.query<
      GroupedRolePermissionsApiData,
      unknown
    >({
      query: () => {
        return {
          url: 'permissions/all/?group=True',
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      providesTags: ['groupedPermissions']
    }),
    getUserRoles: builder.query({
      query: () => ({
        url: 'roles/user/({userId})/',
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    createRole: builder.mutation<RoleApiData, CreateRoleQueryArgs>({
      query: ({ data }) => ({
        url: 'organizations/({organizationId})/roles/',
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: [{ type: 'roles', id: 'LIST' }]
    }),
    getRoles: builder.query<RolesApiData[], unknown>({
      query: () => ({
        url: 'organizations/({organizationId})/roles/',
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      providesTags: result => {
        if (result) {
          return providesObjectWithIds(
            result.map(item => item.role_id),
            'roles'
          );
        }
        return [];
      }
    }),
    addRolePermissions: builder.mutation({
      query: ({ data, roleId }) => ({
        url: `organizations/({organizationId})/roles/(${roleId})/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: [{ type: 'rolePermission', id: 'LIST' }]
    }),
    getRoleBearers: builder.query({
      query: ({ organizationId, roleId }) => ({
        url: `organizations/(${organizationId})/roles/(${roleId})/bearers/`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getGroupedRolePermissions: builder.query<
      GroupedPermissionsApiData,
      { roleId: string }
    >({
      query: ({ roleId }) => {
        return {
          url: `organizations/({organizationId})/roles/(${roleId})/?group=True`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      providesTags: result => {
        if (result) {
          return providesObjectWithIds(Object.keys(result), 'rolePermission');
        }
        return [];
      }
    }),
    assignRole: builder.mutation<RoleRequestApiData, AssignRoleQueryArgs>({
      query: ({ roleRequestId }) => ({
        url: `organizations/({organizationId})/role/request/(${roleRequestId})/complete/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'roleRequest', id: args.roleRequestId }
      ]
    }),
    getRoleRequest: builder.query<
      RoleRequestApiData,
      { roleRequestId: string }
    >({
      query: ({ roleRequestId }) => ({
        url: `organizations/({organizationId})/role/request/(${roleRequestId})/`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      providesTags: (result, error, args) => [
        { type: 'roleRequest', id: args.roleRequestId }
      ]
    }),
    getRoleRequests: builder.query<RoleRequestApiData[], unknown>({
      query: () => ({
        url: 'organizations/({organizationId})/role/request',
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    createRoleRequest: builder.mutation<
      RoleRequestApiData,
      RoleRequestQueryArgs
    >({
      query: ({ roleId, data }) => ({
        url: `organizations/({organizationId})/role/(${roleId})/request/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
    })
  }),
  overrideExisting: false
});

export const {
  useGetPermissionsQuery,
  useGetGroupedPermissionsQuery,
  useGetAllPermissionsQuery,
  useGetAllGroupedPermissionsQuery,
  useGetGroupedRolePermissionsQuery,
  useGetRolesQuery,
  useGetUserRolesQuery,
  useAssignRoleMutation,
  useGetRoleRequestQuery,
  useGetRoleRequestsQuery,
  useCreateRoleMutation,
  useAddRolePermissionsMutation,
  useGetRoleBearersQuery,
  useCreateRoleRequestMutation,
  usePrefetch
} = permissionApi;
