import { Chip, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { ReactComponent as InfoIcon } from 'assets/svg/infoSolid.svg';
import { ReactComponent as LayersIcon } from 'assets/svg/layerSolid.svg';
import { ReactComponent as PlusIcon } from 'assets/svg/plus2.svg';
import { SvgWrapper } from 'components/svg';
import React from 'react';
import { DashboardIndicatorData } from 'services/types/dashboard';

const IndicatorCard = ({ title, value, orderStat }: DashboardIndicatorData) => {
  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        padding: '1rem',
        alignItems: 'center',
        backgroundColor: '#FFF',
        border: '2px solid #F5F6F7',
        borderRadius: '0.5rem',
        flexGrow: '1'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '1.0rem',
          flexShrink: '0'
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          width="inherit"
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              flex: '1 0 0'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                padding: '0.5rem',
                alignItems: 'flex-start',
                gap: '0.5rem'
              }}
            >
              <SvgWrapper icon={LayersIcon} width="1rem" height="1rem" />
            </Box>
            <Typography variant="bodyMediumMedium" color="#667085">
              {title}
            </Typography>
          </Stack>

          <Stack>
            <SvgWrapper icon={InfoIcon} width="1.25rem" height="1.25rem" />
          </Stack>
        </Stack>
        <Stack direction="column" alignItems="flex-start" spacing={1}>
          <Typography variant="h4Bold" color="#475467">
            {value}
          </Typography>
          <Stack direction="row" alignItems="center">
            <Chip
              sx={{
                height: 'auto',
                width: 'auto',
                backgroundColor: '#E9FFF3',
                color: '#65A793',
                borderRadius: '0.25rem',
                display: 'flex',
                padding: '0.125rem 0.25rem',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              icon={
                <SvgWrapper
                  icon={PlusIcon}
                  width="0.75rem"
                  height="0.75rem"
                  color="#65A793"
                  styleOverrides={{
                    stroke: '#65A793'
                  }}
                />
              }
              size="small"
              label={`${orderStat} orders`}
            />

            <Typography variant="bodySmallMedium" color="#98A2B3">
              vs Last Month
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
};

export default IndicatorCard;
