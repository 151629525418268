import React from 'react';
import { FormControl, FormLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import type { FieldValues } from 'react-hook-form';
import { MuiTelInput } from 'mui-tel-input';
import type { FormTelephoneInputProps } from 'components/form/common/formTypes';
import { fontFamily2, theme } from 'themes/theme';

export default function FormTelephoneInput<FormValueT extends FieldValues>(
  props: FormTelephoneInputProps<FormValueT>
) {
  const { label, name, control, onlyCountries } = props;

  return (
    <FormControl
      fullWidth
      sx={{
        paddingBottom: { xs: 3 },
        '& .css-13nvzlm-MuiFormHelperText-root': {
          fontSize: '.813rem',
          ml: 0
        }
      }}
    >
      <FormLabel
        htmlFor={name}
        sx={{
          fontFamily: fontFamily2,
          fontSize: '0.75rem',
          fontWeight: 400,
          lineHeight: '1.05rem',
          letterSpacing: '-0.015rem',
          color: `${theme.palette.common.labelColor}`,
          mb: '0.25rem'
        }}
      >
        {label}
      </FormLabel>
      <Controller
        control={control}
        name={name}
        rules={{ required: true }}
        render={({
          field: { ref: fieldRef, value, onChange, ...fieldProps },
          fieldState: { error, isTouched, invalid }
        }) => {
          return (
            <MuiTelInput
              {...fieldProps}
              name={name}
              id={name}
              value={value ?? ''}
              onChange={(newValue: string) => {
                onChange(newValue);
              }}
              inputRef={fieldRef}
              onlyCountries={onlyCountries}
              defaultCountry="NG"
              helperText={error?.message}
              error={invalid && isTouched}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '3.2rem',
                  borderRadius: '0.5rem',
                  border: '.094rem solid #EAECF0',
                  background: '#FEFFFF',
                  boxShadow:
                    '0rem .25rem .25rem 0rem rgba(220, 220, 220, 0.02)',
                  '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent'
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: `${theme.palette.common.btnColor}`,
                    boxShadow: `${theme.palette.common.boxShadow}`
                  }
                }
              }}
              MenuProps={{
                sx: {
                  '& .MuiMenu-paper': {
                    borderRadius: '0.75rem',
                    overflowY: 'auto',
                    maxHeight: '15rem',
                    width: '21.8rem'
                  }
                }
              }}
              FormHelperTextProps={{
                sx: {
                  ml: '0rem',
                  mr: '0rem',
                  fontFamily: `${fontFamily2}`,
                  fontSize: '.875rem',
                  fontWeight: 400,
                  lineHeight: '1.188rem',
                  letterSpacing: '-0.025em',
                  width: '100%',
                  textAlign: 'left',
                  color: `${theme.palette.common.error}`
                }
              }}
            />
          );
        }}
      />
    </FormControl>
  );
}
