import React from 'react';
import { theme } from 'themes/theme';
import linkExternal from 'assets/svg/linkExternal.svg';
import { Typography, Stack, Button, Avatar } from '@mui/material';

interface ResourcesProps {
  label: string;
  pdfLink: string;
}

const Resources: React.FC<ResourcesProps> = ({ label, pdfLink }) => {
  const openPdf = () => {
    const pdfUrl = pdfLink;
    window.open(pdfUrl, '_blank');
  };

  return (
    <Stack
      width="100%"
      padding="1rem 1.25rem"
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      border="0.063rem solid #F2F4F7"
      borderRadius="0.5rem"
    >
      <Typography
        variant="bodyMediumSemibold"
        color={`${theme.palette.common.subheading}`}
      >
        {label}
      </Typography>
      <Button
        sx={{ padding: 0 }}
        onClick={openPdf}
        startIcon={
          <Avatar
            src={linkExternal}
            sx={{ width: '1.25rem', height: '1.25rem', flexShrink: 0 }}
          />
        }
      />
    </Stack>
  );
};

export default Resources;
