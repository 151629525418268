import React from 'react';
import { ReactComponent as X } from 'assets/svg/x.svg';
import { ReactComponent as Dot } from 'assets/svg/dot.svg';
import { TypographyProps } from '@mui/material/Typography';
import { Chip as MuiChip, Typography } from '@mui/material';

type MuiTypographyVariant = TypographyProps['variant'];

interface ChipProps {
  size: 'xs' | 'sm' | 'md' | 'lg';
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'error'
    | 'default'
    | 'inherit';
  label: string;
  onDelete?: () => void;
  icon?: boolean;
}

const Chip: React.FC<ChipProps> = ({ size, label, onDelete, icon, color }) => {
  const getVariantForText = (size: ChipProps['size']): MuiTypographyVariant => {
    let variant: MuiTypographyVariant = 'body1';
    if (size == 'xs') {
      variant = 'bodySmallMedium';
    }
    if (size == 'sm') {
      variant = 'bodyMediumMedium';
    }
    return variant;
  };

  const getStyleForChip = (color: ChipProps['color']) => {
    switch (color) {
      case 'primary':
        return {
          color: '#026AA2',
          fill: '#026AA2',
          backgroundColor: '#F0F9FF'
        };
      case 'success':
        return {
          color: '#19956C',
          fill: '#19956C',
          backgroundColor: '#E8F6F1'
        };
      case 'warning':
        return {
          color: '#E5A100',
          fill: '#E5A100',
          backgroundColor: '#FFFAEB'
        };
      case 'error':
        return {
          color: '#D92D20',
          fill: '#D92D20',
          backgroundColor: '#FFEBEA'
        };
      case 'secondary':
        return {
          color: '#4649E3',
          fill: '#4649E3',
          backgroundColor: '#EAEAFC'
        };
      case 'inherit':
        return {
          color: 'inherit',
          fill: 'inherit',
          backgroundColor: '#F5F6F7'
        };
      default:
        return {
          color: '#475467',
          fill: '#475467',
          backgroundColor: '#F5F6F7'
        };
    }
  };

  const style = {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0.5rem',
    ...getStyleForChip(color)
  };

  return (
    <MuiChip
      label={
        <Typography variant={getVariantForText(size)} color={color}>
          {label}
        </Typography>
      }
      sx={style}
      icon={
        icon ? (
          <Dot style={{ marginLeft: '10px', ...getStyleForChip(color) }} />
        ) : undefined
      }
      onDelete={onDelete}
      deleteIcon={<X />}
    />
  );
};

export default Chip;
