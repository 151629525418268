import React, { useContext } from 'react';
import { TeamsContext } from '../../teamsContext';
import BulkTeamUpload from './bulkTeamUpload';
import ViewBulkFileUpload from './viewBulkFileUpload';

const BulkUploadTeamStep = () => {
  const { uploadedFile } = useContext(TeamsContext);

  return (
    <>{uploadedFile !== null ? <ViewBulkFileUpload /> : <BulkTeamUpload />}</>
  );
};

export default BulkUploadTeamStep;
