import React, { useState, createContext, PropsWithChildren } from 'react';
import AddBranchStep from './steps/base/addBranchStep';
import InviteBranchMembersStep from './steps/base/inviteBranchMembersStep';
import ManualUploadStartStep from './steps/manual-upload/manualUploadStartStep';
import BulkFileUploadStep from './steps/bulk-upload/bulkFileUploadStep';

type BranchesData = {
  name: string;
  street_address_1: string;
  street_address_2: string;
  state: string;
  locality: string;
  country: string;
  members: string[];
  file: File | null;
};

const initialValues: BranchesData = {
  name: '',
  street_address_1: '',
  street_address_2: '',
  state: '',
  locality: '',
  country: '',
  members: [],
  file: null
};

type BranchesContextWrapperProps = {
  setAddBranchOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type BranchesContextTypes = {
  data: BranchesData;
  setData: React.Dispatch<React.SetStateAction<BranchesData>>;
  currentStep: number;
  steps: { element: JSX.Element }[];
  handleNextStep: (newData?: Partial<BranchesData>) => void;
  handlePrevStep: () => void;
  goToStep: (index: number) => void;
  uploadedFile: File | null;
  setUploadedFile: React.Dispatch<React.SetStateAction<File | null>>;
  setAddBranchOpen: BranchesContextWrapperProps['setAddBranchOpen'];
};

const BranchesContextDefaults: BranchesContextTypes = {
  data: initialValues,
  setData: () => undefined,
  currentStep: 0,
  steps: [],
  handleNextStep: () => undefined,
  handlePrevStep: () => undefined,
  goToStep: () => undefined,
  uploadedFile: null,
  setUploadedFile: () => undefined,
  setAddBranchOpen: () => undefined
};

export const BranchesContext = createContext<BranchesContextTypes>(
  BranchesContextDefaults
);

const BranchesContextWrapper = ({
  children,
  setAddBranchOpen
}: PropsWithChildren<BranchesContextWrapperProps>) => {
  const [data, setData] = useState<BranchesData>(initialValues);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [uploadedFile, setUploadedFile] = useState<null | File>(null);

  const steps = [
    { element: <AddBranchStep /> },
    { element: <InviteBranchMembersStep /> },
    { element: <ManualUploadStartStep /> },
    { element: <BulkFileUploadStep /> }
  ];

  const handleNextStep = (newData?: Partial<BranchesData>) => {
    if (newData) {
      setData(prev => ({ ...prev, ...newData }));
    }
    setCurrentStep(i => {
      if (i === 2) return i;
      if (i >= steps.length - 1) return i;
      return i + 1;
    });
  };

  const handlePrevStep = () => {
    setCurrentStep(i => {
      if (i <= 0) return i;
      return i - 1;
    });
  };

  const goToStep = (index: number) => {
    setCurrentStep(index);
  };

  const values: BranchesContextTypes = {
    data,
    setData,
    currentStep,
    steps,
    handleNextStep,
    handlePrevStep,
    goToStep,
    uploadedFile,
    setUploadedFile,
    setAddBranchOpen
  };

  return (
    <BranchesContext.Provider value={values}>
      {children}
    </BranchesContext.Provider>
  );
};

export default BranchesContextWrapper;
