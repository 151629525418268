import React, { useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { FormDateInput, FormCheckBox } from 'components/form';
import { useLazyGetTopProductsQuery } from 'services/api/analyticsApi';
import { TopProductsAnalyticsApiData } from 'services/types/analytics';
import { formatPrcQuantityAxis } from '../utils/formatAxis';

Chart.register(CategoryScale);

type FormValues = {
  start: string;
  end: string;
  includeClosed: boolean;
};

const TopProductsChart = () => {
  const [analyticsResult, setAnalyticsResult] = useState<
    TopProductsAnalyticsApiData[]
  >([]);
  const [getTopProducts] = useLazyGetTopProductsQuery();

  const { getValues, control } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      start: '2024-01-01',
      end: '',
      includeClosed: true
    }
  });

  const data: ChartData<'bar'> = useMemo(() => {
    if (analyticsResult) {
      return {
        labels: analyticsResult.map(item => item.name),
        datasets: [
          {
            label: 'Top Products',
            data: analyticsResult.map(item => item.value),
            backgroundColor: 'rgba(62, 125, 248, 0.8)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
          }
        ]
      };
    }
    return {
      labels: [],
      datasets: [
        {
          label: 'Top Products',
          data: [],
          backgroundColor: 'rgba(62, 125, 248, 0.8)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        }
      ]
    };
  }, [analyticsResult]);

  const options: ChartOptions<'bar'> = {
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 20,
          callback: formatPrcQuantityAxis
        }
      },
      y: {
        beginAtZero: true
      }
    },
    indexAxis: 'y',
    animation: {
      duration: 2000
    },
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false
      },
      legend: {
        display: false
      }
    }
  };

  const onParamSelect = async () => {
    const includeClosed = getValues('includeClosed');
    const startDate = getValues('start');
    const endDate = getValues('end');

    // Make api call for data and set result
    await getTopProducts({
      params: {
        start: startDate,
        ...(includeClosed && { include_closed: 'true' }),
        ...(endDate !== '' && { end: endDate })
      }
    })
      .unwrap()
      .then(result => {
        setAnalyticsResult(result);
      })
      .catch(error => console.log(error));
  };

  // Fetch default data on load
  useEffect(() => {
    (async () => {
      await getTopProducts({
        params: {
          start: '2024-01-01'
        }
      })
        .unwrap()
        .then(result => {
          setAnalyticsResult(result);
        })
        .catch(error => console.log(error));
    })();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%'
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="1.25rem 0rem 1.25rem 0rem"
        spacing={1}
        width="100%"
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          flex="1 0 0"
        >
          <Typography variant="bodyMediumSemibold">Top Products</Typography>
          <Typography
            variant="bodyMediumMedium"
            color="#98A2B3"
            sx={{ width: 'auto' }}
          >
            By PRC&apos;s request
          </Typography>
        </Stack>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '0.5rem',
              alignItems: 'center'
            }}
          >
            <Box sx={{ minWidth: '10rem' }}>
              <FormCheckBox<FormValues>
                control={control}
                label="Include Closed"
                name="includeClosed"
                onChange={onParamSelect}
              />
            </Box>
            <FormDateInput<FormValues>
              control={control}
              name="start"
              label="Start Date"
              format="YYYY-MM-DD"
              onChange={onParamSelect}
            />
            <FormDateInput<FormValues>
              control={control}
              name="end"
              label="End Date"
              format="YYYY-MM-DD"
              onChange={onParamSelect}
            />
          </Box>
        </Box>
      </Stack>
      <Box width="inherit" height="100%">
        <Bar data={data} options={options} />
      </Box>
    </Box>
  );
};

export default TopProductsChart;
