import React, { useMemo, useState } from 'react';
import { Table } from 'components/table';

import { createColumnHelper } from '@tanstack/react-table';
import type { ColumnDef } from '@tanstack/react-table';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { Chip } from 'components/chip';
import { SvgWrapper } from 'components/svg';
import { ReactComponent as Lock } from 'assets/svg/lock-solid.svg';
import EditPermissions from '../users/editPermissions';
import { useGetMembersQuery } from 'services/api/organizationApi';
import { Member, MemberWithDetails, Role } from '../types/permissionTypes';
import { PermissionType } from 'services/enums/permissions';

const renderPermissions = (permissions: PermissionType[]) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <Box
      display="flex"
      alignItems="center"
      padding="0.5rem"
      sx={{ background: '#F5F6F7', borderRadius: '0.5rem' }}
    >
      <SvgWrapper height="1rem" width="1rem" icon={Lock} color="#98A2B3" />
    </Box>
    <Typography variant="bodyMediumMedium" color="#475467">
      {permissions.length > 0 ? `${permissions.length}+` : '0'}
    </Typography>
  </Stack>
);

const renderChips = (data: Role[], maxShown: number) => {
  if (data.length === 0) return <p>_</p>;

  const renderedChips = data
    .slice(0, maxShown)
    .map((item, index) => <Chip key={index} label={item.name} size="sm" />);

  if (data.length > maxShown) {
    renderedChips.push(
      <Chip key={maxShown} label={`+${data.length - maxShown}`} size="sm" />
    );
  }

  return renderedChips;
};

const columnHelper = createColumnHelper<MemberWithDetails>();
const columns = [
  columnHelper.accessor('member', {
    header: 'USERS',
    cell: info => (
      <Stack display="flex" flexDirection="row">
        <Avatar
          src={`${info.getValue().profile_picture}`}
          sx={{
            width: '2rem',
            height: '2rem',
            mr: '0.75rem',
            justifyContent: 'center',
            borderRadius: '12.5rem'
          }}
        />
        <Stack display="flex" flexDirection="column" justifyContent="center">
          <Typography variant="bodyMediumMedium" color="#344054">
            {`${info.getValue().firstname} ${info.getValue().lastname}`}
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            {info.getValue().email}
          </Typography>
        </Stack>
      </Stack>
    )
  }),
  columnHelper.accessor('roles', {
    header: 'ROLES',
    cell: info => (
      <Stack direction={'row'} spacing={1}>
        {renderChips(info.getValue(), 2)}
      </Stack>
    )
  }),
  columnHelper.accessor('permissions', {
    header: 'PERMISSIONS ASSIGNED',
    cell: info => <>{renderPermissions(info.getValue())}</>
  })
] as ColumnDef<MemberWithDetails>[];
// Cast is a workaround due to issues with tansatck table
// See https://github.com/TanStack/table/issues/4302

const PermissionsTable = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: members, isLoading: isLoadingMembers } = useGetMembersQuery({});
  const [selectedMember, setSelectedMember] =
    useState<MemberWithDetails | null>(null);

  const handleRowClick = (member: MemberWithDetails) => {
    setSelectedMember(member);
    setIsOpen(true);
  };

  const membersWithDetails = useMemo(() => {
    if (!members) return [];

    return members.map((member: Member) => ({
      member,
      roles: member.roles || [],
      permissions: member.permissions || []
    }));
  }, [members]);

  return (
    <>
      {selectedMember && (
        <EditPermissions
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
            setSelectedMember(null);
          }}
          data={selectedMember}
        />
      )}
      <Table<MemberWithDetails>
        hover
        columns={columns}
        data={membersWithDetails}
        onClickRow={handleRowClick}
        optionsButton
        enableFilter={false}
        filterColumns={['name']}
        searchPlaceholderText="Search for Permissions"
        loadingTable={isLoadingMembers}
      />
    </>
  );
};

export default PermissionsTable;
