import * as Yup from 'yup';

export const agentInformationFormValidator = Yup.object({
  agent_photo: Yup.mixed<FileList>()
    .nullable()
    .required()
    .test('fileType', value => {
      if (!value || !value[0]) return true;
      return value[0].type === 'image/*';
    })
    .test('fileSize', 'File size is too large', value => {
      if (!value || !value[0]) return true;
      const maxSize = 5 * 1024 * 1024;
      return value[0].size <= maxSize;
    }),
  name: Yup.string().required('This field is required'),
  phone_number: Yup.string().required('This field is required'),
  email: Yup.string().email().required('This field is required')
});

export const vehicleInformationFormValidator = Yup.object({
  vehicle_photo: Yup.mixed<FileList>()
    .nullable()
    .test('fileType', value => {
      if (!value || !value[0]) return true;
      return value[0].type === 'image/*';
    })
    .test('fileSize', 'File size is too large', value => {
      if (!value || !value[0]) return true;
      const maxSize = 5 * 1024 * 1024;
      return value[0].size <= maxSize;
    }),
  vehicle_make: Yup.string().required('This field is required'),
  vehicle_model: Yup.string().required('This field is required'),
  license: Yup.string().required('This field is required'),
  identifying_markers: Yup.string()
});

export const schedulePickupValidator = Yup.object({
  depot: Yup.string().required('This field is required'),
  pickup_date: Yup.string().required('This field is required')
});

export const validatePickupCodeValidator = Yup.object({
  pickup_code: Yup.string().required('This field is required')
});
