import React, { useContext } from 'react';
import { Stack, Box, Typography } from '@mui/material';

import { Button } from 'components/button';

import WorkflowBuilderStepCard from '../workflowBuilderStepCard';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable as Droppable } from 'components/beautiful-dnd';
import type { DropResult } from 'react-beautiful-dnd';
import _ from 'lodash';

import { WorkflowBuilderContext } from '../context/builderContext';

import { ReactComponent as plusIcon } from 'assets/svg/plus.svg';

const WorkflowBuilderSideBar: React.FC = () => {
  const {
    workflowSteps,
    setWorkflowSteps,
    addNewStep,
    setResetVersionOpen: setDeleteVersionOpen,
    currentWorkflowVersion
  } = useContext(WorkflowBuilderContext);

  const onDragEnd = (result: DropResult) => {
    const { destination, draggableId } = result;
    if (destination && destination.index != _.toInteger(draggableId)) {
      const currentSteps = _.cloneDeep(workflowSteps);
      // Remove the dragged step from its place
      const currentDraggedStep = currentSteps.splice(
        _.toInteger(draggableId),
        1
      );

      // Add the removed step to its new place in the array
      currentSteps.splice(destination.index, 0, currentDraggedStep[0]);

      // Set new steps
      setWorkflowSteps(currentSteps);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        padding: '1rem 0.75rem'
      }}
    >
      <Box
        sx={{
          padding: '1rem 0.75rem 0.75rem 1rem',
          borderBottom: '0.0625rem solid #F5F6F7'
        }}
      >
        <Typography variant="captionLarge" color="#667085">
          Version {currentWorkflowVersion}
        </Typography>
      </Box>
      <Stack
        justifyContent="center"
        alignItems="start"
        gap="0.25rem"
        sx={{
          padding: '1.5rem 1rem 1rem 0.5rem',
          borderBottom: '0.0625rem solid #F5F6F7'
        }}
      >
        <Typography variant="bodyLargeSemibold" color="#475467">
          All Actions
        </Typography>
        <Typography variant="bodyMediumRegular" color="#667085">
          Rearrange and add new actions here
        </Typography>
      </Stack>
      <Box sx={{ marginTop: '2.5rem', overflowY: 'auto', width: '100%' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-container-workflow-builder-sidebar">
            {provided => (
              <Box
                sx={{ width: '100%' }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {workflowSteps.map((step, index) => {
                  const { title, description } = step;
                  return (
                    <Draggable
                      key={`draggable-step-card-${index}`}
                      draggableId={_.toString(index)}
                      index={index}
                    >
                      {provided => (
                        <WorkflowBuilderStepCard
                          title={title}
                          description={description}
                          draggableProps={provided.draggableProps}
                          forwardedRef={provided.innerRef}
                          dragHandleProps={provided.dragHandleProps}
                        />
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Stack
        alignItems="center"
        sx={{ flexShrink: 1, margin: 'auto 1rem 1rem 0.75rem' }}
      >
        <Button
          color="primary"
          size="md"
          text="Add new action"
          width="100%"
          styleOverrides={{
            margin: '1.25rem 1rem'
          }}
          onClick={() => {
            addNewStep();
          }}
          icon={plusIcon}
          iconPosition="start"
        />
        <Button
          color="grey"
          transparent
          size="md"
          text="Reset Workflow"
          width="100%"
          onClick={() => {
            setDeleteVersionOpen(true);
          }}
        />
      </Stack>
    </Box>
  );
};
export default WorkflowBuilderSideBar;
