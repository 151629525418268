import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Paper,
  Typography,
  Button
} from '@mui/material';
import lock from 'assets/svg/lock.svg';
import AuthModal from 'pages/auth/common/authModal';
import { theme } from 'themes/theme';
import { useNavigate, Link } from 'react-router-dom';
import { FormInput } from 'components/form';
import { emailValidator } from './validators/authFormValidators';
import { useGetTimer } from 'utilities/helperFunc/getTimer';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Resolver, SubmitHandler } from 'react-hook-form';
import { usePasswordResetRequestMutation } from 'services/api/userApi';
import { toast } from 'react-toastify';
import { MyError } from './resetPasswordPage';

type FormValues = {
  email: string;
};

const ForgotPassword = () => {
  const [showModal, setShowModal] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const { formattedTime, reset } = useGetTimer();

  const navigate = useNavigate();

  const [passwordResetRequest, { isSuccess, error, isError }] =
    usePasswordResetRequestMutation();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(emailValidator) as unknown as Resolver<FormValues>
  });

  const onSubmit: SubmitHandler<FormValues> = data => {
    if ('email' in data && typeof data.email === 'string') {
      setUserEmail(data.email);
      passwordResetRequest(data as FormValues);
    }
    isSuccess && setShowModal(!showModal);
  };

  useEffect(() => {
    isSuccess && setShowModal(!showModal);
    if (error !== undefined) {
      const myError = error as MyError;
      if (
        typeof myError.data === 'object' &&
        typeof myError.data !== 'string' &&
        Object.values(myError.data).length > 0
      ) {
        toast.error(
          `${Object.keys(myError.data)[0]}: ${Object.values(myError.data)[0]}`
        );
      } else if (typeof myError.data === 'string') {
        toast.error(myError.data);
      } else {
        toast.error('Reset request unsuccessful.');
      }
    }
  }, [isSuccess, isError]);

  const note = () => {
    return (
      <Typography
        variant="subtitle1"
        sx={{
          color: `${theme.palette.common.labelColor}`,
          fontWeight: 400
        }}
      >
        We sent an link to{' '}
        <span style={{ color: '#475467', fontWeight: 500 }}>{userEmail}</span>{' '}
        If you do not have access to your email, contact{' '}
        <Link to="/" style={{ color: '#1D2939' }}>
          customer support.
        </Link>
      </Typography>
    );
  };

  return (
    <>
      {showModal ? (
        <AuthModal
          src={lock}
          text={'Reset Password'}
          subText={note()}
          disabled={reset}
          onClick={() => navigate('/password/reset/:resetId')}
          btnText={reset ? `Resend email in ${formattedTime}` : 'Resend email'}
        />
      ) : (
        <Paper
          elevation={0}
          sx={{
            backgroundColor: `${theme.palette.common.card}`,
            border: `1.5px solid ${theme.palette.common.cardBorder}`,
            borderRadius: '.75rem',
            width: '30rem',
            margin: {
              md: '0 auto'
            },
            padding: '2.5rem 1.5rem',
            marginTop: {
              md: '13rem'
            },
            marginBottom: {
              md: '16rem'
            }
          }}
        >
          <Box sx={{ width: { lg: '27rem' } }}>
            <Box width="inherit" sx={{ mb: '2.56rem', textAlign: 'center' }}>
              <Typography
                variant="h5"
                sx={{
                  color: `${theme.palette.common.subheading}`,
                  fontSize: '1.25rem',
                  fontWeight: 700
                }}
              >
                Enter Your Email
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: `${theme.palette.common.labelColor}`,
                  fontWeight: 400
                }}
              >
                A unique link would be sent to your email to reset your
                password.
              </Typography>
            </Box>

            <Box width="inherit">
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormInput<FormValues>
                  control={control}
                  label="EMAIL"
                  name="email"
                />

                <Button
                  type="submit"
                  variant="contained"
                  disabled={!isDirty || !isValid}
                  sx={{
                    padding: '0.75rem 1rem',
                    color: 'white',
                    width: '100%',
                    fontSize: '1rem'
                  }}
                >
                  {isSubmitting ? (
                    <CircularProgress
                      size={22}
                      sx={{
                        color: 'common.white',
                        margin: '0px 10px'
                      }}
                    />
                  ) : (
                    'Send link'
                  )}
                </Button>
              </form>
            </Box>
          </Box>
        </Paper>
      )}
    </>
  );
};

export default ForgotPassword;
