import React from 'react';
import { ReactComponent as User } from 'assets/svg/add-user.svg';
import { ReactComponent as IconLeft } from 'assets/svg/book-open.svg';
import { ReactComponent as Question } from 'assets/svg/helpSolid.svg';
import { SearchBar } from 'components/search';
import { Stack, Toolbar, Box, Divider, Button } from '@mui/material';
import { theme } from 'themes/theme';
import { SvgWrapper } from 'components/svg';
import BreadCrumb from './breadCrumb';

const AppNavBar = () => {
  return (
    <Toolbar
      sx={{
        width: 'inherit',
        height: '4.25rem',
        borderBottom: `1px solid ${theme.palette.common.cardBorder}`,
        padding: {
          md: '.75rem 1.75rem',
          lg: '1rem 2rem',
          xl: '1rem 5rem'
        }
      }}
    >
      <Stack
        width="100%"
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <BreadCrumb />

        <Box width="30rem" height="2.75rem">
          <SearchBar
            size="lg"
            id="localSearch"
            options={[]}
            onChange={value => console.log(value)}
            placeholderText="Search Taficasa for product receipts, branches and teams"
          />
        </Box>

        <Stack
          direction="row"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="auto"
        >
          <Box
            sx={{
              borderRadius: '0.125rem',
              height: '2.25rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <SvgWrapper
              height="1.25rem"
              width="1.25rem"
              icon={IconLeft}
              styleOverrides={{
                fill: '#ffffff',
                stroke: '#475467'
              }}
            />
          </Box>

          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              width: '0.0625rem',
              height: '2.375rem',
              borderRadius: '0.125rem',
              borderLeft: '0.0625rem solid #F2F4F7',
              borderRight: '0.0625rem solid #F2F4F7',
              margin: '0rem 1rem'
            }}
          />

          <Box
            sx={{
              borderRadius: '0.125rem',
              height: '2.25rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <SvgWrapper
              height="1.25rem"
              width="1.25rem"
              icon={Question}
              styleOverrides={{
                fill: '#fff',
                stroke: '#475467'
              }}
            />
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              width: '0.0625rem',
              height: '2.375rem',
              borderRadius: '0.125rem',
              borderLeft: '0.0625rem solid #F2F4F7',
              borderRight: '0.0625rem solid #F2F4F7',
              margin: '0rem 1rem'
            }}
          />
          <Button
            variant="outlined"
            sx={{
              color: `${theme.palette.common.btnColor}`,
              fontWeight: 600,
              lineHeight: '1.26rem',
              borderRadius: '0.5rem',
              height: '2.25rem',
              letterSpacing: '-0.01313rem',
              backgroundColor: `${theme.palette.common.white}`,
              padding: '.5rem .75rem',
              border: '1px solid #7FAAFC'
            }}
            startIcon={<User />}
          >
            Invite User
          </Button>
        </Stack>
      </Stack>
    </Toolbar>
  );
};

export default AppNavBar;
