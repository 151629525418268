import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { api } from './api/baseApi';

import authSlice from './slices/authSlice';
import registerSlice from './slices/registerSlice';
import themeSlice from './slices/themeSlice';
import appSlice from './slices/appSlice';

import { authPersistConfig, appPersistConfig } from './persist';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';

import { isProductionEnvironment } from 'utilities/environment';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: persistReducer<ReturnType<typeof authSlice.reducer>>(
      authPersistConfig,
      authSlice.reducer
    ),
    register: registerSlice.reducer,
    theme: themeSlice.reducer,
    app: persistReducer<ReturnType<typeof appSlice.reducer>>(
      appPersistConfig,
      appSlice.reducer
    )
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(api.middleware),
  devTools: !isProductionEnvironment()
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
setupListeners(store.dispatch);
