import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as UsersIcon } from 'assets/svg/users.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/svg/chevron-right.svg';
import { Button } from 'components/button';
import { SvgWrapper } from 'components/svg';
import { TeamsContext } from '../../teamsContext';

const InviteTeamMemberStep = () => {
  const { goToStep, handlePrevStep, setCreateTeamModalOpen } =
    useContext(TeamsContext);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1.25rem',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          borderBottom: '2px solid #F5F6F7',
          width: '24.5rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '2.25rem',
            width: '2.25rem',
            borderRadius: '6.25rem',
            backgroundColor: '#E5EEFF',
            padding: '0.5rem',
            boxShadow: '0px 0px 0px 4px rgba(200, 219, 255, 0.20)',
            marginBottom: '1rem'
          }}
        >
          <SvgWrapper
            icon={UsersIcon}
            width="1.25rem"
            height="1.25rem"
            styleOverrides={{
              opacity: '0.8rem'
            }}
            color="#C1D6FF"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h7Semibold" color="#344054">
            Invite to team
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            Invite team members to the Product Receipt endorsement team
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          padding: '2.25rem 1.5rem',
          backgroundColor: '#FCFCFC',
          width: '24.5rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem 1.25rem',
            backgroundColor: '#FFFFFF',
            borderRadius: '0.5rem',
            cursor: 'pointer',
            mb: '.75rem'
          }}
          onClick={() => {
            goToStep(2);
          }}
        >
          <Box
            sx={{
              padding: '0.5rem',
              width: '2.25rem',
              height: '2.25rem',
              backgroundColor: '#EFF5FF',
              borderRadius: '0.5rem'
            }}
          >
            <SvgWrapper
              icon={UsersIcon}
              width="1.25rem"
              height="1.25rem"
              color="#3E7DF8"
            />
          </Box>
          <Typography
            variant="bodyMediumSemibold"
            color="#344054"
            sx={{ marginLeft: '0.5rem' }}
          >
            Add User Manually
          </Typography>
          <Box sx={{ marginLeft: 'auto', mt: '.6rem' }}>
            <SvgWrapper
              icon={ChevronRightIcon}
              width="1.5rem"
              height="1.5rem"
              styleOverrides={{ fill: 'none', stroke: '#000000' }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem 1.25rem',
            backgroundColor: '#FFFFFF',
            borderRadius: '0.5rem',
            cursor: 'pointer'
          }}
          onClick={() => {
            goToStep(3);
          }}
        >
          <Box
            sx={{
              padding: '0.5rem',
              width: '2.25rem',
              height: '2.25rem',
              backgroundColor: '#EFF5FF',
              borderRadius: '0.5rem'
            }}
          >
            <SvgWrapper
              icon={UsersIcon}
              width="1.25rem"
              height="1.25rem"
              color="#3E7DF8"
            />
          </Box>
          <Typography
            variant="bodyMediumSemibold"
            color="#344054"
            sx={{ marginLeft: '0.5rem' }}
          >
            Bulk Upload
          </Typography>
          <Box sx={{ marginLeft: 'auto', mt: '.6rem' }}>
            <SvgWrapper
              icon={ChevronRightIcon}
              width="1.5rem"
              height="1.5rem"
              styleOverrides={{ fill: 'none', stroke: '#000000' }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderTop: '1px solid #F5F6F7',
          padding: '1.25rem 1.5rem',
          marginBottom: '0.25rem',
          width: '24.5rem'
        }}
      >
        <Button
          color="grey"
          transparent
          size="lg"
          onClick={() => {
            handlePrevStep();
          }}
          text="Back"
          width="48%"
        />
        <Button
          color="primary"
          size="lg"
          text="Invite later"
          onClick={() => {
            setCreateTeamModalOpen(false);
          }}
          width="48%"
        />
      </Box>
    </>
  );
};

export default InviteTeamMemberStep;
