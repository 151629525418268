import { createSlice } from '@reduxjs/toolkit';

export interface ThemeState {
  isDarkMode: boolean;
}

const initialState = {
  isDarkMode: false
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleDarkMode: (state: ThemeState) => {
      state.isDarkMode = !state.isDarkMode;
    }
  }
});

export const { toggleDarkMode } = themeSlice.actions;

export default themeSlice;
