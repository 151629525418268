export const nameCheck = (value: string) => {
  const hasNumber = /[0-9]/.test(value);
  const hasSymbol = /[!@#%&.,*^]/.test(value);
  let validConditions = 0;
  const numberOfMustBeValidConditions = 0;
  const conditions = [hasNumber, hasSymbol];
  conditions.forEach(condition => (condition ? validConditions++ : null));
  if (validConditions === numberOfMustBeValidConditions) {
    return true;
  }
  return false;
};
