import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as Plus } from 'assets/svg/plus.svg';
import { ReactComponent as Dot } from 'assets/svg/dot.svg';
import { Button } from 'components/button';
import { Accordion } from 'components/accordion';
import { SvgWrapper } from 'components/svg';
import SearchableContainer from 'components/search/searchableContainer';
import AddPermission from '../users/addPermission';
import { useGetGroupedRolePermissionsQuery } from 'services/api/permissionsApi';
import Loading from 'components/loader/loading';
import { RoleDetail } from '../types/rolesTypes';
import { GroupedPermission, Permission } from '../types/permissionTypes';
import {
  PermissionType,
  PermissionTypeGroup
} from 'services/enums/permissions';

interface PermissionTabProps {
  role: RoleDetail;
}

const Permissions = ({ role }: PermissionTabProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: permissions, isLoading: isLoadingPermissions } =
    useGetGroupedRolePermissionsQuery({ roleId: role.role_id });

  // transform the permissions data to the searchable container format
  const permissionData: GroupedPermission[] = permissions
    ? Object.keys(permissions).map(category => ({
        category: category as PermissionTypeGroup,
        permissions: permissions[category as PermissionTypeGroup]
      }))
    : [];

  const permissionList: PermissionType[] =
    permissionData.reduce<PermissionType[]>((acc, category) => {
      category.permissions.forEach((permission: Permission) => {
        acc.push(permission.permission_name as PermissionType);
      });
      return acc;
    }, []) ?? [];

  const renderData = (item: GroupedPermission) => {
    return (
      <Box
        sx={{
          backgroundColor: '#FCFCFD',
          borderRadius: '0.75rem',
          border: '0.094rem solid #F2F4F7',
          width: '100%',
          mb: '0.75rem'
        }}
      >
        <Accordion
          key={item.category}
          summary={
            <Stack width="25.6rem">
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                mb="0.25rem"
              >
                <SvgWrapper
                  icon={Dot}
                  width="0.375rem"
                  height="0.375rem"
                  styleOverrides={{ fill: '#3E7DF8' }}
                />
                <Typography variant="captionSmall" color="#667085">
                  {item.category} Permissions
                </Typography>
              </Stack>
            </Stack>
          }
        >
          {item.permissions.map(permissionItem => (
            <Stack
              key={permissionItem.permission_name}
              display="flex"
              direction="row"
              padding="0.75rem 0rem"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack>
                <Typography variant="bodyMediumMedium" color="#344054">
                  {permissionItem.permission_name
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}
                </Typography>
                <Typography variant="bodyMediumMedium" color="#98A2B3">
                  {permissionItem.description}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Accordion>
      </Box>
    );
  };

  if (isLoadingPermissions) {
    return <Loading loading={isLoadingPermissions} />;
  }

  return (
    <>
      <AddPermission
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        roleId={role.role_id}
        existingPermissions={permissionList}
      />
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '0.75rem 1.25rem',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: 'center',
          gap: '1rem',
          mb: '0.75rem',
          alignSelf: 'stretch',
          flexWrap: 'wrap',
          width: '100%'
        }}
      >
        <Stack direction="column">
          <Typography variant="bodyMediumSemibold" color="#475467">
            Edit Permissions
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            Permissions assigned to this role
          </Typography>
        </Stack>

        <Button
          color="grey"
          transparent
          size="sm"
          text="Add Permission"
          icon={Plus}
          iconPosition="start"
          onClick={() => setIsOpen(true)}
          disabled={role.is_taficasa_default}
        />
      </Stack>
      <Box padding="0rem 1.25rem">
        <SearchableContainer<GroupedPermission>
          searchPlaceholderText="Search here"
          data={permissionData}
          renderDataList={renderData}
          searchField={['permissions.permission_name']}
        />
      </Box>
    </>
  );
};

export default Permissions;
