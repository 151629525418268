import React, {
  useState,
  useEffect,
  createContext,
  PropsWithChildren
} from 'react';
import PickupRequestFormStep1 from './steps/pickupRequestFormStep1';
import PickupRequestFormStep2 from './steps/pickupRequestFormStep2';
import { useCreatePickupRequestMutation } from 'services/api/productReceiptApi';
import ShowInfo from 'components/info/showInfo';
import { useParams } from 'react-router-dom';

type PickupRequestData = {
  agent_photo: File | null;
  name: string;
  email: string;
  phone_number: string;
  vehicle_photo: File | null;
  vehicle_make: string;
  vehicle_model: string;
  license: string;
  identifying_markers: string;
};

const initialValues: PickupRequestData = {
  agent_photo: null,
  name: '',
  phone_number: '',
  email: '',
  vehicle_photo: null,
  vehicle_make: '',
  vehicle_model: '',
  license: '',
  identifying_markers: ''
};

type PickupRequestContextTypes = {
  data: PickupRequestData;
  setData: React.Dispatch<React.SetStateAction<PickupRequestData>>;
  currentStep: number;
  steps: { element: JSX.Element }[];
  handleNextStep: (newData?: Partial<PickupRequestData>) => void;
  handlePrevStep: () => void;
  goToStep: (index: number) => void;
  setPickupRequestModalOpen: PickupRequestContextWrapperProps['setPickupRequestModalOpen'];
  uploadedFile: File | null;
  setUploadedFile: React.Dispatch<React.SetStateAction<File | null>>;
  isLoading: boolean;
};

const PickupRequestContextDefaults: PickupRequestContextTypes = {
  data: initialValues,
  setData: () => undefined,
  currentStep: 0,
  steps: [],
  handleNextStep: () => undefined,
  handlePrevStep: () => undefined,
  goToStep: () => undefined,
  setPickupRequestModalOpen: () => undefined,
  uploadedFile: null,
  setUploadedFile: () => undefined,
  isLoading: false
};

type PickupRequestContextWrapperProps = {
  setPickupRequestModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PickupRequestContext = createContext<PickupRequestContextTypes>(
  PickupRequestContextDefaults
);

const PickupRequestContextWrapper = ({
  children,
  setPickupRequestModalOpen
}: PropsWithChildren<PickupRequestContextWrapperProps>) => {
  const [data, setData] = useState<PickupRequestData>(initialValues);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [uploadedFile, setUploadedFile] = useState<null | File>(null);
  const [createPickupRequest, { isLoading }] = useCreatePickupRequestMutation();
  const { productReceiptId } = useParams();

  const steps = [
    { element: <PickupRequestFormStep1 /> },
    { element: <PickupRequestFormStep2 /> }
  ];

  const handleNextStep = (newData?: Partial<PickupRequestData>) => {
    if (newData) {
      setData(prev => ({ ...prev, ...newData }));
    }
    setCurrentStep(i => {
      if (i >= steps.length - 1) return i;
      return i + 1;
    });
  };

  const pickupRequest = (data: PickupRequestData) => {
    if (productReceiptId !== undefined) {
      return createPickupRequest({
        productReceiptId: productReceiptId,
        data: {
          firstname: data.name,
          lastname: data.name,
          email: data.email,
          phone_number: data.phone_number,
          profile_picture: data.agent_photo,
          vehicle_make: data.vehicle_make,
          vehicle_model: data.vehicle_model,
          vehicle_plate_number: data.license,
          vehicle_color: 'Black',
          vehicle_photo: data.vehicle_photo
        }
      })
        .unwrap()
        .then(() => {
          ShowInfo({
            message: 'Request Sent',
            subText: 'Pickup request created successfully',
            type: 'success',
            componentType: 'toast',
            hideProgressBar: false
          });
          setPickupRequestModalOpen(false);
        })
        .catch(() => {
          ShowInfo({
            message: 'Request Failed',
            type: 'error',
            componentType: 'toast',
            hideProgressBar: false
          });
          setPickupRequestModalOpen(false);
        });
    }
  };

  useEffect(() => {
    if (
      data.agent_photo &&
      data.name &&
      data.phone_number &&
      data.vehicle_photo &&
      data.vehicle_make &&
      data.vehicle_model &&
      data.license
    ) {
      pickupRequest(data);
    }
  }, [data]);

  const handlePrevStep = () => {
    setCurrentStep(i => {
      return i - 1;
    });
  };

  const goToStep = (index: number) => {
    setCurrentStep(index);
  };

  const values: PickupRequestContextTypes = {
    data,
    setData,
    currentStep,
    steps,
    handleNextStep,
    handlePrevStep,
    goToStep,
    setPickupRequestModalOpen,
    uploadedFile,
    setUploadedFile,
    isLoading
  };

  return (
    <PickupRequestContext.Provider value={values}>
      {children}
    </PickupRequestContext.Provider>
  );
};

export default PickupRequestContextWrapper;
