import React, { useState } from 'react';
import { FormControl, FormLabel } from '@mui/material';

import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';
import type { FieldValues } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { ReactComponent as Calender } from 'assets/svg/calender.svg';
import { SvgWrapper } from 'components/svg';
import type { FormDateInputProps } from 'components/form/common/formTypes';
import { fontFamily2, theme } from 'themes/theme';
import { formattedDate } from 'utilities/helperFunc/formatter';

export default function FormDateInput<FormValueT extends FieldValues>(
  props: FormDateInputProps<FormValueT>
) {
  const {
    label,
    name,
    control,
    disablePast,
    disableFuture,
    format = 'DD/MM/YYYY',
    onChange: onChangeCallBack
  } = props;
  const [toggleDate, setToggleDate] = useState(false);

  return (
    <FormControl
      fullWidth
      sx={{
        paddingBottom: { xs: 3 },
        '& .css-13nvzlm-MuiFormHelperText-root': {
          fontSize: '0.813rem',
          ml: 0
        }
      }}
    >
      <FormLabel
        htmlFor={name}
        sx={{
          fontFamily: fontFamily2,
          fontSize: '0.75rem',
          fontWeight: 400,
          lineHeight: '1.05rem',
          letterSpacing: '-0.015rem',
          color: `${theme.palette.common.labelColor}`,
          mb: '0.25rem'
        }}
      >
        {label}
      </FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error, isTouched, invalid } }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={['year', 'month', 'day']}
                onChange={date => {
                  if (dayjs.isDayjs(date)) {
                    field.onChange(formattedDate(date, format));
                  } else {
                    field.onChange(date);
                  }
                  if (onChangeCallBack) {
                    onChangeCallBack();
                  }
                }}
                format={format}
                value={dayjs(field.value)}
                closeOnSelect
                disablePast={disablePast}
                disableFuture={disableFuture}
                onClose={() => setToggleDate(false)}
                open={toggleDate}
                slotProps={{
                  textField: {
                    onBlur: field.onBlur,
                    id: name,
                    error: invalid && isTouched,
                    helperText: error?.message,
                    sx: {
                      '& .MuiOutlinedInput-root': {
                        height: '3.2rem',
                        borderRadius: '0.5rem',
                        border: '0.094rem solid #EAECF0',
                        background: '#FEFFFF',
                        boxShadow:
                          '0rem .25rem .25rem 0rem rgba(220, 220, 220, 0.02)',
                        '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent'
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: `${theme.palette.common.btnColor}`,
                          boxShadow: `${theme.palette.common.boxShadow}`
                        }
                      }
                    },
                    FormHelperTextProps: {
                      sx: {
                        ml: '0rem',
                        mr: '0rem',
                        fontFamily: fontFamily2,
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        lineHeight: '1.188rem',
                        letterSpacing: '-0.025em',
                        width: '100%',
                        textAlign: 'left',
                        color: `${theme.palette.common.error}`
                      }
                    },
                    InputProps: {
                      onClick: () => {
                        setToggleDate(true);
                      }
                    }
                  },
                  inputAdornment: {
                    position: 'start',
                    children: (
                      <SvgWrapper
                        icon={Calender}
                        width="1.25rem"
                        height="1.25rem"
                        styleOverrides={{
                          fill: 'none',
                          stroke: '#98A2B3'
                        }}
                      />
                    )
                  },
                  desktopPaper: {
                    sx: {
                      borderRadius: '1rem',
                      width: '21.6rem'
                    }
                  }
                }}
              />
            </LocalizationProvider>
          );
        }}
      />
    </FormControl>
  );
}
