import React from 'react';
import Typography from '@mui/material/Typography';
import { Tab as MuiTab, TabProps as MuiTabProps } from '@mui/material';
import { theme } from 'themes/theme';

interface TabProps extends MuiTabProps {
  labelText: string;
  index: number;
}

const Tab: React.FC<TabProps> = ({ labelText, index, ...rest }) => {
  return (
    <MuiTab
      {...rest}
      label={
        <Typography
          variant="bodyMediumMedium"
          sx={{ color: theme.palette.common.labelColor }}
        >
          {labelText}
        </Typography>
      }
      {...a11yProps(index)}
    />
  );

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`
    };
  }
};

export default Tab;
