import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import { Chip } from 'components/chip';
import { SvgWrapper } from 'components/svg';
import AvatarGroup from 'components/common/avatarGroup';
import LinearProgressBar from 'components/progress/linear';
import { ReactComponent as WorkflowIcon } from 'assets/svg/workflow.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/svg/chevron-right.svg';

const WorkflowCard = () => {
  const navigate = useNavigate();

  return (
    <Box
      width="inherit"
      sx={{
        padding: '1rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#FFF',
        gap: '1rem'
      }}
    >
      <Stack
        sx={{
          width: '35%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '0.5rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0.5rem',
            backgroundColor: '#F5F6F7',
            borderRadius: '0.5rem'
          }}
        >
          <SvgWrapper
            icon={WorkflowIcon}
            width="1rem"
            height="1rem"
            color="#98A2B3"
          />
        </Box>
        <Stack direction="column" alignItems="flex-start">
          <Typography variant="bodyMediumMedium">
            Endorsement to Jeremiah Akoh
          </Typography>
          <Typography variant="bodyMediumMedium" color="#98A2B3">
            Updated Jan 28 at 10:45 AM
          </Typography>
        </Stack>
      </Stack>
      <Stack
        sx={{
          width: '20%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          sx={{ width: '100%' }}
        >
          <LinearProgressBar
            value={80}
            color="#3E7DF8"
            borderRadius={5}
            height={4}
          />
          <Typography variant="bodyMediumMedium" color="#475467">
            80%
          </Typography>
        </Stack>
        <Stack width="100%" direction="column" alignItems="flex-start">
          <Typography variant="bodyMediumMedium" color="#475467">
            10 steps
          </Typography>
        </Stack>
      </Stack>
      <Stack
        sx={{
          width: '20%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Chip size="xs" label="Approval pending" color="error" />
      </Stack>
      <Stack
        sx={{
          width: '20%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <AvatarGroup
          srcs={['NY', 'YS', 'SC', 'ND', 'JD', 'SF', 'ND', 'LD']}
          maxShown={6}
          size="md"
        />
      </Stack>
      <Stack
        sx={{
          width: '5%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            height: '1.5rem',
            width: '1.5rem',
            borderRadius: '50%',
            backgroundColor: '#FCFCFD',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onClick={() => navigate('/workflows')}
        >
          <SvgWrapper icon={ChevronRightIcon} height="1rem" width="1rem" />
        </Box>
      </Stack>
    </Box>
  );
};

export default WorkflowCard;
