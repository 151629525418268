import React, { useState, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as Plus } from 'assets/svg/plus.svg';
import { ReactComponent as Dot } from 'assets/svg/dot.svg';
import { Button } from 'components/button';
import { SvgWrapper } from 'components/svg';
import SearchableContainer from 'components/search/searchableContainer';
import { ReactComponent as Edit } from 'assets/svg/edit.svg';
import { ReactComponent as TrashSolid } from 'assets/svg/trashSolidGrey.svg';
import { FormSelect, FormSelectOption } from 'components/form';
import { useForm } from 'react-hook-form';
import RemoveRoleModal from './removeRoleModal';
import { useGetRolesQuery } from 'services/api/permissionsApi';
import { Role } from '../types/permissionTypes';

type FormValues = {
  roles: string[];
};

const Roles = ({ roles }: { roles: Role[] }) => {
  const { data: organizationRoles } = useGetRolesQuery({});
  const [showAddRole, setShowAddRole] = useState(false);
  const [showRemoveRoleModal, setShowRemoveRoleModal] = useState(false);
  const { control } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      roles: []
    }
  });

  const roleOptions: FormSelectOption[] = useMemo(() => {
    if (!organizationRoles) return [];

    return organizationRoles.map((role: Role) => ({
      title: role.name,
      value: role.description
    }));
  }, [organizationRoles]);

  const renderData = (item: Role) => {
    return (
      <Stack
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        sx={{
          padding: '1rem 1.5rem',
          borderRadius: '0.75rem',
          border: '0.063rem solid #F2F4F7',
          background: '#FCFCFD',
          mb: '1rem'
        }}
      >
        <Stack>
          <Stack direction="row" spacing={1} alignItems="center" mb="0.25rem">
            <SvgWrapper
              icon={Dot}
              width="0.375rem"
              height="0.375rem"
              styleOverrides={{ fill: '#3E7DF8' }}
            />
            <Typography variant="captionSmall" color="#667085">
              {item.name}
            </Typography>
          </Stack>
          <Typography variant="bodyMediumMedium" color="#667085">
            {item.description}
          </Typography>
        </Stack>
        <Stack display="flex" direction="row" alignItems="center" spacing={1}>
          <Box padding="0.5rem">
            <SvgWrapper
              icon={Edit}
              width="1rem"
              height="1rem"
              styleOverrides={{ fill: '#98A2B3' }}
            />
          </Box>
          <Box
            sx={{ cursor: 'pointer', padding: '0.5rem' }}
            onClick={() => {
              setShowRemoveRoleModal(true);
            }}
          >
            <SvgWrapper
              icon={TrashSolid}
              width="1rem"
              height="1rem"
              styleOverrides={{ fill: '#98A2B3' }}
            />
          </Box>
        </Stack>
      </Stack>
    );
  };
  return (
    <Box>
      <RemoveRoleModal
        open={showRemoveRoleModal}
        onClose={() => {
          setShowRemoveRoleModal(false);
        }}
      />
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '0.75rem 1.25rem',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: 'center',
          gap: '1rem',
          mb: '0.75rem',
          alignSelf: 'stretch',
          flexWrap: 'wrap',
          width: '100%',
          height: 'inherit'
        }}
      >
        <Stack direction="column">
          <Typography variant="bodyMediumSemibold" color="#475467">
            Roles
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            See roles assigned to this user
          </Typography>
        </Stack>

        <Button
          color="grey"
          transparent
          size="sm"
          text="Add Role"
          icon={Plus}
          iconPosition="start"
          onClick={() => setShowAddRole(true)}
        />
      </Stack>
      {showAddRole ? (
        <Box padding="0rem 1.25rem">
          <FormSelect<FormValues>
            options={roleOptions}
            control={control}
            label="ADD NEW ROLE"
            name="roles"
            multiselect
            renderSelected={(value: string) => (
              <Box
                sx={{
                  padding: '0.25rem 0.5rem',
                  backgroundColor: '#F5F6F7',
                  display: 'inline-block',
                  borderRadius: '0.5rem',
                  marginRight: '0.5rem'
                }}
              >
                <Typography color="#475467" variant="bodyMediumMedium">
                  {value}
                </Typography>
              </Box>
            )}
          />
        </Box>
      ) : (
        <Box padding="0rem 1.25rem">
          <SearchableContainer<Role>
            searchPlaceholderText="Search here"
            data={roles}
            renderDataList={renderData}
            searchField={['title']}
          />
        </Box>
      )}

      <Stack
        sx={{
          display: 'flex',
          padding: '0.75rem 1.5rem',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          gap: '1rem',
          alignSelf: 'stretch',
          borderRadius: '0rem 0rem 0rem 0.75rem',
          borderTop: '0.063rem solid  #F5F6F7',
          background: '#FCFCFD',
          width: '100%',
          position: 'absolute',
          bottom: 0
        }}
      >
        <Stack direction="row" spacing={1}>
          <Button
            color="grey"
            transparent
            size="md"
            text="Cancel"
            onClick={() => setShowAddRole(false)}
          />
          {showAddRole ? (
            <Button
              color="primary"
              size="md"
              text="Add Role"
              icon={Plus}
              iconPosition="start"
              disabled
            />
          ) : (
            <Button color="primary" size="md" text="Save changes" disabled />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default Roles;
