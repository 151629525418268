import React from 'react';
import { Stack, Box, Typography } from '@mui/material';

import { ReactComponent as AutomationIcon } from 'assets/svg/automation.svg';
import { SvgWrapper } from 'components/svg';

import type {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps
} from 'react-beautiful-dnd';

type WorkflowBuilderStepCardProps = {
  title: string;
  description: string;
  draggableProps: DraggableProvidedDraggableProps;
  forwardedRef: React.Ref<HTMLElement>;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
};

const WorkflowBuilderStepCard: React.FC<WorkflowBuilderStepCardProps> = ({
  title,
  description,
  draggableProps,
  forwardedRef,
  dragHandleProps
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        margin: '1.5rem 0.75rem',
        cursor: 'pointer'
      }}
      {...draggableProps}
      {...dragHandleProps}
      ref={forwardedRef}
    >
      <Box
        sx={{
          display: 'flex',
          padding: '0.5rem',
          backgroundColor: '#F5F6F7',
          borderRadius: '0.5rem',
          border: '0.0625rem solid #F2F4F7',
          marginRight: '0.5rem'
        }}
      >
        <SvgWrapper
          icon={AutomationIcon}
          width="1.25rem"
          height="1.25rem"
          color="#98A2B3"
        />
      </Box>
      <Stack sx={{ maxWidth: '70%' }}>
        <Typography
          noWrap
          sx={{ width: '100%' }}
          variant="bodyMediumSemibold"
          color="#475467"
        >
          {title}
        </Typography>
        <Typography
          noWrap
          sx={{ width: '100%' }}
          variant="bodyMediumRegular"
          color="#98A2B3"
        >
          {description}
        </Typography>
      </Stack>
    </Box>
  );
};

export default WorkflowBuilderStepCard;
