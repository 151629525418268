import React from 'react';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import _ from 'lodash';

export default function SettingsBreadcrumb() {
  const location = useLocation();
  const crumbs = location.pathname.split('/').filter(crumb => crumb !== '');
  const crumbName = (element: string) => {
    switch (element) {
      case 'settings':
        return 'Dashboard';
      default:
        return _.capitalize(element.replace(/-/g, ' '));
    }
  };

  return (
    <Breadcrumbs maxItems={5} separator="/" aria-label="breadcrumb">
      {crumbs.map((element, index) => {
        const isLast = index === crumbs.length - 1;
        const routeTo =
          index === 0 ? '/' : `/${crumbs.slice(0, index + 1).join('/')}`;
        if (element.length > 20) return null;
        return isLast ? (
          <Typography key={index} variant="bodyMediumMedium" color="#3E7DF8">
            {crumbName(element)}
          </Typography>
        ) : (
          <Link
            style={{
              color: '#667085',
              textDecoration: 'none'
            }}
            key={index}
            to={routeTo}
          >
            <Typography variant="bodyMediumMedium" color="#667085">
              {crumbName(element)}
            </Typography>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}
