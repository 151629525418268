import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';
import type { FormFileInputProps } from 'components/form/common/formTypes';
import { Button } from '@mui/material';
import type { FieldValues } from 'react-hook-form';

export default function FormFileInput<FormValueT extends FieldValues>(
  props: FormFileInputProps<FormValueT>
) {
  const {
    name,
    label,
    control,
    setFile,
    value,
    setPreview,
    supportedFiles,
    startIcon,
    fileInputButtonStyle,
    ...rest
  } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <input
            type="file"
            accept={supportedFiles}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.files && e.target.files[0]) {
                setFile !== undefined && setFile(e.target.files[0]);
                field.onChange(e.target.files[0]);
                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onload = () => {
                  setPreview !== undefined && setPreview(reader.result);
                };
              }
            }}
            style={{ display: 'none' }}
            ref={inputRef}
          />
          <Button
            value={value}
            sx={fileInputButtonStyle}
            onClick={handleButtonClick}
            {...rest}
            type="button"
            startIcon={startIcon}
          >
            {label}
          </Button>
        </>
      )}
    />
  );
}
