import { Box, Typography } from '@mui/material';
import SearchableContainer from 'components/search/searchableContainer';
import React from 'react';

interface Lender {
  id: string;
  name: string;
  product: string;
  value: string;
}

const lenders = [
  {
    id: '#1267483',
    name: 'Janco Group',
    product: 'Cement Bags',
    value: 'N200,000,000'
  },
  {
    id: '#1267483',
    name: 'Janco Group',
    product: 'Cement Bags',
    value: 'N200,000,000'
  }
];

const renderData = (item: Lender) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      width="100%"
      alignItems="center"
      padding="1.5rem"
      borderBottom="0.063rem solid #F5F6F7"
    >
      <Box display="flex" width="20%" justifyContent="flex-start">
        <Typography variant="bodyMediumMedium" color="#475467">
          {item.id}
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        width="40%"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="bodyMediumMedium" color="#475467">
          {item.product}
        </Typography>
        <Typography variant="bodyMediumMedium" color="#475467">
          {item.name}
        </Typography>
      </Box>

      <Box
        display="flex"
        width="40%%"
        justifyContent="center"
        alignItems="center"
        flexGrow="1"
      >
        <Typography variant="bodyMediumMedium" color="#475467">
          {item.value}
        </Typography>
      </Box>
    </Box>
  );
};

const SingleReceipts = () => {
  return (
    <SearchableContainer<Lender>
      searchPlaceholderText="Search here"
      data={lenders}
      renderDataList={renderData}
      searchField={['title']}
      containerStyles={{
        borderRadius: '0.75rem',
        border: '0.063rem solid #F2F4F7',
        background: 'rgba(102, 112, 133, 0.02)'
      }}
    />
  );
};

export default SingleReceipts;
