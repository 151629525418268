import React from 'react';
import { Typography, Stack, Avatar } from '@mui/material';

import { Table } from 'components/table';

import { createColumnHelper } from '@tanstack/react-table';
import type { ColumnDef } from '@tanstack/react-table';
import { Distributor } from 'pages/distributorManagement/types';

type TableProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const columnHelper = createColumnHelper<Distributor>();
const columns = [
  columnHelper.accessor('distributor', {
    header: 'distributor',
    cell: info => (
      <Stack display="flex" flexDirection="row">
        <Avatar
          src={`${info.getValue().logo}`}
          sx={{
            width: '2rem',
            height: '2rem',
            mr: '0.75rem',
            justifyContent: 'center',
            borderRadius: '12.5rem'
          }}
        />
        <Stack display="flex" flexDirection="column" justifyContent="center">
          <Typography
            variant="bodyMediumMedium"
            color="#475467"
            sx={{ whiteSpace: 'nowrap' }}
          >
            {info.getValue().name}
          </Typography>
          <Typography
            variant="bodyMediumRegular"
            color="#667085"
            sx={{ whiteSpace: 'nowrap' }}
          >
            {info.getValue().public_id}
          </Typography>
        </Stack>
      </Stack>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('registration_code', {
    header: 'registration code',
    cell: info => (
      <Typography
        variant="bodyMediumMedium"
        color="#667085"
        sx={{ whiteSpace: 'nowrap' }}
      >
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('address', {
    header: 'address',
    cell: info => (
      <Typography
        variant="bodyMediumMedium"
        color="#667085"
        sx={{ whiteSpace: 'nowrap' }}
      >
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  })
] as ColumnDef<Distributor>[];
// Cast is a workaround due to issues with tansatck table
// See https://github.com/TanStack/table/issues/4302

const DistributorsRegisteredToMerchantTable = ({ setIsOpen }: TableProps) => {
  const distributors: Distributor[] = [];

  const handleRowClick = () => {
    setIsOpen(true);
  };

  return (
    <Table<Distributor>
      hover
      columns={columns}
      data={distributors}
      onClickRow={handleRowClick}
      optionsButton
      enableFilter={false}
      filterColumns={['name']}
      searchPlaceholderText="Search for Distributors"
    />
  );
};

export default DistributorsRegisteredToMerchantTable;
