import React, { useState } from 'react';
import { Box, Typography, Stack } from '@mui/material';

import WorkflowsViewerStepsGroup from './workflowViewerStepsGroup';

import { shadowsTheme } from 'themes/shadows';
import { SvgWrapper } from 'components/svg';
import { ReactComponent as Dot } from 'assets/svg/dot.svg';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/svg/minus.svg';
import { ReactComponent as FourRoundCornersIcon } from 'assets/svg/four-round-corners.svg';
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef
} from 'react-zoom-pan-pinch';
import _ from 'lodash';

import { Chip } from 'components/chip';
import { WorkflowApiData } from 'services/types/workflow';
import { WorkflowTargetObject } from 'services/enums/workflows';

type WorkflowsViewerProps = {
  workflow: WorkflowApiData;
  workflowTargetObject: WorkflowTargetObject;
  workflowTargetObjectId: string;
  invalidateTag: string;
  invalidateTagId: string | number;
};

const WorkflowsViewer = ({
  workflow,
  workflowTargetObject,
  workflowTargetObjectId,
  invalidateTag,
  invalidateTagId
}: WorkflowsViewerProps) => {
  const maxZoom = 1;
  const minZoom = 0.25;

  const [currentZoom, setCurrentZoom] = useState<number>(1);

  const handleZoomed = (ref: ReactZoomPanPinchRef) => {
    setCurrentZoom(ref.state.scale);
  };

  return (
    <TransformWrapper
      initialScale={1}
      minScale={minZoom}
      maxScale={maxZoom}
      minPositionX={0}
      minPositionY={0}
      onZoomStop={handleZoomed}
      onTransformed={handleZoomed}
      wheel={{ disabled: true, excluded: ['input'] }}
      doubleClick={{ disabled: true }}
      panning={{ disabled: true, excluded: ['input'] }}
      pinch={{ disabled: true, excluded: ['input'] }}
    >
      {({ setTransform, resetTransform }) => (
        <Box
          sx={{
            marginY: '3rem',
            height: 'inherit',
            overflow: 'hidden',
            width: '100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '0.75rem',
              border: '1px solid #F2F4F7',
              opacity: 0.8,
              backgroundColor: '#FFF',
              boxShadow: shadowsTheme.buttonShadow,
              padding: '0.75rem',
              width: '20%',
              position: 'absolute',
              bottom: 0,
              left: 0,
              zIndex: 1
            }}
          >
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() =>
                setTransform(0, 0, Math.max(minZoom, currentZoom - 0.25), 0)
              }
            >
              <SvgWrapper
                icon={MinusIcon}
                width="1.25rem"
                height="1.25rem"
                styleOverrides={{
                  stroke: '#98A2B3'
                }}
              />
            </Box>
            <Typography
              variant="bodyMediumSemibold"
              color="#475467"
              sx={{ marginX: '1rem' }}
            >
              {_.round(currentZoom * 100)}%
            </Typography>
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() =>
                setTransform(0, 0, Math.min(maxZoom, currentZoom + 0.25), 0)
              }
            >
              <SvgWrapper
                icon={PlusIcon}
                width="1.25rem"
                height="1.25rem"
                styleOverrides={{
                  stroke: '#98A2B3',
                  marginRight: '1.75rem'
                }}
              />
            </Box>
            <Box sx={{ cursor: 'pointer' }} onClick={() => resetTransform(0)}>
              <SvgWrapper
                icon={FourRoundCornersIcon}
                width="1.25rem"
                height="1.25rem"
                styleOverrides={{
                  stroke: '#98A2B3'
                }}
              />
            </Box>
          </Box>
          <TransformComponent
            wrapperStyle={{
              width: 'auto',
              height: 'inherit',
              position: 'absolute',
              overflowY: 'auto',
              top: 0,
              left: 0,
              right: 0,
              margin: 'auto',
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
              transition: 'transform 0.2s ease-in-out'
            }}
            contentStyle={{
              width: '100%',
              height: 'inherit',
              transform: 'none',
              transition: 'transform 0.2s ease-in-out'
            }}
          >
            <Box
              sx={{
                width: '100%',
                overflowY: 'auto',
                msOverflowStyle: 'none',
                transition: 'transform 0.2s ease-in-out',
                scrollbarWidth: 'none',
                '&::WebkitScrollbar': {
                  display: 'none'
                }
              }}
            >
              <WorkflowsViewerStepsGroup
                workflow={workflow}
                workflowTargetObject={workflowTargetObject}
                workflowTargetObjectId={workflowTargetObjectId}
                invalidateTag={invalidateTag}
                invalidateTagId={invalidateTagId}
              />
            </Box>
          </TransformComponent>
          <Box
            sx={{
              width: 'auto',
              borderRadius: '0.75rem',
              border: '.0625rem solid #F2F4F7',
              backgroundColor: '#FFF',
              boxShadow: shadowsTheme.buttonShadow,
              padding: '.5rem .75rem',
              position: 'absolute',
              bottom: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1
            }}
          >
            <Stack
              gap="1rem"
              display="flex"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Box
                gap=".2rem"
                width="auto"
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <SvgWrapper
                  icon={Dot}
                  width="0.375rem"
                  height="0.375rem"
                  styleOverrides={{ fill: '#4649E3' }}
                />

                <Typography variant="bodyMediumSemibold" color="#667085">
                  Comments
                </Typography>
              </Box>

              <Chip label="20 Comments" size="sm" />
            </Stack>
          </Box>
        </Box>
      )}
    </TransformWrapper>
  );
};

export default WorkflowsViewer;
