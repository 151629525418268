import { nameCheck } from 'utilities/helperFunc/nameCheck';
import { passwordStrength } from 'utilities/helperFunc/passwordStrength';
import * as Yup from 'yup';

export const memberAuthValidator = Yup.object({
  firstname: Yup.string()
    .required('First name is a required field')
    .min(2, 'First name must be at least 2 characters')
    .test(
      'isValidPass',
      'First name should not contain any number or special character',
      (value: string) => nameCheck(value)
    ),
  lastname: Yup.string()
    .required('Last name is a required field')
    .min(2, 'Last name must be at least 2 characters')
    .test(
      'isValidPass',
      'Last name should not contain any number or special character',
      (value: string) => nameCheck(value)
    ),
  password: Yup.string()
    .required('Password is a required field')
    .min(10, 'Password must be at least 10 characters')
    .test(
      'isValidPass',
      ' Password should contain at least: 1 uppercase, 1 lowercase, 1 number and 1 special character',
      (value: string) => {
        const { isValid } = passwordStrength(value);
        return isValid;
      }
    )
});

export const logInAuthValidator = Yup.object({
  email: Yup.string()
    .required('Email is a required field')
    .email('Email must be a valid email'),
  password: Yup.string().required('Password is a required field'),
  remember: Yup.bool()
});

export const emailValidator = Yup.object({
  email: Yup.string()
    .required('Email is a required field')
    .email('Email must be a valid email')
});

export const orgAuthValidator = Yup.object({
  firstname: Yup.string()
    .required('First name is a required field')
    .test(
      'isValidPass',
      'First name should not contain any number or special character',
      (value: string) => nameCheck(value)
    ),
  lastname: Yup.string()
    .required('Last name is a required field')
    .test(
      'isValidPass',
      'First name should not contain any number or special character',
      (value: string) => nameCheck(value)
    ),
  email: Yup.string()
    .required('Email is a required field')
    .email('Email must be a valid email'),
  password: Yup.string()
    .required('Password is a required field')
    .min(10, 'Password must be at least 10 characters')
    .test(
      'isValidPass',
      ' Password should contain at least: 1 uppercase, 1 lowercase, 1 number and 1 special character',
      (value: string) => {
        const { isValid } = passwordStrength(value);
        return isValid;
      }
    )
});

export const passwordValidator = Yup.object({
  password: Yup.string()
    .required('Password is required')
    .min(10, 'Password must be at least 10 characters')
    .test(
      'isValidPass',
      ' Password should contain at least: 1 uppercase, 1 lowercase, 1 number and 1 special character',
      (value: string) => {
        const { isValid } = passwordStrength(value);
        return isValid;
      }
    )
});
