import React, { useContext } from 'react';
import construction from 'assets/svg/construction.svg';
import barrel from 'assets/svg/barrel.svg';
import consumables from 'assets/svg/consumables.svg';
import truck from 'assets/svg/truck.svg';
import { Button } from 'components/button';
import { theme } from 'themes/theme';
import { orgStepFormValidator2 } from 'pages/onboarding/validators/onboardingValidators';
import { useForm, Resolver, SubmitHandler } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { OnboardingContext } from '../context/onboardingContext';
import { FormCheckGroup, FormInput } from 'components/form';

type FormValues = {
  product: string[];
  otherProduct: string;
};

const ProductInformation = () => {
  const { handleNextStep, handlePrevStep, data } =
    useContext(OnboardingContext);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      product: data.product,
      otherProduct: data.otherProduct
    },
    resolver: yupResolver(
      orgStepFormValidator2
    ) as unknown as Resolver<FormValues>
  });

  const watchProduct = watch('product', []);

  const onSubmit: SubmitHandler<FormValues> = data => {
    const updatedProduct = data.product.map(item =>
      item === 'other' && data.otherProduct ? data.otherProduct : item
    );
    return handleNextStep({
      product: [...updatedProduct]
    });
  };

  return (
    <Box sx={{ width: { lg: '29.25rem' } }}>
      <Box width="inherit" sx={{ mb: '2rem', textAlign: 'center' }}>
        <Typography
          variant="h5"
          sx={{
            color: `${theme.palette.common.heading}`
          }}
        >
          Product Information
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: `${theme.palette.common.labelColor}`,
            fontWeight: 400
          }}
        >
          What kind of goods do you deal in. Check all that apply
        </Typography>
      </Box>

      <Box width="inherit">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormCheckGroup<FormValues>
            control={control}
            name="product"
            label=""
            setValue={(data: string[]) => setValue('product', data)}
            getValues={() => getValues('product')}
            checkData={checkData}
          />

          {watchProduct.includes('other') && (
            <FormInput<FormValues>
              control={control}
              name="otherProduct"
              label={'KINDLY ADD PRODUCT INFORMATION NOT MENTIONED ABOVE'}
            />
          )}
          <Button
            submit
            color="primary"
            size="lg"
            disabled={!isValid}
            text="Next"
            width="100%"
            styleOverrides={{ marginBottom: '0.25rem' }}
          />
          <Button
            color="grey"
            outline
            size="lg"
            onClick={() => handlePrevStep()}
            text="Back"
            width="100%"
          />
        </form>
      </Box>
    </Box>
  );
};

export default ProductInformation;

const checkData = [
  {
    image: construction,
    heading: 'Construction',
    value: 'construction',
    subHeading: 'Essential materials for building projects e.g cement'
  },
  {
    image: consumables,
    heading: 'Consumables',
    value: 'consumables',
    subHeading: 'Everyday essentials and perishable goods e.g beverages'
  },
  {
    image: barrel,
    heading: 'Petroleum products',
    value: 'distributor',
    subHeading: 'Fuels, lubricants, and related products'
  },
  {
    image: truck,
    heading: 'Other',
    value: 'other',
    subHeading: 'Receive and endorse Product receipts from merchants'
  }
];
