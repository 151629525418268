import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as FormIcon } from 'assets/svg/form.svg';
import { Button } from 'components/button';
import { Drawer } from 'components/drawer';
import {
  FormAutocomplete,
  FormDateInput,
  FormInput,
  FormSelect
} from 'components/form';
import ShowInfo from 'components/info/showInfo';
import { SvgWrapper } from 'components/svg';
import React, { useMemo } from 'react';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { createProductReceiptValidator } from './validator/createProductReceiptValidator';
import { useCreateProductReceiptMutation } from 'services/api/productReceiptApi';
import { useGetProductsQuery } from 'services/api/productApi';

import type { FormSelectOption } from 'components/form';
import { useGetDiscoverableProfilesQuery } from 'services/api/supplyChainApi';

interface DrawerProps {
  open: boolean;
  onClose: () => void;
}

type FormValues = {
  customer_name: string;
  bank: string;
  product: string;
  quantity: string;
  amount: string;
  expiry_date: string;
};

const CreateProductReceipt: React.FC<DrawerProps> = ({ open, onClose }) => {
  const [createProductReceipt, { isLoading }] =
    useCreateProductReceiptMutation();
  const { data: productData } = useGetProductsQuery({});
  const { data: bankData } = useGetDiscoverableProfilesQuery({
    params: { organization_type: 'lender' }
  });
  const { data: customerData } = useGetDiscoverableProfilesQuery({
    params: { organization_type: 'distributor,end_user' }
  });

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      customer_name: '',
      bank: '',
      product: '',
      quantity: '',
      amount: '',
      expiry_date: ''
    },
    resolver: yupResolver(
      createProductReceiptValidator
    ) as unknown as Resolver<FormValues>
  });

  const bankOptions =
    (bankData &&
      Object.values(bankData).map(bank => ({
        title: bank.name,
        value: bank.organization_id
      }))) ||
    [];

  const customerOptions =
    (customerData &&
      Object.values(customerData).map(customer => ({
        title: customer.name,
        value: customer.organization_id
      }))) ||
    [];

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const customer = customerOptions.find(
      option => option.title === data.customer_name
    )?.value;
    const bank = bankOptions.find(option => option.title === data.bank)?.value;
    const price = Number(data.amount.replace(/[^0-9]/g, ''));
    const quantity = Number(data.quantity.replace(/,/g, ''));

    createProductReceipt({
      data: {
        bank: bank || data.bank,
        product: data.product,
        customer: customer || data.customer_name,
        document_files: [],
        document_metadata: [],
        total_quantity: quantity,
        quoted_price: price,
        quoted_price_currency: 'ngn',
        quoted_price_expiry: data.expiry_date
      }
    })
      .unwrap()
      .then(() => {
        ShowInfo({
          message: 'Product Receipt Created',
          subText: 'PRC has been added to your vault',
          type: 'success',
          componentType: 'toast',
          hideProgressBar: false
        });
        onClose();
      })
      .catch(() => {
        ShowInfo({
          message: 'Product Receipt Created',
          subText: 'PRC has not been created!',
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
        onClose();
      });
  };

  const renderDrawerHeader = () => {
    return (
      <Stack
        display="flex"
        flexDirection="column"
        padding="1.25rem 1.25rem 1.25rem 0rem"
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '2.25rem',
            width: '2.25rem',
            borderRadius: '6.25rem',
            backgroundColor: '#F8FBFE',
            padding: '0.5rem',
            boxShadow:
              '0px 4px 4px 0px rgba(191, 191, 191, 0.10), 0px 0px 0px 3px rgba(232, 240, 255, 0.10), 0px 0px 4px 0px rgba(237, 237, 237, 0.25), 0px 0px 4px 0px rgba(177, 187, 197, 0.25) inset, 0px 0px 0px 6px rgba(222, 233, 255, 0.15)',
            marginBottom: '1rem'
          }}
        >
          <SvgWrapper
            icon={FormIcon}
            width="1.25rem"
            height="1.25rem"
            styleOverrides={{
              opacity: 0.8
            }}
            color="#2393C4"
          />
        </Box>

        <Stack display="flex" flexDirection="column" justifyContent="center">
          <Typography variant="h6Bold" color="#475467">
            Create Product Receipt
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            All product receipts created are stored in your vault
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const productOptions = useMemo(() => {
    const options: FormSelectOption[] = [];
    productData?.forEach(product => {
      options.push({
        title: product.name,
        value: product.product_id
      });
    });
    return options;
  }, [productData]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      header={renderDrawerHeader()}
      childrenBoxStyles={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'inherit'
      }}
    >
      <Box padding="1rem 1.25rem">
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          padding="1.25rem"
          mb="1.75rem"
          sx={{
            borderRadius: '0.5rem',
            border: '0.0625rem solid #F5F6F7',
            background: 'rgba(102, 112, 133, 0.02)'
          }}
        >
          <Stack display="flex" flexDirection="column" width="inherit">
            <FormAutocomplete<FormValues>
              control={control}
              multiple={false}
              name="customer_name"
              label="CUSTOMER NAME"
              options={customerOptions.map(option => option.title)}
            />

            <FormAutocomplete<FormValues>
              control={control}
              multiple={false}
              name="bank"
              label="BANK"
              options={bankOptions.map(option => option.title)}
            />
            <FormSelect<FormValues>
              name="product"
              options={productOptions}
              label="PRODUCT"
              control={control}
              renderSelected={(value: string) => value}
            />
            <FormInput<FormValues>
              control={control}
              name="quantity"
              label="QUANTITY"
            />
            <FormInput<FormValues>
              control={control}
              name="amount"
              label="AMOUNT PAID"
            />
            <FormDateInput<FormValues>
              control={control}
              name="expiry_date"
              label="EXPIRY DATE"
              format="YYYY-MM-DD"
              disablePast
            />
          </Stack>
        </Box>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack
          sx={{
            display: 'flex',
            padding: '0.75rem 1.5rem',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            gap: '1rem',
            mt: 'auto',
            alignSelf: 'stretch',
            borderRadius: '0rem 0rem 0rem 0.75rem',
            borderTop: '0.063rem solid  #F5F6F7',
            background: '#FCFCFD'
          }}
        >
          <Stack direction="row" spacing={1}>
            <Button
              color="grey"
              transparent
              size="md"
              text="Cancel"
              onClick={onClose}
            />
            <Button
              submit
              color="primary"
              size="md"
              text="Create"
              disabled={!isValid}
              loadingIcon={isLoading}
            />
          </Stack>
        </Stack>
      </form>
    </Drawer>
  );
};

export default CreateProductReceipt;
