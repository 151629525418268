import React, { useMemo, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import {
  useLazyGetAggregateProductReceiptsQuery,
  AggregatePRCsQueryArgs
} from 'services/api/analyticsApi';
import { useGetBranchesQuery } from 'services/api/branchApi';
import { FormSelect, FormDateInput, FormCheckBox } from 'components/form';
import type { FormSelectOption } from 'components/form';
import { AggregateProductReceiptAnalyticsApiData } from 'services/types/analytics';
import { useForm } from 'react-hook-form';
import { formatDateAxis, formatPrcQuantityAxis } from '../utils/formatAxis';

type FormValues = {
  branch: string;
  granularity: AggregatePRCsQueryArgs['params']['granularity'];
  start: string;
  end: string;
  includeClosed: boolean;
};

const emptyBranchOptions: FormSelectOption[] = [];

const TotalPrcValueChart = () => {
  const [analyticsResult, setAnalyticsResult] = useState<
    AggregateProductReceiptAnalyticsApiData[]
  >([]);
  const { data: branchesData } = useGetBranchesQuery({});
  const [getAggregatePRCs] = useLazyGetAggregateProductReceiptsQuery();

  const { getValues, control } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      branch: 'all',
      granularity: 'monthly',
      start: '2024-01-01',
      end: '',
      includeClosed: true
    }
  });

  const data: ChartData<'line'> = useMemo(() => {
    if (analyticsResult) {
      return {
        labels: analyticsResult.map(item => item.date),
        datasets: [
          {
            label: 'ATCs Received by Month',
            data: analyticsResult.map(item => item.value),
            backgroundColor: 'rgba(62, 125, 248, 0.8)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 2,
            tension: 0.5
          }
        ]
      };
    }
    return {
      labels: [],
      datasets: [
        {
          label: 'ATCs Received by Month',
          data: [],
          backgroundColor: 'rgba(62, 125, 248, 0.8)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 2,
          tension: 0.5
        }
      ]
    };
  }, [analyticsResult]);

  const options: ChartOptions<'line'> = {
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 20,
          callback: function (value) {
            return formatDateAxis(value, this);
          }
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: formatPrcQuantityAxis
        }
      }
    },
    animation: {
      duration: 2000
    },
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false
      },
      legend: {
        display: true
      }
    }
  };

  const validatedBranches = useMemo(() => {
    if (branchesData) {
      const branchOptions = [
        {
          title: 'All Branches',
          value: 'all'
        }
      ];
      branchOptions.push(
        ...branchesData.map(branch => ({
          title: branch.is_headquarters
            ? `${branch.name} - (Headquarters)`
            : branch.name,
          value: branch.branch_id
        }))
      );
      return branchOptions;
    }
    return emptyBranchOptions;
  }, [branchesData]);

  const onParamSelect = async () => {
    const branchId = getValues('branch');
    const currentGranularity = getValues('granularity');
    const startDate = getValues('start');
    const endDate = getValues('end');
    const includeClosed = getValues('includeClosed');

    // Try to find the current selected branch in the data
    let selectedBranch: string | undefined = undefined;
    if (branchesData) {
      const selectedBranchObj = branchesData.find(
        branch => branch.branch_id === branchId
      );
      selectedBranch = selectedBranchObj?.branch_id;
    }

    // Make api call for data and set result
    await getAggregatePRCs({
      params: {
        start: startDate,
        granularity: currentGranularity,
        ...(selectedBranch && { branches: selectedBranch }),
        ...(endDate !== '' && { end: endDate }),
        ...(includeClosed && { include_closed: 'true' })
      }
    })
      .unwrap()
      .then(result => {
        setAnalyticsResult(result);
      })
      .catch(error => console.log(error));
  };

  // Fetch default data on load
  useEffect(() => {
    (async () => {
      await getAggregatePRCs({
        params: {
          start: '2024-01-01',
          granularity: 'monthly'
        }
      })
        .unwrap()
        .then(result => {
          setAnalyticsResult(result);
        })
        .catch(error => console.log(error));
    })();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%'
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="1.25rem 0rem 1.25rem 0rem"
        spacing={1}
        width="100%"
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          flex="1 0 0"
          gap="0.5rem"
        >
          <Typography variant="bodyMediumSemibold">
            Total Value of ATC&apos;s
          </Typography>
        </Stack>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
            <FormDateInput<FormValues>
              control={control}
              name="start"
              label="Start Date"
              format="YYYY-MM-DD"
              onChange={onParamSelect}
            />
            <FormDateInput<FormValues>
              control={control}
              name="end"
              label="End Date"
              format="YYYY-MM-DD"
              onChange={onParamSelect}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '0.5rem',
              alignItems: 'center'
            }}
          >
            <Box sx={{ minWidth: '10rem' }}>
              <FormCheckBox<FormValues>
                control={control}
                label="Include Closed"
                name="includeClosed"
                onChange={onParamSelect}
              />
            </Box>
            <FormSelect<FormValues>
              name="branch"
              options={validatedBranches}
              label=""
              control={control}
              renderSelected={value => value}
              size="small"
              placeholder="Select One Option"
              onChange={onParamSelect}
            />
            <FormSelect<FormValues>
              name="granularity"
              options={[
                { title: 'Daily', value: 'daily' },
                { title: 'Monthly', value: 'monthly' },
                { title: 'Yearly', value: 'yearly' }
              ]}
              label=""
              control={control}
              renderSelected={value => value}
              size="small"
              placeholder="Select One Option"
              onChange={onParamSelect}
            />
          </Box>
        </Box>
      </Stack>
      <Box width="inherit">
        <Line data={data} options={options} />
      </Box>
    </Box>
  );
};

export default TotalPrcValueChart;
