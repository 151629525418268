import React, { useState } from 'react';
import { Box } from '@mui/material';
import DistributorLenders from './drawers/distributorLenders';
import { TabContainer } from 'components/tabs';
import DistributorTable from './tables/distributorTable';
import PickupRequestTable from '../../pickup/pickupRequestTable';

const DistributorsRegisteredToMerchant = () => {
  const [isOpen, setIsOpen] = useState(false);

  const tabs = [
    {
      labelText: 'Distributor list',
      content: <DistributorTable setIsOpen={setIsOpen} />
    },
    {
      labelText: 'Pickup request',
      content: <PickupRequestTable />
    }
  ];

  return (
    <Box>
      <DistributorLenders
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />

      <TabContainer tabs={tabs} />
    </Box>
  );
};

export default DistributorsRegisteredToMerchant;
