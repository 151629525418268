import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import Divider from 'components/common/divider';
import { FormAutocomplete } from 'components/form';
import React, { useContext } from 'react';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { theme } from 'themes/theme';
import { OnboardingContext } from '../context/onboardingContext';
import { orgStepFormValidator5 } from '../validators/onboardingValidators';

type FormValues = {
  emails?: string[];
};

const Invite = () => {
  const { handleNextStep, data, changeBtn, setChangeBtn } =
    useContext(OnboardingContext);

  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, isSubmitting }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      emails: data.emails
    },
    resolver: yupResolver(
      orgStepFormValidator5
    ) as unknown as Resolver<FormValues>
  });

  const onSubmit: SubmitHandler<FormValues> = data => {
    handleNextStep(data);
    toast.success('Invites sent!');
    setChangeBtn(!changeBtn);
  };

  return (
    <Box sx={{ width: { lg: '29.25rem' } }}>
      <Box width="inherit" sx={{ mb: '2.25rem', textAlign: 'center' }}>
        <Typography
          variant="h5"
          sx={{
            color: `${theme.palette.common.heading}`
          }}
        >
          Invite to Organization
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: `${theme.palette.common.labelColor}`,
            fontWeight: 400,
            letterSpacing: '-0.02rem',
            lineHeight: '1.2075rem'
          }}
        >
          {isValid
            ? 'Give selected team members a seat in your organization. Make sure the emails are accurate'
            : 'Welcome to ****** HQ. Invite team members and make sure the emails are accurate'}
        </Typography>
      </Box>

      <Box width="inherit">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormAutocomplete<FormValues>
            control={control}
            multiple={true}
            name="emails"
            label="EMAILS"
            options={[]}
          />

          <Button
            type={changeBtn ? 'button' : 'submit'}
            variant="contained"
            disabled={!isValid || !isDirty}
            sx={{
              padding: '0.75rem 1rem',
              color: 'white',
              width: '100%',
              fontSize: '1rem'
            }}
            onClick={() => {
              changeBtn ? navigate('/') : null;
            }}
          >
            {isSubmitting ? (
              <CircularProgress
                size={22}
                sx={{
                  color: 'common.white',
                  margin: '0px 10px'
                }}
              />
            ) : changeBtn ? (
              'Go to Dashboard'
            ) : (
              'Invite'
            )}
          </Button>
        </form>
      </Box>

      <Divider mt="1.5rem" fontWeight={400} />

      <Box
        width="inherit"
        sx={{
          mt: '1.5rem'
        }}
      >
        <Button
          variant="outlined"
          sx={{
            padding: '0.625rem 1rem',
            borderColor: 'white',
            backgroundColor: `${theme.palette.common.white}`,
            width: '100%'
          }}
          onClick={() => {
            changeBtn ? setChangeBtn(!changeBtn) : navigate('/');
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: '#3A4B6D'
            }}
          >
            {changeBtn ? 'Invite more' : 'Skip this step'}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Invite;
