import React, { useEffect } from 'react';
import waves from 'assets/svg/waves.svg';
import { Box } from '@mui/material';
import { useAppSelector, useAppDispatch } from 'services/hook';
import { setAppLoaded } from 'services/slices/appSlice';
import { usePrefetch as usePrefetchUsers } from 'services/api/userApi';
import { usePrefetch as usePrefetchPermissions } from 'services/api/permissionsApi';
import { usePrefetch as usePrefetchTeams } from 'services/api/teamApi';
import { usePrefetch as usePrefetchBranches } from 'services/api/branchApi';
import { usePrefetch as usePrefetchVaultMetaData } from 'services/api/vaultApi';
import _ from 'lodash';
import { theme } from 'themes/theme';
import Loading from 'components/loader/loading';

type Props = {
  children: JSX.Element;
};

const LoadingPage = ({ children }: Props) => {
  const { shouldReload, isLoaded, lastLoadTime } = useAppSelector(
    state => state.app
  );
  const prefetchUser = usePrefetchUsers('getUser');
  const prefetchPermissions = usePrefetchPermissions('getPermissions');
  const prefetchRoles = usePrefetchPermissions('getUserRoles');
  const prefetchTeams = usePrefetchTeams('getTeams');
  const prefetchBranches = usePrefetchBranches('getBranches');
  const prefetchVaultMetaData = usePrefetchVaultMetaData('getVaultMetaData');

  const dispatch = useAppDispatch();

  useEffect(() => {
    const runLoader = shouldReload || _.now() - lastLoadTime > 3600000;
    const delayedLoader = async () => {
      if (runLoader) {
        // Add all the top level data prefetching that is
        // needed for the onboarding center, home page and any
        // other important pages
        prefetchUser({ force: true });
        prefetchPermissions({ force: true });
        prefetchRoles({ force: true });
        prefetchTeams({ force: true });
        prefetchBranches({ force: true });
        prefetchVaultMetaData({ force: true });
      }
      dispatch(setAppLoaded({ lastLoadTime: _.now() }));
    };
    setTimeout(() => delayedLoader(), 1000);
  }, [shouldReload, isLoaded]);

  if (isLoaded) {
    return children;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundImage: `url(${waves})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: `${theme.palette.common.layoutBackground}`
      }}
    >
      <Loading loading={!isLoaded} />
    </Box>
  );
};
export default LoadingPage;
