import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  Typography,
  Chip as MUIChip,
  Box,
  Divider
} from '@mui/material';
import { ReactComponent as Dot } from 'assets/svg/dot.svg';
import { SvgWrapper } from 'components/svg';
import { useNavigate, NavLink } from 'react-router-dom';
import { NavRoute, NavRouteProps } from './types';

const NavRoutes = ({ routes, setIsDrawerOpen }: NavRouteProps) => {
  const [expandedRoute, setExpandedRoute] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleRouteClick = (route: NavRoute) => {
    if (route.children) {
      setExpandedRoute(prev => (prev === route.text ? null : route.text));
    }
  };

  return (
    <Box>
      <List
        sx={{
          width: 'inherit',
          padding: {
            md: '0rem'
          }
        }}
      >
        {routes.map((route, index) => (
          <React.Fragment key={route.link}>
            {route.position === 'top' && (
              <NavLink
                to={route.link}
                style={{ textDecoration: 'none' }}
                key={`nav-route-${index}`}
              >
                {({ isActive }) => (
                  <ListItem
                    sx={{
                      width: 'inherit',
                      backgroundColor: isActive ? '#F8FBFE' : '#FCFCFC'
                    }}
                    disablePadding
                  >
                    <ListItemButton
                      onClick={() => handleRouteClick(route)}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.75rem',
                        padding: '0.75rem 0.5rem',
                        borderRadius: '0.5rem'
                      }}
                      disableRipple
                    >
                      <SvgWrapper
                        icon={route.icon}
                        width="1.25rem"
                        height="1.25rem"
                        styleOverrides={{
                          fill: isActive ? '#3E7DF8' : '#CDD1DA'
                        }}
                      />
                      <Typography
                        variant={'bodyMediumMedium'}
                        sx={{ color: isActive ? '#3E7DF8' : '#667085' }}
                      >
                        {route.text}
                      </Typography>
                      {route.showChip && (
                        <MUIChip
                          label="3"
                          size="small"
                          sx={{
                            backgroundColor: '#F4F9FF',
                            marginLeft: 'auto'
                          }}
                        />
                      )}
                    </ListItemButton>
                  </ListItem>
                )}
              </NavLink>
            )}
            {route.children && expandedRoute === route.text && (
              <>
                {route.children.map((childRoute, childIndex) => (
                  <NavLink
                    to={childRoute.link}
                    style={{ textDecoration: 'none' }}
                    key={`nav-route-${index}-child-${childIndex}`}
                  >
                    {({ isActive }) => (
                      <ListItem
                        sx={{
                          backgroundColor: isActive ? '#F8FBFE' : '#FCFCFC',
                          '&:hover': {
                            backgroundColor: '#F8FBFE'
                          }
                        }}
                        disablePadding
                      >
                        <ListItemButton
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.75rem',
                            paddingY: '0.75rem',
                            borderRadius: '0.5rem'
                          }}
                          disableRipple
                        >
                          <SvgWrapper
                            icon={Dot}
                            width=".35rem"
                            height=".35rem"
                            styleOverrides={{
                              fill: isActive ? '#3E7DF8' : '#CDD1DA',
                              ml: '.5rem'
                            }}
                          />
                          <Typography
                            variant={'bodyMediumMedium'}
                            sx={{ color: isActive ? '#3E7DF8' : '#667085' }}
                          >
                            {childRoute.text}
                          </Typography>
                          {childRoute.showChip && (
                            <MUIChip
                              label="3"
                              size="small"
                              sx={{
                                backgroundColor: '#F4F9FF',
                                marginLeft: 'auto'
                              }}
                            />
                          )}
                        </ListItemButton>
                      </ListItem>
                    )}
                  </NavLink>
                ))}
              </>
            )}
          </React.Fragment>
        ))}
      </List>
      <Divider
        sx={{
          color: '#F5F6F7',
          height: '.0625rem',
          width: 'inherit',
          opacity: 0.4,
          marginY: '0.75rem'
        }}
      />
      <List
        sx={{
          padding: {
            md: '0rem'
          }
        }}
      >
        {routes.map((route, index) => {
          if (route.position === 'bottom') {
            return (
              <NavLink
                end={true}
                to={route.link}
                key={`sidebar-li-1-${index}`}
                style={{ textDecoration: 'none', transition: '0s' }}
              >
                {({ isActive }) => (
                  <ListItem
                    sx={{
                      width: 'inherit',
                      backgroundColor: isActive ? '#F8FBFE' : '#FCFCFC'
                    }}
                    disablePadding
                  >
                    <ListItemButton
                      sx={{
                        display: 'flex',
                        padding: '0.75rem 1.5rem 0.75rem 0.75rem',
                        alignItems: 'center',
                        height: '2.75rem',
                        gap: '0.75rem',
                        borderRadius: '.5rem'
                      }}
                      onClick={() => {
                        if (route.link === '/notifications') {
                          navigate(route.link);
                          navigate(-1);
                          setIsDrawerOpen(true);
                        } else {
                          navigate(route.link);
                        }
                      }}
                      disableRipple
                    >
                      <SvgWrapper
                        icon={route.icon}
                        width="1.25rem"
                        height="1.25rem"
                        styleOverrides={{
                          fill: isActive ? '#3E7DF8' : '#CDD1DA'
                        }}
                      />
                      <Typography
                        variant={'bodyMediumMedium'}
                        sx={{ color: isActive ? '#3E7DF8' : '#667085' }}
                      >
                        {route.text}
                      </Typography>
                      {route.showDot ? (
                        <Box
                          sx={{
                            width: '0.5rem',
                            height: '0.5rem',
                            borderRadius: '0.5rem',
                            border: '1.5px solid #FFF',
                            backgroundColor: '#1CA477',
                            marginLeft: 'auto'
                          }}
                        />
                      ) : null}
                    </ListItemButton>
                  </ListItem>
                )}
              </NavLink>
            );
          }
        })}
      </List>
    </Box>
  );
};
export default NavRoutes;
