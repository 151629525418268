import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { theme } from 'themes/theme';

interface NotificationGroupProps {
  label: string;
  description: string;
  children: React.ReactNode;
}

const NotificationGroup: React.FC<NotificationGroupProps> = ({
  label,
  description,
  children
}) => {
  return (
    <Stack
      width="inherit"
      direction="row"
      sx={{ borderBottom: '0.063rem solid #F5F6F7' }}
    >
      <Box
        width="34.25rem"
        sx={{
          display: 'flex',
          padding: '1.6875rem 0rem',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '0.75rem'
        }}
      >
        <Typography
          variant="captionLarge"
          color={`${theme.palette.common.subheading}`}
        >
          {label}
        </Typography>
        <Typography
          variant="bodyMediumMedium"
          color={`${theme.palette.common.textColor}`}
        >
          {description}
        </Typography>
      </Box>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default NotificationGroup;
