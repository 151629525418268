import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import google from 'assets/svg/google.svg';
import Divider from 'components/common/divider';
import { FormCheckBox, FormInput, FormPassword } from 'components/form';
import { logInAuthValidator } from 'pages/auth/validators/authFormValidators';
import React, { useEffect } from 'react';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLoginUserMutation } from 'services/api/authApi';
import { api } from 'services/api/baseApi';
import { useAppDispatch } from 'services/hook';
import { setAppShouldLoad } from 'services/slices/appSlice';
import { setUserLogin } from 'services/slices/authSlice';
import { fontFamily2, theme } from 'themes/theme';
import { isProductionEnvironment } from 'utilities/environment';

type FormValues = {
  email: string;
  password: string;
  remember: boolean;
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loginUser, { data, isSuccess, isError, error }] =
    useLoginUserMutation();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      email: '',
      password: '',
      remember: false
    },
    resolver: yupResolver(logInAuthValidator) as unknown as Resolver<FormValues>
  });

  const onSubmit: SubmitHandler<FormValues> = async data => {
    await loginUser({
      password: data.password,
      email: data.email,
      remember: data.remember
    });
  };

  interface MyError {
    data:
      | {
          non_field_errors: string[];
        }
      | string;
    status?: number;
  }

  useEffect(() => {
    if (data && isSuccess) {
      sessionStorage.clear();
      dispatch(api.util.resetApiState());
      dispatch(
        setUserLogin({
          isAuthenticated: true,
          userId: data.userid,
          organizationId: data.organization_id,
          organizationType: data.organization_type,
          membershipType: data.membership_type,
          supplyChainProfileId: data.supply_chain_profile_id,
          devAuthToken:
            !isProductionEnvironment() && data.token ? data.token : null,
          branch: {
            branchId: data.branch.branch_id,
            branchName: data.branch.name
          }
        })
      );
      dispatch(setAppShouldLoad());
      navigate('/');
    }
    if (error !== undefined) {
      const myError = error as MyError;
      if (
        typeof myError.data === 'object' &&
        Object.values(myError.data).length > 0
      ) {
        toast.error(Object.values(myError.data)[0].join(', '));
      } else if (typeof myError.data === 'string') {
        toast.error(myError.data);
      } else {
        toast.error('Login unsuccessful');
      }
    }
  }, [isSuccess, isError]);

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: `${theme.palette.common.card}`,
        border: `1.5px solid ${theme.palette.common.cardBorder}`,
        borderRadius: '.75rem',
        width: '30rem',
        margin: {
          md: '0 auto'
        },
        padding: '2.5rem 1.5rem',
        marginTop: {
          md: '5rem'
        },
        marginBottom: {
          md: '16rem'
        }
      }}
    >
      <Box sx={{ width: { lg: '27rem' } }}>
        <Box width="inherit" sx={{ mb: '1.5rem', textAlign: 'center' }}>
          <Typography
            variant="h5"
            sx={{
              color: `${theme.palette.common.subheading}`
            }}
          >
            Sign In
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: `${theme.palette.common.labelColor}`
            }}
          >
            Enter your credentials to access your account
          </Typography>
        </Box>

        <Button
          variant="outlined"
          sx={{
            padding: '0.625rem 1rem',
            border: `1px solid ${theme.palette.common.cardBorder}`,
            backgroundColor: `${theme.palette.common.white}`,
            mb: '1.5rem',
            width: '100%'
          }}
        >
          <Stack direction="row" gap=".75rem">
            <Box component="img" src={google} />
            <Typography
              sx={{
                color: `${theme.palette.common.subheading}`,
                fontSize: '1rem',
                fontFamily: fontFamily2,
                fontWeight: 600
              }}
            >
              Sign in with SSO
            </Typography>
          </Stack>
        </Button>

        <Divider mb="1.5rem" fontWeight={400} />

        <Box width="inherit">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormInput<FormValues>
              control={control}
              label="EMAIL"
              name="email"
            />

            <FormPassword<FormValues>
              control={control}
              label="PASSWORD"
              name="password"
            />

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormCheckBox<FormValues>
                control={control}
                label="Remember Me"
                name="remember"
              />

              <Link
                to="/forgot-password"
                style={{
                  textDecoration: 'none',
                  color: `${theme.palette.common.blueColor}`
                }}
              >
                <Typography variant="subtitle2">Forgot Password?</Typography>
              </Link>
            </Stack>

            <Button
              type="submit"
              variant="contained"
              disabled={!isDirty || !isValid || isSubmitting}
              sx={{
                padding: '0.75rem 1rem',
                color: 'white',
                width: '100%',
                mt: '1.5rem',
                fontSize: '1rem'
              }}
            >
              {isSubmitting ? (
                <CircularProgress
                  size={22}
                  sx={{
                    color: 'common.white',
                    margin: '0px 10px'
                  }}
                />
              ) : (
                'Log In'
              )}
            </Button>
          </form>
        </Box>
      </Box>
    </Paper>
  );
};

export default Login;
