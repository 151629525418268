import Cookies from 'js-cookie';
import { store } from '../services/store';
import { updateHeaderWithDevToken } from 'utilities/devToken';

interface AxiosConfigHelperProps {
  apiVersion: string;
  contentType: string;
  abortController?: AbortController;
}
/**
 *
 * @param {String} apiVersion
 * @param {String} contentType
 * @param {AbortController} abortController
 * @returns {object}
 *
 * This sets up config params for axios e.g.
 * headers that include the current auth token/csrf token/ content type.
 *
 * You can use like so,
 * axios.get(`api url`, axiosConfigHelper('v1', 'multipart/form-data')) or
 * axios.post(`api url`, axiosConfigHelper('v1', 'application/json'))
 */
export const axiosConfigHelper = ({
  apiVersion,
  contentType,
  abortController = new AbortController()
}: AxiosConfigHelperProps) => {
  // Headers
  const csrfToken = Cookies.get('csrftoken');
  const config = {
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/${apiVersion}`,
    headers: updateHeaderWithDevToken(
      {
        'Content-Type': contentType,
        'X-CSRFToken': csrfToken
      },
      store.getState().auth.devAuthToken
    ),
    signal: abortController.signal,
    withCredentials: true
  };

  return config;
};
