import React from 'react';
import { theme } from 'themes/theme';
import { Stack, Toolbar, Typography, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthNavbar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const registerOrgPath = pathname.startsWith('/register');

  return (
    <Toolbar
      sx={{
        width: 'inherit',
        backgroundColor: `${theme.palette.common.white}`,
        height: '3.75rem',
        borderBottom: '1px solid #FCFCFD'
      }}
    >
      <Stack
        width="100%"
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          padding: '0.75rem 1.8rem'
        }}
      >
        <Typography
          component="div"
          sx={{
            color: `${theme.palette.common.logoColor}`,
            fontSize: '1.25rem',
            fontWeight: '700',
            lineHeight: '1.5rem',
            letterSpacing: '-0.01875rem'
          }}
        >
          Flux
        </Typography>

        <Button
          variant="outlined"
          sx={{
            border: `1px solid ${theme.palette.common.btnColor}`,
            color: `${theme.palette.common.btnColor}`,
            backgroundColor: `${theme.palette.common.white}`,
            padding: '.5rem .75rem',
            width: '6rem',
            fontWeight: 600,
            lineHeight: '1.26rem',
            letterSpacing: '-0.01313rem'
          }}
          onClick={() =>
            registerOrgPath ? navigate('/login') : navigate('/register')
          }
        >
          {registerOrgPath ? 'Log in' : 'Sign up'}
        </Button>
      </Stack>
    </Toolbar>
  );
};

export default AuthNavbar;
