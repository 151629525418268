import React from 'react';

import { useForm, Resolver, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack, Typography } from '@mui/material';
import helpImage from 'assets/png/help-image.png';
import { ReactComponent as ChevronRightIcon } from 'assets/svg/chevron-right.svg';
import { ReactComponent as XIcon } from 'assets/svg/x.svg';
import { Drawer } from 'components/drawer';
import ShowInfo from 'components/info/showInfo';
import { createRoleValidator } from './createRoleValidator';
import { FormInput } from 'components/form';
import { SvgWrapper } from 'components/svg';
import { Button } from 'components/button';
import { useCreateRoleMutation } from 'services/api/permissionsApi';
import { Role } from '../types/rolesTypes';

interface DrawerProps {
  open: boolean;
  onClose: () => void;
  onCreate: (role: Role) => void;
}

type FormValues = {
  name: string;
  description: string;
};

const CreateRole: React.FC<DrawerProps> = ({ open, onClose, onCreate }) => {
  const [createOrganizationRole, { isLoading }] = useCreateRoleMutation();

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      name: '',
      description: ''
    },
    resolver: yupResolver(
      createRoleValidator
    ) as unknown as Resolver<FormValues>
  });

  const onSubmit: SubmitHandler<FormValues> = async data => {
    createOrganizationRole({
      data: {
        name: data.name,
        description: data.description
      }
    })
      .unwrap()
      .then(response => {
        const newRole: Role = {
          role: {
            role_id: response.role_id,
            name: response.name,
            description: response.description
          },
          role_id: response.role_id,
          permissions: [],
          users: []
        };
        ShowInfo({
          message: 'Role Created',
          subText: 'Kindly assign members and permissions to this role',
          type: 'success',
          componentType: 'toast',
          hideProgressBar: false
        });
        onClose();
        onCreate(newRole);
      })
      .catch(() => {
        ShowInfo({
          message: 'Role creation failed',
          subText: 'Role has not been created!',
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
        onClose();
      });
  };

  const renderDrawerHeader = () => {
    return (
      <Box
        padding="1.25rem 1.25rem 1.25rem 0rem"
        display="flex"
        flexDirection="column"
        gap="1.5rem"
      >
        <Stack
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          spacing={1}
        >
          <Typography variant="h6Bold" color="#475467">
            Create Role
          </Typography>
        </Stack>
        <Stack direction="row">
          <Box component="img" src={helpImage}></Box>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            padding="1.25rem"
            sx={{
              backgroundColor: '#FCFCFD',
              border: '1px solid #F2F4F7',
              borderRadius: '0.75rem'
            }}
          >
            <Typography variant="bodyMediumSemibold">
              Not sure where to start? Check out help center for tips
            </Typography>
            <SvgWrapper
              icon={ChevronRightIcon}
              height="1.5rem"
              width="1.5rem"
              color="#98A2B3"
            />
            <SvgWrapper
              icon={XIcon}
              height="1.5rem"
              width="1.5rem"
              color="#98A2B3"
              styleOverrides={{ opacity: '0.6' }}
            />
          </Stack>
        </Stack>
      </Box>
    );
  };

  return (
    <Drawer open={open} onClose={onClose} header={renderDrawerHeader()}>
      <Box>
        <Box
          padding="1rem 1.25rem"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Stack
            display="flex"
            padding="1.25rem"
            flexDirection="column"
            alignItems="flex-start"
            gap="1rem"
            alignSelf="stretch"
            sx={{
              background: '#FBFBFC',
              border: '0.063rem solid #F2F4F7',
              borderRadius: '0.75rem'
            }}
          >
            <FormInput<FormValues>
              control={control}
              name="name"
              label="NAME OF ROLE"
            />
            <FormInput<FormValues>
              control={control}
              name="description"
              label="DESCRIPTION"
            />
          </Stack>

          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Stack
              sx={{
                display: 'flex',
                padding: '0.75rem 1.5rem',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                gap: '1rem',
                alignSelf: 'stretch',
                borderRadius: '0rem 0rem 0rem 0.75rem',
                borderTop: '0.063rem solid #F5F6F7',
                mt: '.75rem'
              }}
            >
              <Stack direction="row" spacing={1}>
                <Button
                  color="grey"
                  transparent
                  size="md"
                  text="Cancel"
                  onClick={onClose}
                />
                <Button
                  submit
                  color="primary"
                  size="md"
                  text="Create Role"
                  disabled={!isValid}
                  loadingIcon={isLoading}
                />
              </Stack>
            </Stack>
          </form>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CreateRole;
