import React, { useState } from 'react';
import {
  TableBody as MUITableBody,
  TableRow,
  TableCell,
  Box,
  Typography
} from '@mui/material';
import { ColumnDef, Row, Table, flexRender } from '@tanstack/react-table';
import { ReactComponent as ChevronUpIcon } from 'assets/svg/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/svg/chevronDown.svg';
import { ReactComponent as Empty } from 'assets/custom-svg/table-empty-state.svg';
import { OptionsButton, IndeterminateCheckbox } from '../tableButtons';
import TableMenu from './tableMenu';
import type { TableMenuProps } from './tableMenu';
import { SvgWrapper } from 'components/svg';
import NestedTable from './nestedTable';
import { PulseLoader } from 'react-spinners';

interface TableBodyProps<TableDataT> {
  table: Table<TableDataT>;
  optionsButton?: boolean;
  rowShouldHover?: boolean;
  onClickRow?: (rowData: TableDataT) => void;
  showMenu?: boolean;
  hideTableHead?: boolean;
  loading?: boolean;
  menuItemProps: TableMenuProps<TableDataT>['menuItemProps'];
  columns: ColumnDef<TableDataT>[];
}

export default function TableBody<TableDataT>({
  table,
  optionsButton,
  rowShouldHover,
  showMenu,
  menuItemProps,
  hideTableHead,
  onClickRow,
  loading,
  columns
}: TableBodyProps<TableDataT>) {
  const [anchorEl, setAnchorEl] = useState<HTMLTableCellElement | null>(null);
  const [selectedRowMenu, setSelectedRowMenu] =
    useState<Row<TableDataT> | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'options-popover' : undefined;

  const onClickRowCallback = (rowData: TableDataT) => {
    if (onClickRow) {
      onClickRow(rowData);
    }
  };

  return (
    <MUITableBody sx={{ body: '#F5F6F7' }}>
      {loading ? (
        <TableRow>
          <TableCell colSpan={columns.length + 1} sx={{ textAlign: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '6rem 0rem'
              }}
            >
              <PulseLoader
                color="#D9D9D9"
                loading={loading}
                size=".7rem"
                aria-label="Loading Table"
                data-testid="loader"
                style={{
                  margin: '0 auto'
                }}
              />
              <Typography variant="bodyMediumMedium" color="#475467">
                Loading data...
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      ) : table.getRowModel().rows.length === 0 ? (
        <TableRow>
          <TableCell colSpan={columns.length + 1} sx={{ textAlign: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '6rem 0rem'
              }}
            >
              <SvgWrapper
                height="4.25rem"
                width="4.25rem"
                icon={Empty}
                styleOverrides={{ fill: 'none', margin: '0 auto' }}
              />

              <Typography variant="bodyMediumSemibold" color="#475467">
                No Data Found
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      ) : (
        table.getRowModel().rows.map(row => (
          <React.Fragment key={row.id}>
            <TableRow
              sx={{
                ...(rowShouldHover && {
                  '&:hover': {
                    background: '#FCFCFD',
                    cursor: 'pointer'
                  }
                }),
                borderBottom: '.0625rem solid #F5F6F7'
              }}
              onClick={() =>
                !optionsButton ? onClickRowCallback(row.original) : null
              }
            >
              <TableCell
                sx={{
                  borderBottom: 'none',
                  height: '4.188rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}
              >
                {!hideTableHead && (
                  <IndeterminateCheckbox
                    {...{
                      checked: row.getIsSelected(),
                      disabled: !row.getCanSelect(),
                      indeterminate: row.getIsSomeSelected(),
                      onChange: row.getToggleSelectedHandler()
                    }}
                  />
                )}

                {row.getCanExpand() && (
                  <Box
                    onClick={row.getToggleExpandedHandler()}
                    sx={{
                      cursor: 'pointer',
                      ml: '1rem'
                    }}
                  >
                    {row.getIsExpanded() ? (
                      <Box
                        component="span"
                        sx={{
                          width: '1.25rem',
                          height: '1.25rem',
                          borderRadius: '6.25rem',
                          background: '#F9FAFC',
                          display: 'flex',
                          justifyContent: 'center',
                          mr: '.5rem',
                          boxShadow:
                            '0rem 0.09375rem 0.25rem -0.0625rem rgba(20, 28, 40, 0.05)'
                        }}
                      >
                        <SvgWrapper
                          icon={ChevronUpIcon}
                          width="0.83331rem"
                          height="0.83331rem"
                          styleOverrides={{ fill: 'none', stroke: '#98A2B3' }}
                        />
                      </Box>
                    ) : (
                      <Box
                        component="span"
                        sx={{
                          width: '1.25rem',
                          height: '1.25rem',
                          borderRadius: '6.25rem',
                          background: '#F9FAFC',
                          display: 'flex',
                          justifyContent: 'center',
                          mr: '.5rem',
                          boxShadow:
                            '0rem 0.09375rem 0.25rem -0.0625rem rgba(20, 28, 40, 0.05)'
                        }}
                      >
                        <SvgWrapper
                          icon={ChevronDownIcon}
                          width="1.25rem"
                          height="1.25rem"
                          styleOverrides={{ fill: 'none', stroke: '#98A2B3' }}
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </TableCell>

              {row.getVisibleCells().map(cell => {
                return (
                  <TableCell
                    key={cell.id}
                    sx={{
                      borderBottom: 'none',
                      width: `${cell.column.getSize()}px`
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
              {optionsButton && (
                <TableCell
                  sx={{
                    borderBottom: '1px solid #F5F6F7',
                    marginRight: '15px',
                    right: '0px',
                    opacity: 0.95,
                    position: 'sticky',
                    width: '50px',
                    zIndex: 1,
                    backgroundColor: '#fff',
                    cursor: 'pointer'
                  }}
                  onClick={(
                    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>
                  ) => {
                    return showMenu
                      ? (setAnchorEl(e.currentTarget), setSelectedRowMenu(row))
                      : onClickRowCallback(row.original);
                  }}
                  aria-describedby={id}
                >
                  <OptionsButton />
                </TableCell>
              )}
            </TableRow>

            {row.getIsExpanded() && (
              <NestedTable
                row={row}
                optionsButton={optionsButton}
                onClickRowCallback={onClickRowCallback}
                hideTableHead={hideTableHead}
              />
            )}

            {selectedRowMenu && (
              <TableMenu<TableDataT>
                row={selectedRowMenu}
                id={id}
                open={open}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                menuItemProps={menuItemProps}
              />
            )}
          </React.Fragment>
        ))
      )}
    </MUITableBody>
  );
}
