import { useState, useEffect } from 'react';

interface GetTimerHook {
  formattedTime: string;
  reset: boolean;
}

export const useGetTimer = (): GetTimerHook => {
  const [seconds, setSeconds] = useState(59);
  const [minutes, setMinutes] = useState(0);
  const [reset, setReset] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (seconds === 0 && minutes === 0) {
        clearInterval(intervalId);
        setReset(false);
      } else if (seconds === 0) {
        setMinutes(prevMinutes => prevMinutes - 1);
        setSeconds(59);
      } else {
        setSeconds(prevSeconds => prevSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [seconds, minutes]);

  const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

  return { formattedTime, reset };
};
