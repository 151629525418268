import React from 'react';
import { Box } from '@mui/material';
import { WorkflowViewer } from 'components/workflows';
import { RoleRequestApiData } from 'services/types/permission';
import { WorkflowTargetObject } from 'services/enums/workflows';

const RequestWorkflows = ({
  roleRequest
}: {
  roleRequest: RoleRequestApiData;
}) => {
  return (
    <>
      {roleRequest.role_request_workflow && (
        <Box
          sx={{
            position: 'relative',
            height: {
              lg: '60svh',
              xl: '75svh'
            },
            width: 'inherit'
          }}
        >
          <WorkflowViewer
            workflow={roleRequest.role_request_workflow}
            workflowTargetObject={WorkflowTargetObject.ROLE_REQUEST}
            workflowTargetObjectId={roleRequest.role_request_id}
            invalidateTag="roleRequest"
            invalidateTagId={roleRequest.role_request_id}
          />
        </Box>
      )}
    </>
  );
};

export default RequestWorkflows;
