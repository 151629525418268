import React from 'react';
import { theme } from 'themes/theme';
import { Box, Typography, Paper, Button, Stack } from '@mui/material';
import { FormInput } from 'components/form';

import { useForm } from 'react-hook-form';

type FormValues = {
  current_password: string;
  new_password: string;
  confirm_password: string;
};

const ChangePassword = () => {
  const { control } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      current_password: '',
      new_password: '',
      confirm_password: ''
    }
  });
  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: `${theme.palette.common.white}`,
        border: `0.063rem solid ${theme.palette.common.cardBorder}`,
        borderRadius: '0.75rem',
        gap: '1.5rem',
        padding: '1.5rem',
        width: '100%'
      }}
    >
      <Box width="inherit">
        <Box
          width="inherit"
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography
            variant="bodyLargeSemibold"
            color={`${theme.palette.common.heading}`}
          >
            Change password
          </Typography>
          <Typography
            variant="bodyMediumRegular"
            color={`${theme.palette.common.labelColor}`}
          >
            Make sure you change it to something you can remember
          </Typography>
        </Box>

        <Box width="inherit" sx={{ mt: '1.5rem' }}>
          <FormInput<FormValues>
            control={control}
            name="current_password"
            label="CURRENT PASSWORD"
          />
          <FormInput<FormValues>
            control={control}
            name="new_password"
            label="NEW PASSWORD"
          />
          <FormInput<FormValues>
            control={control}
            name="confirm_password"
            label="CONFIRM PASSWORD"
          />
        </Box>

        <Stack direction="row" display="flex" justifyContent="flex-end">
          <Button
            variant="text"
            sx={{
              borderRadius: '0.5rem',
              padding: '0.5rem 0.75rem',
              gap: '0.25rem',
              mr: '1.25rem',
              border: '0.094rem solid #F2F4F7',
              backgroundColor: `${theme.palette.common.white}`
            }}
          >
            <Typography
              variant="bodyMediumSemibold"
              color={`${theme.palette.common.subheading}`}
            >
              Cancel
            </Typography>
          </Button>

          <Button
            variant="text"
            sx={{
              borderRadius: '0.5rem',
              padding: '0.5rem 0.75rem',
              gap: '0.25rem',
              border: '0.094rem solid #F2F4F7',
              backgroundColor: `${theme.palette.common.btnColor}`,
              '&:hover': {
                backgroundColor: '#0069d9',
                borderColor: '#0062cc',
                boxShadow: 'none'
              }
            }}
          >
            <Typography
              variant="bodyMediumSemibold"
              color={`${theme.palette.common.white}`}
            >
              Save Changes
            </Typography>
          </Button>
        </Stack>
      </Box>
    </Paper>
  );
};

export default ChangePassword;
