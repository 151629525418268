import React from 'react';

import { Avatar, Box, Stack, Typography } from '@mui/material';
import { Drawer } from 'components/drawer';

import { TabContainer } from 'components/tabs';
import SingleReceipts from './tabs/singleReceipts';
import BatchReceipts from './tabs/batchReceipts';

interface DrawerProps {
  open: boolean;
  onClose: () => void;
}

const LenderDetail: React.FC<DrawerProps> = ({ open, onClose }) => {
  const tabs = [
    {
      labelText: 'Single',
      content: <SingleReceipts />
    },
    {
      labelText: 'In Batches',
      content: <BatchReceipts />
    }
  ];

  const renderDrawerHeader = () => {
    return (
      <Typography variant="bodyMediumMedium" color="#475467">
        Suvwe Guaranteed Limited / Zenith bank Product Receipts
      </Typography>
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      header={renderDrawerHeader()}
      headerText
      childrenBoxStyles={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'inherit'
      }}
    >
      <Box padding="0rem 1.25rem">
        <Box margin="0.5rem 0rem 1.5rem 0rem">
          <Stack
            display="flex"
            flexDirection="row"
            padding="1.25rem 1.25rem 1.25rem 0rem"
          >
            <Avatar
              src=""
              sx={{
                width: '3.25rem',
                height: '3.25rem',
                mr: '1rem',
                justifyContent: 'center',
                borderRadius: '12.5rem'
              }}
            />
            <Stack
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="h6Bold" color="#475467">
                Zenith Bank
              </Typography>
              <Typography variant="captionXSmall" color="#98A2B3">
                No of Receipts
              </Typography>
              <Typography variant="bodySmallMedium" color="#667085">
                100
              </Typography>
            </Stack>
          </Stack>
        </Box>

        <TabContainer tabs={tabs} />
      </Box>
    </Drawer>
  );
};

export default LenderDetail;
