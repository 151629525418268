import React from 'react';
import { Typography } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { ReactComponent as Bank } from 'assets/svg/bank.svg';
import { ReactComponent as Flag } from 'assets/svg/flag.svg';
import { Chip } from 'components/chip';
import { Table } from 'components/table';
import { OrganizationType } from 'services/enums/organization';
import { ProductReceiptApiData } from 'services/types/productReceipt';
import { formattedDate, formattedNumber } from 'utilities/helperFunc/formatter';
import { useNavigate } from 'react-router-dom';

const SingleProductReceiptsTable = ({
  data,
  organizationType,
  fromBranchVault,
  loading
}: {
  data: ProductReceiptApiData[];
  organizationType: string;
  distributorId?: string;
  merchantId?: string;
  branchId?: string;
  fromBranchVault?: boolean;
  loading: boolean;
}) => {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<ProductReceiptApiData>();
  const columns = [
    columnHelper.accessor('product_receipt_id', {
      header: 'PRC CODE',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {info.getValue()}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('product', {
      header: 'PRODUCT',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {info.getValue().name} {info.getValue().unit}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('quoted_price', {
      header: 'TOTAL VALUE',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {formattedNumber(info.getValue(), true)}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('total_quantity', {
      header: 'QUANTITY',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {formattedNumber(info.getValue(), false)}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('created_on', {
      header: 'CREATION DATE',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {formattedDate(`${info.getValue()}`, 'Do MMM, YYYY hh:ma')}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    })
  ] as ColumnDef<ProductReceiptApiData>[];
  // Cast is a workaround due to issues with tansatck table
  // See https://github.com/TanStack/table/issues/4302

  const type = (organizationType: string) => {
    if (organizationType === OrganizationType.MERCHANT) {
      columns.splice(
        2,
        0,
        columnHelper.accessor('holders', {
          id: '0_holders',
          header: 'BANK',
          cell: info => <Chip label={info.getValue()[0].name} size="sm" />,
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptApiData>,
        columnHelper.accessor('holders', {
          id: '1_holders',
          header: 'CUSTOMER',
          cell: info => <Chip label={info.getValue()[1].name} size="sm" />,
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptApiData>
      );
    }
    if (organizationType === OrganizationType.LENDER) {
      columns.splice(
        2,
        0,
        columnHelper.accessor('merchant', {
          header: 'MERCHANT',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue().name}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptApiData>,
        columnHelper.accessor('holders', {
          header: 'CUSTOMER',
          cell: info => <Chip label={info.getValue()[1].name} size="sm" />,
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptApiData>
      );
    }
    if (organizationType === OrganizationType.DISTRIBUTOR) {
      columns.splice(
        2,
        0,
        columnHelper.accessor('holders', {
          header: 'BANK',
          cell: info => <Chip label={info.getValue()[0].name} size="sm" />,
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptApiData>,
        columnHelper.accessor('merchant', {
          header: 'MERCHANT',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue().name}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptApiData>
      );
    }
  };

  type(organizationType);

  const formattedData = [...data].reverse();

  const menuItems = [
    {
      image: Bank,
      text: 'Show details',
      onClickMenuItem: (rowData: ProductReceiptApiData) => {
        navigate(`/product-receipts/${rowData.product_receipt_id}/details`);
      },
      fill: '#98A2B3'
    },
    { image: Flag, text: 'Report Problem', fill: '#F86F66' }
  ];

  const getTableTitle = () => {
    if (fromBranchVault) {
      return `${data[0]?.assigned_branch?.name} branch`;
    } else if (organizationType === OrganizationType.DISTRIBUTOR) {
      return data[0]?.holders[0].name;
    } else {
      return data[0]?.holders[1].name;
    }
  };

  return (
    <Table<ProductReceiptApiData>
      hover
      columns={columns}
      data={formattedData}
      optionsButton
      menuItemProps={menuItems}
      enableFilter={false}
      filterColumns={['product']}
      searchPlaceholderText="Search with product receipt code, product etc"
      showMenu
      filterTabs={['product', 'merchant']}
      loadingTable={loading}
      title={
        loading || formattedData.length === 0 ? undefined : getTableTitle()
      }
      subtitle={
        loading || formattedData.length === 0
          ? undefined
          : `All ATC's for ${getTableTitle()}`
      }
    />
  );
};

export default SingleProductReceiptsTable;
