import React from 'react';
import frame from 'assets/svg/frame.svg';
import { fontFamily2, theme } from 'themes/theme';
import { memberAuthValidator } from 'pages/auth/validators/authFormValidators';
import {
  Typography,
  Grid,
  Box,
  Stack,
  CircularProgress,
  Link,
  Button
} from '@mui/material';
import { useForm, Resolver } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormInput, FormPassword } from 'components/form';

type FormValues = {
  firstname: string;
  lastname: string;
  password: string;
};

const RegisterMember = () => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      firstname: '',
      lastname: '',
      password: ''
    },
    resolver: yupResolver(
      memberAuthValidator
    ) as unknown as Resolver<FormValues>
  });

  const onSubmit = (data: unknown) => console.log(data);

  return (
    <Grid container spacing={2}>
      <Grid item md={5.5}>
        <Box
          sx={{
            width: '2.25rem',
            height: '2.25rem',
            borderRadius: '50%',
            backgroundColor: `${theme.palette.common.progressBackground}`,
            mt: '2.19rem',
            ml: {
              md: '1.2rem',
              lg: '3.19rem'
            }
          }}
        ></Box>

        <Box
          sx={{
            mt: '5.56rem',
            ml: {
              md: '1.2rem',
              lg: '3rem'
            },
            width: {
              lg: '30rem'
            },
            padding: {
              md: 0,
              lg: '2.5rem 1.5rem'
            }
          }}
        >
          <Box sx={{ width: { lg: '27rem' } }}>
            <Box width="inherit" sx={{ mb: '2.5rem' }}>
              <Typography
                variant="h4"
                sx={{
                  color: `${theme.palette.common.heading}`
                }}
              >
                Welcome To Flux
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: `${theme.palette.common.textColor}`
                }}
              >
                Use a password you can remember to secure your account
              </Typography>
            </Box>

            <Box width="inherit" sx={{ mb: '1.75rem' }}>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Stack
                  width="100%"
                  direction="row"
                  justifyContent="space-between"
                  gap="0.75rem"
                >
                  <FormInput<FormValues>
                    control={control}
                    label="FIRST NAME"
                    name="firstname"
                  />

                  <FormInput<FormValues>
                    control={control}
                    label="LAST NAME"
                    name="lastname"
                  />
                </Stack>

                <FormPassword<FormValues>
                  control={control}
                  label="PASSWORD"
                  name="password"
                  checkPassword
                />

                <Button
                  type="submit"
                  variant="contained"
                  disabled={!isDirty || !isValid}
                  sx={{
                    padding: '0.75rem 1rem',
                    color: 'white',
                    width: '100%',
                    fontSize: '1rem'
                  }}
                >
                  {isSubmitting ? (
                    <CircularProgress
                      size={22}
                      sx={{
                        color: 'common.white',
                        margin: '0px 10px'
                      }}
                    />
                  ) : (
                    'Go to Dashboard'
                  )}
                </Button>
              </form>
            </Box>

            <Stack
              direction="row"
              width="inherit"
              sx={{
                color: `${theme.palette.common.textColor}`,
                fontFamily: fontFamily2,
                fontSize: '0.875rem',
                fontWeight: 400,
                lineHeight: '1.225rem ',
                letterSpacing: '-0.01313rem',
                display: 'flex',
                flexWrap: 'wrap'
              }}
            >
              <Typography component="span">
                By clicking sign up, I agree to TafiCasa{' '}
                <Link
                  href="/"
                  sx={{
                    color: `${theme.palette.common.btnColor}`,
                    textDecoration: 'none'
                  }}
                >
                  {' '}
                  Terms of use
                </Link>{' '}
                &{' '}
                <Link
                  href="/"
                  sx={{
                    color: `${theme.palette.common.btnColor}`,
                    textDecoration: 'none'
                  }}
                >
                  {' '}
                  Privacy Policy
                </Link>
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Grid>

      <Grid item md={6.5}>
        <Box
          component="img"
          src={frame}
          alt="Flux app screenshot"
          width="100%"
        />
      </Grid>
    </Grid>
  );
};

export default RegisterMember;
