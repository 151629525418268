import React, { useEffect } from 'react';
import { Box, Tab as MUITab, Tabs as MUITabs, Typography } from '@mui/material';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import _ from 'lodash';

const tabList = [
  'profile',
  'organization',
  'notifications',
  'permissions',
  //'billing',
  //'integrations',
  'privacy-security'
];

const SettingsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentTab = tabList.findIndex(tab => {
    const pathSegments = location.pathname.split('/');
    return pathSegments[2] === tab;
  });

  const handleChange = (newTabIndex: number) => {
    navigate(`/settings/${tabList[newTabIndex]}`);
  };

  useEffect(() => {
    if (location.pathname === '/settings') {
      navigate('/settings/profile', { replace: true });
    }
  });

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <MUITabs
        orientation="horizontal"
        variant="fullWidth"
        value={Math.max(0, currentTab)}
        onChange={(e, value) => handleChange(value)}
        sx={{
          borderBottom: 1,
          borderColor: '#F5F6F7',
          marginBottom: '1.75rem',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        {tabList.map(tabId => (
          <MUITab
            key={tabId}
            label={
              <Typography variant="bodyMediumMedium">
                {_.startCase(tabId.replace('-', ' '))}
              </Typography>
            }
          />
        ))}
      </MUITabs>

      <Box
        sx={{
          maxHeight: '100%',
          overflowY: 'auto',
          paddingBottom: '4rem'
        }}
      >
        <Box role="tabpanel" sx={{ width: '100%' }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsPage;
