import {
  Autocomplete,
  FormControl,
  FormLabel,
  Chip as MUIChip,
  TextField
} from '@mui/material';
import type { FormAutocompleteProps } from 'components/form/common/formTypes';
import React from 'react';
import type { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { fontFamily2, theme } from 'themes/theme';

export default function FormAutocomplete<FormValueT extends FieldValues>(
  props: FormAutocompleteProps<FormValueT>
) {
  const { label, name, control, options, multiple, ...rest } = props;

  return (
    <FormControl
      fullWidth
      sx={{
        paddingBottom: { xs: 3 },
        '& .css-13nvzlm-MuiFormHelperText-root': {
          fontSize: '13px',
          ml: 0
        }
      }}
    >
      <FormLabel
        htmlFor={name}
        sx={{
          fontFamily: fontFamily2,
          fontSize: '0.75rem',
          fontWeight: 400,
          lineHeight: '1.05rem',
          letterSpacing: '-0.015rem',
          color: `${theme.palette.common.labelColor}`,
          mb: '0.25rem'
        }}
      >
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error, isTouched, invalid } }) => {
          // TODO Task #540
          const valueArray = Array.isArray(field.value) ? field.value : [];
          const valueString = field.value || '';
          return (
            <Autocomplete
              multiple={multiple}
              freeSolo
              id={name}
              {...field}
              {...rest}
              options={options}
              value={multiple ? valueArray : valueString}
              getOptionLabel={(option: string) => `${option}`}
              onChange={(_, newValue) => field.onChange(newValue)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <MUIChip
                    {...getTagProps({ index })}
                    label={option}
                    key={option}
                  />
                ))
              }
              renderInput={params => (
                <TextField
                  {...params}
                  {...rest}
                  fullWidth
                  id={name}
                  type="text"
                  helperText={error?.message}
                  error={invalid && isTouched}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      const value = (e.target as HTMLInputElement).value.trim();
                      if (value) {
                        field.onChange([...valueArray, value]);
                        (e.target as HTMLInputElement).value = '';
                      }
                    }
                  }}
                  FormHelperTextProps={{
                    sx: {
                      ml: '0px',
                      mr: '0px',
                      fontFamily: fontFamily2,
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      letterSpacing: '-0.025em',
                      width: '100%',
                      textAlign: 'left',
                      color: `${theme.palette.common.error}`
                    },
                    onClick: e => {
                      e.stopPropagation();
                    },
                    onMouseDown: e => {
                      e.stopPropagation();
                    }
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      minHeight: '3.2rem',
                      borderRadius: '0.5rem',
                      border: '1.5px solid #EAECF0',
                      background: '#FEFFFF',
                      boxShadow: '0px 4px 4px 0px rgba(220, 220, 220, 0.02)',
                      '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent'
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: `${theme.palette.common.btnColor}`,
                        boxShadow: `${theme.palette.common.boxShadow}`
                      }
                    }
                  }}
                />
              )}
            />
          );
        }}
      />
    </FormControl>
  );
}
