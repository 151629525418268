import * as Yup from 'yup';

export const bulkUploadFormValidator = Yup.object({
  file: Yup.mixed<FileList>()
    .nullable()
    .required()
    .test('fileType', value => {
      if (!value || !value[0]) return true;
      return value[0].type === 'image/*';
    })
    .test('fileSize', 'File size is too large', value => {
      if (!value || !value[0]) return true;
      const maxSize = 160 * 1024;
      return value[0].size <= maxSize;
    })
});

export const createTeamValidator = Yup.object({
  name: Yup.string().required('This field is required'),
  description: Yup.string()
});

export const createTeamManuallyValidator = Yup.object({
  members: Yup.string()
    .email('Invalid email format')
    .required('An Email is required')
});

export const bulkTeamUploadFormValidator = Yup.object({
  file: Yup.mixed<FileList>()
    .nullable()
    .required()
    .test('fileType', value => {
      if (!value || !value[0]) return true;
      return value[0].type === 'image/*';
    })
    .test('fileSize', 'File size is too large', value => {
      if (!value || !value[0]) return true;
      const maxSize = 160 * 1024;
      return value[0].size <= maxSize;
    })
});
