import React, { useEffect, useState } from 'react';
import {
  Paper,
  Box,
  Typography,
  CircularProgress,
  Button
} from '@mui/material';
import modalCheck from 'assets/svg/modal-check.svg';
import { FormPassword } from 'components/form';
import { theme } from 'themes/theme';
import { passwordValidator } from './validators/authFormValidators';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Resolver, SubmitHandler } from 'react-hook-form';
import AuthModal from 'pages/auth/common/authModal';
import { useNavigate, useParams } from 'react-router-dom';
import { usePasswordResetCompleteMutation } from 'services/api/userApi';
import { toast } from 'react-toastify';

type FormValues = {
  password: string;
};

const ResetPassword = (): JSX.Element => {
  const navigate = useNavigate();
  const { resetId } = useParams();
  const [passwordReset, setPasswordReset] = useState(false);

  const [passwordResetComplete, { error, isSuccess, isError }] =
    usePasswordResetCompleteMutation();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      password: ''
    },
    resolver: yupResolver(passwordValidator) as unknown as Resolver<FormValues>
  });

  const onSubmit: SubmitHandler<FormValues> = async data => {
    resetId !== undefined &&
      (await passwordResetComplete({
        reset_id: resetId,
        new_password: data.password,
        new_password_repeated: data.password
      }));
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Password reset successfully!');
      isSuccess && setPasswordReset(!passwordReset);
      navigate('/login');
    }
    if (error !== undefined) {
      const myError = error as MyError;
      if (
        typeof myError.data === 'object' &&
        typeof myError.data !== 'string' &&
        Object.values(myError.data).length > 0
      ) {
        toast.error(
          `${Object.keys(myError.data)[0]}: ${Object.values(myError.data)[0]}`
        );
      } else if (typeof myError.data === 'string') {
        toast.error(myError.data);
      } else {
        toast.error('Reset unsuccessful.');
      }
    }
  }, [isSuccess, isError]);

  return (
    <>
      {passwordReset ? (
        <AuthModal
          src={modalCheck}
          text={'Reset Complete'}
          subText={
            'You have successfully reset your password. Click on the button below to log in.'
          }
          onClick={() => navigate('/login')}
          disabled={false}
          btnText={'Return to Login'}
        />
      ) : (
        <Paper
          elevation={0}
          sx={{
            backgroundColor: `${theme.palette.common.card}`,
            border: `1.5px solid ${theme.palette.common.cardBorder}`,
            borderRadius: '.75rem',
            width: '30rem',
            margin: {
              md: '0 auto'
            },
            padding: '2.5rem 1.5rem',
            marginTop: {
              md: '13rem'
            },
            marginBottom: {
              md: '20rem'
            }
          }}
        >
          <Box sx={{ width: { lg: '27rem' } }}>
            <Box width="inherit" sx={{ mb: '2.5rem', textAlign: 'center' }}>
              <Typography
                variant="h5"
                sx={{
                  color: `${theme.palette.common.heading}`,
                  fontSize: '1.25rem',
                  lineHeight: '1.5rem'
                }}
              >
                Create New Password
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: `${theme.palette.common.textColor}`,
                  fontWeight: 400
                }}
              >
                Choose a new and secure password that you can remember easily
              </Typography>
            </Box>

            <Box width="inherit">
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormPassword<FormValues>
                  control={control}
                  label="PASSWORD"
                  name="password"
                  checkPassword
                />

                <Button
                  type="submit"
                  variant="contained"
                  disabled={!isDirty || !isValid}
                  sx={{
                    padding: '0.75rem 1rem',
                    color: 'white',
                    width: '100%',
                    fontSize: '1rem'
                  }}
                >
                  {isSubmitting ? (
                    <CircularProgress
                      size={22}
                      sx={{
                        color: 'common.white',
                        margin: '0px 10px'
                      }}
                    />
                  ) : (
                    'Set Password'
                  )}
                </Button>
              </form>
            </Box>
          </Box>
        </Paper>
      )}
    </>
  );
};

export default ResetPassword;

export interface MyError {
  data:
    | {
        [key: string]: string[];
      }
    | string;
  status?: number;
}
