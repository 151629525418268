import React from 'react';
import { FormControl, FormLabel, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { fontFamily2, theme } from 'themes/theme';
import type { FormControlProps } from 'components/form/common/formTypes';
import type { FieldValues, ControllerRenderProps } from 'react-hook-form';

export default function FormInput<FormValueT extends FieldValues>(
  props: FormControlProps<FormValueT>
) {
  const { label, name, control, onChange, type = 'text', ...rest } = props;

  const handleFormInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    hookFormCallback: ControllerRenderProps['onChange']
  ) => {
    // Call the hook form on Change
    const value = event.target.value;
    hookFormCallback(value);

    // Call the passed in callback
    if (onChange) {
      onChange();
    }
  };

  return (
    <FormControl
      fullWidth
      sx={{
        paddingBottom: { xs: 3 },
        '& .css-13nvzlm-MuiFormHelperText-root': {
          fontSize: '13px',
          ml: 0
        }
      }}
    >
      <FormLabel
        htmlFor={name}
        sx={{
          fontFamily: fontFamily2,
          fontSize: '0.75rem',
          fontWeight: 400,
          lineHeight: '1.05rem',
          letterSpacing: '-0.015rem',
          color: `${theme.palette.common.labelColor}`,
          mb: '0.25rem'
        }}
      >
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange: onFieldChange, ...restOfField },
          fieldState: { error, isTouched, invalid }
        }) => (
          <TextField
            error={invalid && isTouched}
            id={name}
            type={type}
            helperText={error?.message}
            {...restOfField}
            {...rest}
            onChange={e => {
              handleFormInputChange(e, onFieldChange);
            }}
            FormHelperTextProps={{
              sx: {
                ml: '0px',
                mr: '0px',
                fontFamily: fontFamily2,
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '19px',
                letterSpacing: '-0.025em',
                width: '100%',
                textAlign: 'left',
                color: `${theme.palette.common.error}`
              }
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: '3.2rem',
                borderRadius: '0.5rem',
                border: '1.5px solid #EAECF0',
                background: '#FEFFFF',
                boxShadow: '0px 4px 4px 0px rgba(220, 220, 220, 0.02)',
                '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent'
                },
                '&.Mui-focused fieldset': {
                  borderColor: `${theme.palette.common.btnColor}`,
                  boxShadow: `${theme.palette.common.boxShadow}`
                }
              }
            }}
          />
        )}
      />
    </FormControl>
  );
}
