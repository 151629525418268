interface ErrorResponse {
  [key: string]: string | string[] | ErrorResponse;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function constructErrorMessage(errorObj: any): string {
  let errorMessage = 'Error:';
  for (const key in errorObj) {
    if (Object.prototype.hasOwnProperty.call(errorObj, key)) {
      const value = errorObj[key];
      if (typeof value === 'string') {
        errorMessage += `\n ${value}`;
      } else if (Array.isArray(value)) {
        return `${key}: ${value.join(', ')}`;
      } else if (typeof value === 'object') {
        return `${constructErrorMessage(value as ErrorResponse)}`;
      }
    }
  }
  return errorMessage;
}

export default constructErrorMessage;
