import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from 'assets/svg/chevron-right.svg';
import { ReactComponent as ChevronLeftIcon } from 'assets/svg/chevron-left.svg';
import { SvgWrapper } from 'components/svg';
import { Table } from '@tanstack/react-table';

interface TableFooterProps<TableDataT> {
  table: Table<TableDataT>;
}

export default function TableFooter<TableDataT>({
  table
}: TableFooterProps<TableDataT>) {
  const getPageInfoText = () => {
    const currentPage = table.getState().pagination.pageIndex + 1;
    const maxPages = table.getPageCount();

    if (currentPage) {
      return `Page ${currentPage} of ${maxPages}`;
    } else {
      return '0 Pages';
    }
  };

  const getPageButtons = () => {
    const currentPage = table.getState().pagination.pageIndex + 1;
    const maxPages = table.getPageCount();

    // get 5 pages including the current page to render
    let startPage = currentPage - 2;
    if (startPage < 1) {
      startPage = 1;
    }

    let stopPage = startPage + 4;
    if (stopPage > maxPages) {
      stopPage = maxPages;
      // if we have hit the max page lets just set the
      // start page to 5 pages back but not go back beyond 1
      startPage = maxPages - 4;
      if (startPage < 1) {
        startPage = 1;
      }
    }

    // render 5 page buttons
    const buttons = [];
    for (let idx = startPage; idx <= stopPage; idx++) {
      buttons.push(idx);
    }
    return buttons;
  };

  const goToNextPage = () => {
    if (table.getCanNextPage()) {
      table.nextPage();
    }
  };

  const goToPrevPage = () => {
    if (table.getCanPreviousPage()) {
      table.previousPage();
    }
  };

  return (
    <Box
      sx={{
        marginTop: '12px',
        padding: '10px',
        display: 'flex',
        flexGrow: 1,
        maxWidth: '100%',
        backgroundColor: '#FFF'
      }}
    >
      <Typography
        variant="bodyMediumSemibold"
        sx={{
          marginRight: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        color="#475467"
      >
        {getPageInfoText()}
      </Typography>
      <Stack direction="row" spacing={0}>
        {getPageButtons().map((page: number) => {
          // If active page render special button
          if (page === table.getState().pagination.pageIndex + 1) {
            return (
              <Box
                key={`pagination-page-btn-${page}`}
                onClick={() => {
                  table.setPageIndex(page - 1);
                }}
                sx={{
                  width: '35px',
                  height: '36px',
                  padding: 'auto',
                  borderRadius: '6px',
                  border: '1px solid #BFD5FF',
                  background: '#EAF1FF',
                  color: '#98A2B3',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {page}
              </Box>
            );
          } else {
            return (
              <Box
                key={`pagination-page-btn-${page}`}
                onClick={() => {
                  table.setPageIndex(page - 1);
                }}
                sx={{
                  width: '35px',
                  height: '36px',
                  padding: 'auto',
                  color: '#98A2B3',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {page}
              </Box>
            );
          }
        })}
      </Stack>
      <Box
        sx={{
          marginLeft: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          onClick={() => goToPrevPage()}
          sx={{
            cursor: 'pointer',
            backgroundColor: '#F2F4F7',
            color: '#667085',
            padding: '0.5rem',
            width: '2.25rem',
            height: '2.25rem',
            borderTopLeftRadius: '0.375rem',
            borderBottomLeftRadius: '0.375rem'
          }}
        >
          <SvgWrapper
            icon={ChevronLeftIcon}
            width="1.25rem"
            height="1.25rem"
            styleOverrides={{ fill: 'none', stroke: '#667085' }}
          />
        </Box>
        <Box
          onClick={() => goToNextPage()}
          sx={{
            cursor: 'pointer',
            backgroundColor: '#F2F4F7',
            color: '#667085',
            padding: '0.5rem',
            width: '2.25rem',
            height: '2.25rem',
            borderTopRightRadius: '0.375rem',
            borderBottomRightRadius: '0.375rem'
          }}
        >
          <SvgWrapper
            icon={ChevronRightIcon}
            width="1.25rem"
            height="1.25rem"
            styleOverrides={{ fill: 'none', stroke: '#667085' }}
          />
        </Box>
      </Box>
    </Box>
  );
}
