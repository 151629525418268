import React, { useState } from 'react';
import { Box, Paper, Stack, Typography, Divider } from '@mui/material';
import { ReactComponent as Customer } from 'assets/custom-svg/customer-logo.svg';
import { ReactComponent as FileLocked } from 'assets/svg/file-locked.svg';
import { ReactComponent as Marker } from 'assets/svg/marker-pin.svg';
import { ReactComponent as Alarm } from 'assets/svg/alarm-clock.svg';
import { ReactComponent as Ellipse } from 'assets/svg/ellipse.svg';
import { ReactComponent as Eye } from 'assets/svg/eye.svg';
import { ReactComponent as EyeClosed } from 'assets/svg/eye-closed.svg';
import { Chip } from 'components/chip';
import { Button } from 'components/button';
import { SvgWrapper } from 'components/svg';
import PickupAgentDetails from './pickupAgentDetails';
import PickupVehicleDetails from './pickupVehicleDetails';
import { ProductReceiptApiData } from 'services/types/productReceipt';
import {
  formattedDate,
  formattedQuantity
} from 'utilities/helperFunc/formatter';
import { useAppSelector } from 'services/hook';
import { OrganizationType } from 'services/enums/organization';
import { ProductReceiptStatus } from 'services/enums/product';

interface PickupRequestProps {
  productReceipt: ProductReceiptApiData;
  setSchedulePickupModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setValidatePickupCodeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PickupRequestDetails = ({
  setSchedulePickupModalOpen,
  setValidatePickupCodeModalOpen,
  productReceipt
}: PickupRequestProps) => {
  const { organizationType } = useAppSelector(state => state.auth);
  const [showPickupCode, setShowPickupCode] = useState<boolean>(false);
  const { pickup_request, product } = productReceipt;

  return (
    <Box
      sx={{
        width: 'inherit',
        maxWidth: '80rem',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '.75rem'
      }}
    >
      {organizationType === OrganizationType.MERCHANT && (
        <Paper
          elevation={0}
          sx={{
            padding: '1rem',
            borderRadius: '0.75rem',
            backgroundColor: '#FFF',
            border: '0.0625rem solid #FCFCFD',
            width: 'inherit',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            boxShadow:
              '0rem 0.09375rem 0.25rem -0.0625rem rgba(182, 182, 182, 0.07)'
          }}
        >
          <Stack
            sx={{
              width: 'auto',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '.5rem'
            }}
          >
            <Typography variant="bodyMediumSemibold" color="#475467">
              {product.name}
            </Typography>
            <SvgWrapper
              icon={Ellipse}
              width="0.375rem"
              height="0.375rem"
              styleOverrides={{
                stroke: 'none',
                fill: '#EAECF0',
                margin: '0rem .15rem'
              }}
            />
            <Typography variant="bodyMediumMedium" color="#667085">
              {formattedQuantity(product.unit || '')}
            </Typography>
            <Chip
              label={
                pickup_request?.scheduled_on ? 'Scheduled' : 'Not Scheduled'
              }
              size="sm"
              color={
                productReceipt.closed_on
                  ? 'default'
                  : pickup_request?.scheduled_on
                    ? 'success'
                    : 'warning'
              }
              icon
            />
          </Stack>
          {productReceipt.is_merchant && (
            <Stack alignItems="center" direction="row" gap=".5rem">
              {productReceipt.closed_on ? (
                <Chip label="Product Released" size="sm" color="success" icon />
              ) : !pickup_request?.scheduled_on ? (
                <Button
                  color="primary"
                  size="sm"
                  text="Schedule Pickup"
                  styleOverrides={{
                    mr: '0.5rem'
                  }}
                  onClick={() =>
                    setSchedulePickupModalOpen &&
                    setSchedulePickupModalOpen(true)
                  }
                />
              ) : (
                <Button
                  color="primary"
                  size="sm"
                  text="Enter Pickup Code"
                  styleOverrides={{
                    mr: '0.5rem'
                  }}
                  onClick={() =>
                    setValidatePickupCodeModalOpen &&
                    setValidatePickupCodeModalOpen(true)
                  }
                />
              )}
            </Stack>
          )}
        </Paper>
      )}

      {organizationType === OrganizationType.MERCHANT && (
        <Paper
          elevation={0}
          sx={{
            padding: '1rem',
            borderRadius: '0.75rem',
            backgroundColor: '#FFF',
            border: '0.0625rem solid #FCFCFD',
            width: 'inherit',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: `${pickup_request?.pickup_date === null ? 'space-around' : 'space-between'}`,
            boxShadow:
              '0rem 0.09375rem 0.25rem -0.0625rem rgba(182, 182, 182, 0.07)'
          }}
        >
          <Stack
            sx={{
              width: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="captionSmall" color="#98A2B3">
              PICKUP ID
            </Typography>

            <Typography variant="captionLarge" color="#475467">
              #{pickup_request?.product_receipt_id}
            </Typography>
          </Stack>

          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              width: '0.0625rem',
              height: '2.5rem',
              borderRadius: '0.125rem',
              borderLeft: '0.0625rem solid #EAECF0',
              borderRight: '0.0625rem solid #EAECF0',
              margin: '0rem 1.5rem',
              backgroundColor: '#F2F4F7'
            }}
          />

          <Stack
            direction="column"
            sx={{
              width: 'auto'
            }}
          >
            <Typography variant="captionSmall" color="#D0D5DD">
              CUSTOMER
            </Typography>
            <Stack alignItems="center" direction="row" gap=".5rem">
              {pickup_request?.customer?.logo ? (
                <Box
                  component="img"
                  src={pickup_request?.customer?.logo}
                  sx={{
                    width: '1.25rem',
                    height: '1.25rem',
                    borderRadius: '50%',
                    objectFit: 'cover'
                  }}
                />
              ) : (
                <SvgWrapper icon={Customer} width="1.25rem" height="1.25rem" />
              )}
              <Typography variant="bodyMediumMedium" color="#667085">
                {pickup_request?.customer?.name || '---'}
              </Typography>
            </Stack>
          </Stack>

          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              width: '0.0625rem',
              height: '2.5rem',
              borderRadius: '0.125rem',
              borderLeft: '0.0625rem solid #EAECF0',
              borderRight: '0.0625rem solid #EAECF0',
              margin: '0rem 1.5rem',
              backgroundColor: '#F2F4F7'
            }}
          />

          <Stack
            direction="column"
            sx={{
              width: 'auto',
              mr: '1.5rem'
            }}
          >
            <Typography variant="captionSmall" color="#D0D5DD">
              DEPOT
            </Typography>
            <Typography variant="bodyMediumMedium" color="#667085">
              {pickup_request?.depot?.name || '---'}
            </Typography>
          </Stack>
        </Paper>
      )}

      {organizationType !== 'merchant' && (
        <Paper
          elevation={0}
          sx={{
            padding: '1rem',
            borderRadius: '0.75rem',
            backgroundColor: '#FFF',
            border: '0.0625rem solid #FCFCFD',
            width: 'inherit',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: `${pickup_request?.pickup_date === null ? 'space-around' : 'space-between'}`,
            boxShadow:
              '0rem 0.09375rem 0.25rem -0.0625rem rgba(182, 182, 182, 0.07)',
            cursor: 'pointer'
          }}
        >
          <Stack
            width="auto"
            display="flex"
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Box
              sx={{
                width: '2.75rem',
                height: '3.25rem',
                padding: '1rem .75rem',
                borderRadius: '.75rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mr: '.5rem',
                background:
                  'linear-gradient(358deg, #F8F8F8 -6.95%, #FDFDFD 39.34%, rgba(243, 243, 243, 0.57) 66.07%, rgba(222, 226, 231, 0.00) 97.37%)'
              }}
            >
              <SvgWrapper
                icon={FileLocked}
                width="1.25rem"
                height="1.25rem"
                styleOverrides={{
                  fill: '#D0D5DD'
                }}
              />
            </Box>

            <Typography variant="bodyMediumSemibold" color="#475467">
              {product.name}
            </Typography>

            <SvgWrapper
              icon={Ellipse}
              width="0.375rem"
              height="0.375rem"
              styleOverrides={{
                stroke: 'none',
                fill: '#EAECF0',
                margin: '0rem 0.5rem'
              }}
            />

            <Typography
              variant="bodyMediumSemibold"
              color="#475467"
              sx={{ marginRight: '0.5rem' }}
            >
              {formattedQuantity(product.unit || '')}
            </Typography>
            {productReceipt.status === ProductReceiptStatus.CLOSED && (
              <Chip label="Picked Up" size="sm" color="success" icon />
            )}
          </Stack>

          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              width: '0.0625rem',
              height: '2.5rem',
              borderRadius: '0.125rem',
              borderLeft: '0.0625rem solid #EAECF0',
              borderRight: '0.0625rem solid #EAECF0',
              margin: '0rem 1.5rem',
              backgroundColor: '#F2F4F7'
            }}
          />

          <Stack alignItems="center" direction="row" gap=".69rem">
            <Box
              sx={{
                borderRadius: '6.25rem',
                padding: '.5rem',
                background: '#F5F6F7',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <SvgWrapper
                icon={Marker}
                width="1rem"
                height="1rem"
                styleOverrides={{ stroke: '#98A2B3', fill: 'none' }}
              />
            </Box>

            <Stack
              direction="column"
              sx={{
                width: 'auto'
              }}
            >
              <Typography variant="captionSmall" color="#D0D5DD">
                DEPOT
              </Typography>
              <Typography variant="bodyMediumMedium" color="#667085">
                {pickup_request?.depot?.name || '---'}
              </Typography>
            </Stack>
          </Stack>

          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              width: '0.0625rem',
              height: '2.5rem',
              borderRadius: '0.125rem',
              borderLeft: '0.0625rem solid #EAECF0',
              borderRight: '0.0625rem solid #EAECF0',
              margin: '0rem 1.5rem',
              backgroundColor: '#F2F4F7'
            }}
          />

          {pickup_request?.pickup_date === null ? (
            <Stack alignItems="center" direction="row" gap=".69rem" mr="1.5rem">
              <Box
                sx={{
                  borderRadius: '6.25rem',
                  padding: '.5rem',
                  background: '#F5F6F7',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <SvgWrapper
                  icon={Alarm}
                  width="1rem"
                  height="1rem"
                  styleOverrides={{ stroke: '#98A2B3', fill: 'none' }}
                />
              </Box>

              <Stack
                direction="column"
                sx={{
                  width: 'auto'
                }}
              >
                <Typography variant="captionSmall" color="#D0D5DD">
                  MERCHANT
                </Typography>
                <Typography variant="bodyMediumMedium" color="#667085">
                  {productReceipt?.merchant?.name || '---'}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <>
              <Stack
                alignItems="center"
                direction="row"
                gap=".69rem"
                marginRight="2rem"
              >
                <Box
                  sx={{
                    borderRadius: '6.25rem',
                    padding: '.5rem',
                    background: '#F5F6F7',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <SvgWrapper
                    icon={Alarm}
                    width="1rem"
                    height="1rem"
                    styleOverrides={{ stroke: '#98A2B3', fill: 'none' }}
                  />
                </Box>

                <Stack
                  direction="column"
                  sx={{
                    width: 'auto'
                  }}
                >
                  <Typography variant="captionSmall" color="#D0D5DD">
                    PICKUP DATE
                  </Typography>
                  <Typography variant="bodyMediumMedium" color="#667085">
                    {formattedDate(
                      pickup_request?.pickup_date || '',
                      'DD-MM-YYYY'
                    )}
                  </Typography>
                </Stack>
              </Stack>
              {productReceipt.is_last_owner && (
                <Box
                  sx={{
                    padding: '.5rem .75rem',
                    background:
                      'linear-gradient(180deg, #3E7DF8 0%, #3974E7 100%)',
                    borderRadius: '.5rem',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '.5rem',
                    width: '11rem'
                  }}
                  onClick={() => setShowPickupCode(!showPickupCode)}
                >
                  <SvgWrapper
                    icon={showPickupCode ? Eye : EyeClosed}
                    width="1rem"
                    height="1rem"
                    styleOverrides={{ fill: 'none', stroke: '#fff' }}
                  />

                  <Typography variant="bodyMediumSemibold" color="#fff">
                    {showPickupCode
                      ? pickup_request?.pickup_code?.code
                      : 'View pickup code'}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Paper>
      )}

      {(productReceipt.is_merchant || productReceipt.is_last_owner) && (
        <Stack
          sx={{
            width: 'inherit',
            backgroundColor: '#FFF',
            padding: '.5rem',
            borderRadius: '.75rem'
          }}
          direction="row"
          gap=".5rem"
          justifyContent="space-between"
        >
          <PickupAgentDetails
            agentPhoto={pickup_request?.pickup_agent?.profile_picture || null}
            agentName={pickup_request?.pickup_agent?.firstname || '---'}
            agentPhoneNumber={
              pickup_request?.pickup_agent?.phone_number || '---'
            }
            agentEmail={pickup_request?.pickup_agent?.email || '---'}
            pickupDate={pickup_request?.pickup_date}
            onReschedule={() => console.log('hey')}
          />
          <PickupVehicleDetails
            vehiclePhoto={pickup_request?.pickup_agent?.vehicle_photo || null}
            vehicleMake={pickup_request?.pickup_agent?.vehicle_make || '---'}
            vehicleModel={pickup_request?.pickup_agent?.vehicle_model || '---'}
            vehicleMarker={'---'}
            vehicleLicense={
              pickup_request?.pickup_agent?.vehicle_plate_number || '---'
            }
          />
        </Stack>
      )}
    </Box>
  );
};

export default PickupRequestDetails;
