import React, { useMemo } from 'react';
import { Box, Typography, Stack, Avatar } from '@mui/material';

import { Table } from 'components/table';

import { createColumnHelper } from '@tanstack/react-table';
import type { ColumnDef } from '@tanstack/react-table';
import { useAppSelector } from 'services/hook';
import { useGetMerchantsRegisteredToDistributorQuery } from 'services/api/distributorManagementApi';
import { Merchant } from '../types';
import { formattedDate } from 'utilities/helperFunc/formatter';

const columnHelper = createColumnHelper<Merchant>();
const columns = [
  columnHelper.accessor('merchant', {
    header: 'merchant',
    cell: info => (
      <Stack display="flex" flexDirection="row">
        <Avatar
          src={`${info.getValue().logo}`}
          sx={{
            width: '2rem',
            height: '2rem',
            mr: '0.75rem',
            justifyContent: 'center',
            borderRadius: '12.5rem'
          }}
        />
        <Stack display="flex" justifyContent="center">
          <Typography variant="bodyMediumMedium" color="#475467">
            {info.getValue().name}
          </Typography>
        </Stack>
      </Stack>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('public_id', {
    header: 'public id',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#475467">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('registered', {
    header: 'registered',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#98A2B3">
        {formattedDate(`${info.getValue()}`, 'MMM dd, yyyy')}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  })
] as ColumnDef<Merchant>[];
// Cast is a workaround due to issues with tansatck table
// See https://github.com/TanStack/table/issues/4302

const MerchantsRegisteredToDistributor = () => {
  const { supplyChainProfileId } = useAppSelector(state => state.auth);

  const { data: merchants, isLoading: isLoadingMerchants } =
    useGetMerchantsRegisteredToDistributorQuery(supplyChainProfileId);

  const formattedMerchants = useMemo(() => {
    if (!merchants) return [];

    return merchants.map((merchant: Merchant) => ({
      merchant: {
        name: merchant.name,
        logo: merchant.logo
      },
      public_id: merchant.public_id,
      registered: merchant.registered
    }));
  }, [merchants]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '1.75rem'
        }}
      >
        <Stack gap=".25rem" width="inherit">
          <Typography variant="h6Bold" color="#475467">
            Registered Merchants
          </Typography>
          <Typography variant="bodyMediumMedium" color="#98A2B3">
            View and manage all the merchants you are registered with.
          </Typography>
        </Stack>
      </Box>
      <Table<Merchant>
        hover
        columns={columns}
        data={formattedMerchants}
        onClickRow={(rowData: Merchant) => {
          console.log(`Hi from row ${rowData.merchant}`);
        }}
        enableFilter={false}
        filterColumns={['name']}
        searchPlaceholderText="Search for Merchants"
        loadingTable={isLoadingMerchants}
      />
    </Box>
  );
};

export default MerchantsRegisteredToDistributor;
