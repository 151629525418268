import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import { Row, flexRender } from '@tanstack/react-table';
import { IndeterminateCheckbox, OptionsButton } from '../tableButtons';

interface NestedTableProps<TableDataT> {
  row: Row<TableDataT>;
  optionsButton?: boolean;
  hideTableHead?: boolean;
  onClickRowCallback: (rowData: TableDataT) => void;
}

export default function NestedTable<TableDataT>({
  row,
  optionsButton,
  onClickRowCallback,
  hideTableHead
}: NestedTableProps<TableDataT>) {
  return (
    <>
      {row.subRows &&
        row.subRows.map(subRow => {
          return (
            <TableRow
              key={subRow.id}
              style={{
                padding: `${row.depth * 2}rem`,
                background: '#F9FAFC'
              }}
            >
              <TableCell
                sx={{
                  borderBottom: '.0625rem solid #F5F6F7',
                  height: '4.188rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {!hideTableHead && (
                  <IndeterminateCheckbox
                    {...{
                      checked: row.getIsSelected(),
                      disabled: !row.getCanSelect(),
                      indeterminate: row.getIsSomeSelected(),
                      onChange: row.getToggleSelectedHandler()
                    }}
                  />
                )}
              </TableCell>
              {subRow.getVisibleCells().map(cell => {
                const value = cell.getValue();
                return (
                  <TableCell
                    key={cell.id}
                    sx={{
                      borderBottom: '.0625rem solid #F5F6F7'
                    }}
                  >
                    {value === '' || value === null || value === undefined
                      ? '-'
                      : flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                  </TableCell>
                );
              })}
              {optionsButton && (
                <TableCell
                  sx={{
                    borderBottom: '1px solid #F5F6F7',
                    marginRight: '15px',
                    right: '0px',
                    opacity: 0.95,
                    position: 'sticky',
                    width: '50px',
                    zIndex: 1,
                    backgroundColor: '#fff',
                    cursor: 'pointer'
                  }}
                  onClick={() => onClickRowCallback(row.original)}
                >
                  <OptionsButton />
                </TableCell>
              )}
            </TableRow>
          );
        })}
    </>
  );
}
