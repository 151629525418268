import {
  CircularProgress,
  Button as MUIButton,
  SvgIcon,
  Typography
} from '@mui/material';
import React from 'react';
import type { SxProps, ButtonProps as MUIButtonProps } from '@mui/material';
import _ from 'lodash';
import { shadowsTheme } from 'themes/shadows';
import { colorTheme } from 'themes/colors';

export interface ButtonProps {
  color: 'primary' | 'grey' | 'success' | 'warning' | 'error';
  outline?: boolean;
  transparent?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  onClick?: MUIButtonProps['onClick'];
  styleOverrides?: SxProps;
  disabled?: boolean;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  iconPosition?: 'start' | 'end';
  text?: string;
  width?: string;
  submit?: boolean;
  useIconStroke?: boolean;
  useIconFill?: boolean;
  loadingIcon?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  color,
  outline,
  transparent,
  size,
  onClick,
  disabled,
  styleOverrides,
  icon,
  iconPosition,
  text,
  width,
  submit,
  useIconStroke,
  useIconFill,
  loadingIcon
}) => {
  // Make sure outline and transparent are not passed together
  if (outline && transparent) {
    throw new Error('Buttons cannot be both outline and transparent!');
  }

  const getStyleForSizeProp = (size: ButtonProps['size']) => {
    if (size === 'xs') {
      return {
        height: '2rem',
        padding: '0.25rem'
      };
    } else if (size === 'sm') {
      return {
        height: '2.25rem',
        padding: '0.5rem'
      };
    } else if (size === 'md') {
      return {
        height: '2.75rem',
        padding: '0.75rem'
      };
    } else {
      return {
        height: '3rem',
        padding: '0.75rem'
      };
    }
  };

  const getLoadingIconStyleForSizeProp = (size: ButtonProps['size']) => {
    if (size === 'xs') {
      return '.8rem';
    } else if (size === 'sm') {
      return '1rem';
    } else if (size === 'md') {
      return '1.2rem';
    } else {
      return '1.5rem';
    }
  };

  const getStyleForProps = (
    color: ButtonProps['color'],
    outline: ButtonProps['outline'],
    transparent: ButtonProps['transparent']
  ) => {
    return {
      // Outline prop
      ...(outline && { border: `1.5px solid ${colorTheme[`${color}600`]}` }),
      // Transparent prop
      ...(transparent && { border: `1.5px solid ${colorTheme[`${color}50`]}` }),
      backgroundColor:
        outline || transparent ? '#FFF' : colorTheme[`${color}600`],
      borderRadius: '0.5rem',
      boxShadow: shadowsTheme.buttonShadow,
      color: outline || transparent ? colorTheme[`${color}600`] : '#FFF',
      '&:hover': {
        backgroundColor:
          outline || transparent
            ? colorTheme[`${color}50`]
            : colorTheme[`${color}400`],
        boxShadow: shadowsTheme.xSmallShadowSoft,
        ...(outline && {
          border: `1.5px solid ${colorTheme[`${color}200`]}`,
          color: colorTheme[`${color}400`]
        })
      },
      '&:active': {
        backgroundColor:
          outline || transparent
            ? colorTheme[`${color}50`]
            : colorTheme[`${color}700`],
        ...(outline && {
          border: `1.5px solid ${colorTheme[`${color}500`]}`,
          color: colorTheme[`${color}500`]
        })
      },
      '&:disabled': {
        backgroundColor:
          outline || transparent ? '#FAFAFA' : colorTheme[`${color}100`],
        color: outline || transparent ? colorTheme[`${color}200`] : '#FFF',
        ...(outline && { border: `1.5px solid ${colorTheme[`${color}200`]}` })
      }
    };
  };

  const getIconStyleForProps = (
    size: ButtonProps['size'],
    color: ButtonProps['color'],
    outline: ButtonProps['outline'],
    transparent: ButtonProps['transparent']
  ) => {
    // Use IconStroke by default if neither stroke nor fill is defined
    let useIconStrokeDefault = false;
    if (useIconStroke === undefined && useIconFill === undefined) {
      useIconStrokeDefault = true;
    }
    return {
      width: size === 'sm' || size === 'md' ? '1rem' : '1.25rem',
      height: size === 'sm' || size === 'md' ? '1rem' : '1.25rem',
      ...(useIconFill
        ? {
            fill: outline || transparent ? colorTheme[`${color}600`] : '#FFF'
          }
        : { fill: 'none' }),
      ...(useIconStroke || useIconStrokeDefault
        ? {
            stroke: disabled
              ? '#BDBDBD'
              : outline || transparent
                ? colorTheme[`${color}600`]
                : '#FFF'
          }
        : { stroke: 'none' })
    };
  };

  const style = {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...(width && { width: width }),
    ...getStyleForSizeProp(size),
    ...getStyleForProps(color, outline, transparent)
  };

  // If style overrides are passed then use to override existing styles
  if (styleOverrides) {
    _.assign(style, styleOverrides);
  }

  // Override icon styles if provided
  const iconStyle = {
    ...getIconStyleForProps(size, color, outline, transparent)
  };

  // Compute extra conditional props for button
  const conditionalProps: Partial<MUIButtonProps> = {};
  if (submit) {
    conditionalProps.type = 'submit';
  }

  /**
   * Note that if you find that button color styles are not being applied to an icon
   * you want for this button make sure that the stroke/fill attributes are set on the
   * top level <svg> elements rather than the <path> elements.
   *
   * If you need to set an icon that is multicolored on this button then probably you want
   * to refactor this so it accepts custom icons but you will be responsible for making sure
   * the icon color follows whatever button variant you need.
   */
  return (
    <MUIButton
      {...conditionalProps}
      disabled={disabled}
      disableRipple
      disableElevation
      sx={style}
      onClick={onClick}
    >
      {loadingIcon && (
        <CircularProgress
          size={getLoadingIconStyleForSizeProp(size)}
          sx={{
            color: 'inherit',
            mr: '.25rem'
          }}
        />
      )}
      {icon && iconPosition === 'start' && !loadingIcon && (
        <SvgIcon
          component={icon}
          sx={{
            marginRight: '0.25rem',
            ...iconStyle
          }}
        />
      )}
      {text && (
        <Typography
          variant={
            size === 'xs'
              ? 'bodySmallSemibold'
              : size === 'sm' || size === 'md'
                ? 'bodyMediumSemibold'
                : 'bodyLargeSemibold'
          }
          component="div"
          color="inherit"
        >
          {text}
        </Typography>
      )}
      {icon && iconPosition === 'end' && (
        <SvgIcon
          component={icon}
          sx={{
            marginLeft: '0.25rem',
            ...iconStyle
          }}
        />
      )}
    </MUIButton>
  );
};

export default Button;
