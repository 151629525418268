import React from 'react';
import { Box, Typography, Stack } from '@mui/material';

const DesktopOnlyPage = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Stack alignItems="center" gap={'.25rem'} sx={{ textAlign: 'center' }}>
        <Box
          sx={{
            borderRadius: '6.25rem',
            border: '0.0625rem solid #F2F4F7',
            backgroundColor: '#EAECF0',
            boxShadow:
              '0rem 0.09375rem 0.25rem -0.0625rem rgba(182, 182, 182, 0.07',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography
            variant="captionSmall"
            sx={{
              background:
                'linear-gradient(180deg, rgba(62, 125, 248, 0.50) 36.42%, rgba(31, 160, 209, 0.50) 71.08%)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              padding: '0.5rem'
            }}
          >
            Available on Desktop Only
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 700,
            lineHeight: '148%',
            letterSpacing: '-0.025rem',
            fontStyle: 'normal',
            fontFamily: 'Inter',
            color: '#475467'
          }}
        >
          Experience the full power of Flux on your computer
        </Typography>
      </Stack>
    </Box>
  );
};

export default DesktopOnlyPage;
