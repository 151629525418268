import React, { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

const ProductReceiptInfoTabLayout = ({ children }: PropsWithChildren) => {
  return (
    <Box
      sx={{
        display: 'flex',
        padding: '1rem',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem',
        minHeight: '13.3rem',
        borderRadius: '0.75rem',
        backgroundColor: '#FCFCFD'
      }}
    >
      {children}
    </Box>
  );
};

export default ProductReceiptInfoTabLayout;
