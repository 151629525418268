import React from 'react';
import waves from 'assets/svg/waves.svg';
import { Box } from '@mui/material';
import { theme } from 'themes/theme';
import OnboardingContextWrapper from 'pages/onboarding/context/onboardingContext';
import OnboardingNav from 'pages/onboarding/common/onboardingNav';

const OnboardingLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={{
        height: '100%',
        backgroundImage: `url(${waves})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: `${theme.palette.common.layoutBackground}`
      }}
    >
      <OnboardingContextWrapper>
        <OnboardingNav />
        {children}
      </OnboardingContextWrapper>
    </Box>
  );
};

export default OnboardingLayout;
