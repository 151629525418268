import { Box, Typography } from '@mui/material';
import React from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as Check } from 'assets/svg/checkSolid.svg';
import { ReactComponent as X } from 'assets/svg/x.svg';
import { ReactComponent as Error } from 'assets/svg/infoError.svg';
import { ReactComponent as Primary } from 'assets/svg/infoPrimary.svg';
import { ReactComponent as Warning } from 'assets/svg/infoWarning.svg';
import { SvgWrapper } from 'components/svg';
import { Button } from 'components/button';

interface ToastOptions {
  message: string;
  subText?: string;
  hideProgressBar?: boolean;
  autoClose?: number;
  type: 'primary' | 'success' | 'warning' | 'error';
  componentType?: 'toast' | 'banner';
}

const ShowInfo = ({
  message,
  subText,
  type,
  hideProgressBar = true,
  autoClose = 5000,
  componentType
}: ToastOptions) => {
  let iconComponent: React.FC<React.SVGProps<SVGSVGElement>>;
  let iconFill: string;
  let iconBorderColor: string;
  let iconBackgroundColor: string;
  let borderLeftColor = '';

  if (type == 'success') {
    iconComponent = Check;
    iconFill = '#19956C';
    iconBorderColor = '#B9E3D5';
    iconBackgroundColor = '#F7FCFA';
    borderLeftColor = '#1CA477';
  } else if (type == 'primary') {
    iconComponent = Primary;
    iconFill = '#3E7DF8';
    iconBorderColor = '#C3D7FD';
    iconBackgroundColor = '#ECF2FE';
    borderLeftColor = '#3E7DF8';
  } else if (type == 'warning') {
    iconComponent = Warning;
    iconFill = '#FAD17E';
    iconBorderColor = '#FDEAC3';
    iconBackgroundColor = '#FEF8EC';
    borderLeftColor = '#F8BA3F';
  } else if (type == 'error') {
    iconComponent = Error;
    iconFill = '#E1343F';
    iconBorderColor = '#FCEBEC';
    iconBackgroundColor = '#FFF7F6';
    borderLeftColor = '#E1343F';
  }

  const Toast: React.FC<{ message: string }> = ({ message }) => (
    <Box display="flex" flexDirection="row">
      <Box
        mr="1rem"
        sx={{
          display: 'flex',
          width: '1.5rem',
          height: '1.5rem',
          padding: '0.375rem',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '0.5rem',
          border: `0.063rem solid ${iconBorderColor}`,
          background: iconBackgroundColor
        }}
      >
        <SvgWrapper
          icon={iconComponent}
          width="0.75rem"
          height="0.75rem"
          styleOverrides={{ fill: iconFill }}
        />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="bodySmallSemibold" color="#344054">
          {message}
        </Typography>
        <Typography variant="bodySmallSemibold" color="#667085">
          {subText}
        </Typography>
        {componentType === 'banner' && (
          <Box display="flex" flexDirection="row" mt="0.5rem">
            <Button
              color={type}
              size="xs"
              text="Primary CTA"
              width="6.3rem"
              styleOverrides={{
                mr: '0.5rem'
              }}
            />
            <Button
              color="grey"
              size="xs"
              text="Secondary CTA"
              width="6.3rem"
            />
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="row" ml="auto">
        <Box ml="0.25rem">
          <SvgWrapper icon={X} width="1.25rem" height="1.25rem" />
        </Box>
      </Box>
    </Box>
  );

  toast(<Toast message={message} />, {
    style: {
      borderLeft: `0.313rem solid ${borderLeftColor}`
    },
    progressStyle: {
      background: borderLeftColor,
      borderBottomLeftRadius: '0rem'
    },
    hideProgressBar: hideProgressBar,
    autoClose: autoClose,
    toastId: message
  });

  return null;
};

export default ShowInfo;
