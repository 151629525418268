import React from 'react';

import { Box, Checkbox as MUICheckbox, SvgIcon } from '@mui/material';

export const UnChecked = () => {
  return (
    <SvgIcon inheritViewBox>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <rect
          x="0.5"
          y="0.5"
          width="19"
          height="19"
          rx="5.5"
          fill="white"
          stroke="#EAECF0"
        />
      </svg>
    </SvgIcon>
  );
};

export const Checked = () => {
  return (
    <SvgIcon inheritViewBox>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="20" height="20" rx="4" fill="#2563EB" />
        <path
          d="M6.11133 9.99995L8.88911 12.7777L14.4447 7.22217"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export function IndeterminateCheckbox({
  indeterminate,
  checked,
  disabled,
  onChange
}: {
  indeterminate?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onChange: (event: unknown) => void;
}) {
  const ref = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean' && ref.current) {
      ref.current.indeterminate = !checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <MUICheckbox
      inputRef={ref}
      sx={{
        cursor: 'pointer',
        width: '20px',
        height: '20px',
        borderRadius: '10px'
      }}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      icon={<UnChecked />}
      checkedIcon={<Checked />}
    />
  );
}

export function OptionsButton() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#FFF',
        '&:hover': {
          background: '#F5F6F7',
          cursor: 'pointer',
          borderRadius: '8px',
          borderColor: '#F5F6F7'
        }
      }}
    >
      <SvgIcon inheritViewBox>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.66667 7.99984C6.66667 7.26346 7.26362 6.6665 8 6.6665C8.73638 6.6665 9.33333 7.26346 9.33333 7.99984C9.33333 8.73622 8.73638 9.33317 8 9.33317C7.26362 9.33317 6.66667 8.73622 6.66667 7.99984Z"
            fill="#475467"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.3333 7.99984C11.3333 7.26346 11.9303 6.6665 12.6667 6.6665C13.403 6.6665 14 7.26346 14 7.99984C14 8.73622 13.403 9.33317 12.6667 9.33317C11.9303 9.33317 11.3333 8.73622 11.3333 7.99984Z"
            fill="#475467"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 7.99984C2 7.26346 2.59695 6.6665 3.33333 6.6665C4.06971 6.6665 4.66667 7.26346 4.66667 7.99984C4.66667 8.73622 4.06971 9.33317 3.33333 9.33317C2.59695 9.33317 2 8.73622 2 7.99984Z"
            fill="#475467"
          />
        </svg>
      </SvgIcon>
    </Box>
  );
}
