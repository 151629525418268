import React from 'react';
import { SxProps, Box } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  styleOverrides?: SxProps;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  styleOverrides
}) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      sx={{ width: '100%', ...styleOverrides }}
    >
      {value === index && (
        <Box
          sx={{
            height: 'inherit',
            position: 'relative'
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default TabPanel;
