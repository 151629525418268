import React from 'react';
import { ReactComponent as Empty } from 'assets/custom-svg/empty.svg';
import { ButtonProps, Stack, Typography, Box } from '@mui/material';
import { Button } from 'components/button';
import { SvgWrapper } from 'components/svg';

interface NotFoundProps {
  title?: string;
  subtitle?: string;
  btnText?: string;
  btnIcon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  btnIconPosition?: 'start' | 'end';
  onClick?: ButtonProps['onClick'];
  loading?: boolean;
}

const NotFound = ({
  title,
  subtitle,
  btnText,
  btnIcon,
  btnIconPosition,
  onClick,
  loading
}: NotFoundProps) => {
  return (
    <Stack
      direction="column"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <SvgWrapper icon={Empty} width="5rem" height="5rem" />

      <Stack
        direction="column"
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt=".5rem"
      >
        {title && (
          <Typography variant="h7Semibold" color="#475467">
            {title}
          </Typography>
        )}

        {subtitle && (
          <Box width="16rem" textAlign="center" mb=".5rem">
            <Typography variant="bodyMediumMedium" color="#98A2B3">
              {subtitle}
            </Typography>
          </Box>
        )}

        {btnText && (
          <Button
            color="grey"
            transparent
            size="md"
            text={btnText}
            icon={btnIcon}
            iconPosition={btnIconPosition}
            styleOverrides={{ padding: '0.5rem 0.75rem' }}
            onClick={onClick}
            loadingIcon={loading}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default NotFound;
