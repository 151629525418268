import React, { PropsWithChildren, ReactNode, useState } from 'react';
import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';
import {
  AccordionDetails,
  AccordionSummary,
  Accordion as MuiAccordion
} from '@mui/material';

import { SvgWrapper } from 'components/svg';

interface AccordionProps {
  summary: React.ReactNode;
  expandIcon?: ReactNode;
  closeIcon?: ReactNode;
  disabled?: boolean;
}

const Accordion: React.FC<PropsWithChildren<AccordionProps>> = ({
  children,
  summary,
  expandIcon,
  closeIcon,
  disabled
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, expanded: boolean) => {
    setExpanded(expanded);
  };

  const iconWithDefault = expandIcon ? (
    expandIcon
  ) : (
    <SvgWrapper
      icon={ChevronDown}
      width="1.25rem"
      height="1.25rem"
      styleOverrides={{ fill: 'none', stroke: '#667085' }}
    />
  );

  return (
    <MuiAccordion
      square={true}
      disabled={disabled}
      expanded={expanded}
      onChange={handleChange}
      sx={{
        '&.MuiPaper-root': {
          border: 'none',
          boxShadow: 'none',
          backgroundColor: 'transparent'
        }
      }}
    >
      <AccordionSummary
        expandIcon={expanded && closeIcon ? closeIcon : iconWithDefault}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails
        sx={{ borderTop: '0.094rem solid  #F2F4F7', padding: '0rem 1rem' }}
      >
        {children}
      </AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
