import React from 'react';
import ErrorNav from 'pages/error/errorNav';
import waves from 'assets/svg/waves.svg';
import { theme } from 'themes/theme';
import { Box } from '@mui/material';

const ErrorLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundImage: `url(${waves})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: `${theme.palette.common.layoutBackground}`
      }}
    >
      <ErrorNav />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: '1'
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ErrorLayout;
