import { ProductApiData } from 'services/types/product';
import { api } from './baseApi';

export const productApi = api.injectEndpoints({
  endpoints: builder => ({
    getProducts: builder.query<ProductApiData[], unknown>({
      query: () => ({
        url: 'supply_chain/({supplyChainProfileId})/products/',
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      providesTags: ['products']
    }),
    createProduct: builder.mutation({
      query: ({ data }) => ({
        url: 'supply_chain/({supplyChainProfileId})/products/',
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: data
      }),
      invalidatesTags: ['products']
    })
  }),
  overrideExisting: false
});

export const { useGetProductsQuery, useCreateProductMutation } = productApi;
