/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, createContext, ReactNode } from 'react';
import IdVerification from 'pages/onboarding/steps/idVerification';
import Invite from 'pages/onboarding/steps/invite';
import ProductInformation from 'pages/onboarding/steps/productInformation';
import SelectUser from 'pages/onboarding/steps/selectUser';
import SetupOrganization from 'pages/onboarding/steps/setupOrganization';
import { useActivateSupplyChainProfileMutation } from 'services/api/supplyChainApi';
import { useAppSelector } from 'services/hook';
import {
  useCreateOrganizationMutation,
  useAddMemberMutation
} from 'services/api/organizationApi';
import { axiosConfigHelper } from 'utilities/api';
import { toast } from 'react-toastify';
import axios, { AxiosError } from 'axios';
import { MyError } from 'pages/auth/resetPasswordPage';

export type OnboardingData = {
  organization_type: string;
  product: string[];
  otherProduct: string;
  file: File[] | null;
  logo: File | null;
  business_name: string;
  street_address: string;
  postal_code: string;
  state: string;
  industry: string;
  locality: string;
  country: string;
  administrative_area: string;
  emails: string[];
};

const initialValues: OnboardingData = {
  organization_type: '',
  product: [],
  otherProduct: '',
  file: null || [],
  logo: null,
  business_name: '',
  street_address: '',
  postal_code: '',
  state: '',
  industry: '',
  locality: '',
  country: '',
  administrative_area: '',
  emails: []
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OnboardingContext = createContext<any>(null);

let step = 0;

const OnboardingContextWrapper: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => {
  const [data, setData] = useState<OnboardingData>(initialValues);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [uploadedFile, setUploadedFile] = useState<null | File[]>(null);
  const [preview, setPreview] = useState<File | null>(null);
  const [showHighLight, setShowHighLight] = useState<string[]>([]);
  const [changeBtn, setChangeBtn] = useState(false);
  const [organizationId, setOrganizationId] = useState('');
  const [activateSupplyChainProfile] = useActivateSupplyChainProfileMutation();
  const [createOrganization] = useCreateOrganizationMutation();
  const [inviteMember] = useAddMemberMutation();

  const steps = [
    { element: <SelectUser /> },
    { element: <ProductInformation /> },
    { element: <IdVerification /> },
    { element: <SetupOrganization /> },
    { element: <Invite /> }
  ];

  useEffect(() => {
    step++;
    if (step > 5 && data.emails.length > 0) {
      FinalSubmission(
        data,
        organizationId,
        activateSupplyChainProfile,
        createOrganization,
        inviteMember,
        setOrganizationId
      );
    } else if (step > 5 && data.business_name !== '') {
      FinalSubmission(
        data,
        organizationId,
        activateSupplyChainProfile,
        createOrganization,
        inviteMember,
        setOrganizationId
      );
    }
  }, [data]);

  const handleNextStep = (newData: Partial<OnboardingData>) => {
    setData(prev => ({ ...prev, ...newData }));
    setCurrentStep(i => {
      if (i >= steps.length - 1) return i;
      return i + 1;
    });
  };

  const handlePrevStep = () => {
    setCurrentStep(i => {
      if (i <= 0) return i;
      return i - 1;
    });
  };

  const goToStep = (index: number) => {
    setCurrentStep(index);
  };

  const values = {
    data,
    setData,
    currentStep,
    uploadedFile,
    setUploadedFile,
    preview,
    setPreview,
    showHighLight,
    setShowHighLight,
    changeBtn,
    setChangeBtn,
    steps,
    handleNextStep,
    handlePrevStep,
    goToStep
  };

  return (
    <OnboardingContext.Provider value={values}>
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingContextWrapper;
interface ErrorResponse {
  [key: string]: string | string[] | ErrorResponse;
}

function constructErrorMessage(errorObj: ErrorResponse): string {
  let errorMessage = 'Validation failed:';
  for (const key in errorObj) {
    if (Object.prototype.hasOwnProperty.call(errorObj, key)) {
      const value = errorObj[key];
      if (typeof value === 'string') {
        errorMessage += `\n- ${key}: ${value}`;
      } else if (Array.isArray(value)) {
        errorMessage += `\n- ${key}: ${value.join(', ')}`;
      } else if (typeof value === 'object') {
        errorMessage += `\n- ${key}: ${constructErrorMessage(value as ErrorResponse)}`;
      }
    }
  }
  return errorMessage;
}

const FinalSubmission = async (
  data: OnboardingData,
  organizationId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activateSupplyChainProfile: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createOrganization: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inviteMember: any,
  setOrganizationId: React.Dispatch<React.SetStateAction<string>>
) => {
  const callSupplyChainProfile = async (organizationId: string) => {
    try {
      const response = await axios.post(
        `supply_chain/(${organizationId})/profiles/`,
        { organization_type: data.organization_type },
        axiosConfigHelper({ apiVersion: 'v1', contentType: 'application/json' })
      );
      toast.success('Supply chain profile created.', {
        theme: 'colored'
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorMessage = constructErrorMessage(error.response.data);
      toast.error(errorMessage);
    } finally {
      if ('emails' in data && organizationId) {
        await inviteMember({
          data: { emails: data.emails },
          organizationId
        });
      }
    }
  };

  if (
    'postal_code' in data &&
    'locality' in data &&
    'street_address' in data &&
    'business_name' in data
  ) {
    try {
      const response = await axios.post(
        'organizations/',
        {
          name: data.business_name,
          location: {
            street_address: data.street_address,
            locality: data.locality,
            country: 'NG',
            postal_code: data.postal_code,
            administrative_area: data.locality
          }
        },
        axiosConfigHelper({ apiVersion: 'v1', contentType: 'application/json' })
      );
      setOrganizationId(response.data.organization_id);
      callSupplyChainProfile(response.data.organization_id);
      toast.success('Organization created.', {
        theme: 'colored'
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorMessage = constructErrorMessage(error.response.data);
      toast.error(errorMessage);
    }
  }
};
