import React, { useState } from 'react';
import { Box, List, ListItem, Stack, SxProps, Typography } from '@mui/material';
import { SearchBar } from 'components/search';
import Fuse, { FuseOptionKeyObject } from 'fuse.js';
import _ from 'lodash';
import { ReactComponent as Empty } from 'assets/custom-svg/table-empty-state.svg';
import { SvgWrapper } from 'components/svg';

interface SearchableContainerProps<SearchableDataT> {
  searchPlaceholderText: string;
  searchField: FuseOptionKeyObject<unknown> | string | string[];
  data: SearchableDataT[];
  containerStyles?: SxProps;
  listStylesOverride?: SxProps;
  renderDataList: (item: SearchableDataT, index?: number) => React.ReactNode;
  title?: string;
  subtitle?: string;
  loading?: boolean;
  enableEmptyData?: boolean;
}

export default function SearchableContainer<SearchableDataT>({
  searchPlaceholderText,
  data,
  searchField,
  containerStyles,
  renderDataList,
  listStylesOverride,
  title,
  subtitle,
  loading,
  enableEmptyData
}: SearchableContainerProps<SearchableDataT>) {
  const [currentSearchText, setCurrentSearchText] = useState('');
  const fuseOptions: { [key: string]: unknown } = {
    threshold: 0.3,
    keys: searchField
  };

  const fuse = new Fuse(data, fuseOptions);
  const filteredItems = fuse.search(currentSearchText);

  const style = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%'
  };

  // If container styles are passed then use to override existing styles
  if (containerStyles) {
    _.assign(style, containerStyles);
  }

  const listStyle = {
    width: '100%'
  };

  // If list styles override are passed then use to override existing list style
  if (listStylesOverride) {
    _.assign(listStyle, listStylesOverride);
  }

  return (
    <Stack direction="column" spacing={1} width="100%">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        {title && (
          <Box
            sx={{
              justifyContent: 'center',
              alignItems: 'start',
              flexWrap: 'wrap',
              flexDirection: 'column',
              display: 'flex'
            }}
          >
            <Typography variant="bodyLargeSemibold" color="#475467">
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="bodyMediumMedium" color="#98A2B3">
                {subtitle}
              </Typography>
            )}
          </Box>
        )}
        <Box
          sx={{
            width: title ? '20rem' : 'inherit'
          }}
        >
          <SearchBar
            size="sm"
            id="search"
            options={[]}
            onChange={value => {
              setCurrentSearchText(value);
            }}
            placeholderText={searchPlaceholderText}
          />
        </Box>
      </Box>
      {enableEmptyData && !loading && data.length === 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '6rem 0rem'
          }}
        >
          <SvgWrapper
            height="4.25rem"
            width="4.25rem"
            icon={Empty}
            styleOverrides={{ fill: 'none', margin: '0 auto' }}
          />

          <Typography variant="bodyMediumSemibold" color="#475467">
            No Data Found
          </Typography>
        </Box>
      )}
      {(!enableEmptyData || (enableEmptyData && data.length > 0)) && (
        <Box sx={style}>
          <List sx={listStyle}>
            {currentSearchText === ''
              ? data.map((item, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      padding: '0rem',
                      width: listStylesOverride ? 'auto' : '100%'
                    }}
                  >
                    {renderDataList(item, index)}
                  </ListItem>
                ))
              : filteredItems.map((obj, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      padding: '0rem',
                      width: listStylesOverride ? 'auto' : '100%'
                    }}
                  >
                    {renderDataList(obj.item)}
                  </ListItem>
                ))}
          </List>
        </Box>
      )}
    </Stack>
  );
}
