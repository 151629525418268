import React from 'react';
import { Checkbox, FormControlLabel, SvgIcon } from '@mui/material';
import { Controller } from 'react-hook-form';
import { fontFamily2, theme } from 'themes/theme';
import { FormControlProps } from 'components/form/common/formTypes';
import type { FieldValues } from 'react-hook-form';

interface CheckedProps {
  disabled?: boolean;
}

export const Checked = ({ disabled = false }: CheckedProps) => {
  return (
    <SvgIcon inheritViewBox>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="20"
          height="20"
          rx="4"
          fill={disabled ? '#667085' : '#2563EB'}
        />
        <path
          d="M6.11133 9.99995L8.88911 12.7777L14.4447 7.22217"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
export const UnChecked = () => {
  return (
    <SvgIcon inheritViewBox>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Type=Checkbox, Active=disabled, Size=large">
          <rect
            id="Outer Rectangle"
            x="0.5"
            y="0.5"
            width="23"
            height="23"
            rx="5.5"
            fill="white"
            stroke="#EAECF0"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default function FormCheckBox<FormValueT extends FieldValues>(
  props: FormControlProps<FormValueT>
) {
  const { label, name, control, onChange: onChangeCallBack } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          label={label}
          name={name}
          control={
            <Checkbox
              {...field}
              size="medium"
              sx={{
                width: '1.5rem',
                height: '1.5rem',
                borderRadius: '0.25rem'
              }}
              checkedIcon={<Checked />}
              icon={<UnChecked />}
              checked={field.value}
              onChange={value => {
                field.onChange(value);
                if (onChangeCallBack) {
                  onChangeCallBack();
                }
              }}
            />
          }
          sx={{
            margin: 0,
            textAlign: 'center',
            '.MuiFormControlLabel-label': {
              ml: '.62rem',
              color: `${theme.palette.common.labelColor}`,
              fontFamily: fontFamily2,
              fontSize: '0.875rem',
              fontWeight: 500,
              lineHeight: '1.225rem',
              letterSpacing: '-0.01313rem'
            }
          }}
        />
      )}
    />
  );
}
