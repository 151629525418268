import React, { useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import NotFound from '../notFound';
import PickupRequestContextWrapper from './requests/pickupRequestContext';
import PickupRequestDetails from './requests/pickupRequestDetails';
import PickupRequestModal from './requests/pickupRequestModal';
import { ProductReceiptApiData } from 'services/types/productReceipt';
import SchedulePickupContextWrapper from './scheduling/schedulePickupContext';
import SchedulePickupForm from './scheduling/schedulePickupForm';
import SchedulePickupModal from './scheduling/schedulePickupModal';
import ValidatePickupCodeModal from './pickup-code/validatePickupCodeModal';
import ValidatePickupCodeForm from './pickup-code/validatePickupCodeForm';

const ProductReceiptPickup = ({
  productReceipt
}: {
  productReceipt: ProductReceiptApiData;
}) => {
  const [pickupRequestModalOpen, setPickupRequestModalOpen] =
    useState<boolean>(false);
  const [validatePickupCodeModalOpen, setValidatePickupCodeModalOpen] =
    useState<boolean>(false);
  const [schedulePickupModalOpen, setSchedulePickupModalOpen] =
    useState<boolean>(false);

  return (
    <>
      <PickupRequestContextWrapper
        setPickupRequestModalOpen={setPickupRequestModalOpen}
      >
        {productReceipt.pickup_request ? (
          <PickupRequestDetails
            setSchedulePickupModalOpen={setSchedulePickupModalOpen}
            setValidatePickupCodeModalOpen={setValidatePickupCodeModalOpen}
            productReceipt={productReceipt}
          />
        ) : productReceipt.is_last_owner ? (
          <NotFound
            title="Pickup Request"
            subtitle="Use your product receipts to pick up products from merchants"
            btnIcon={PlusIcon}
            btnIconPosition="start"
            btnText="Request Pickup"
            onClick={() => {
              setPickupRequestModalOpen(!pickupRequestModalOpen);
            }}
          />
        ) : (
          <NotFound
            title="Pickup Request Pending"
            subtitle="Waiting for customer to initiate pickup request"
          />
        )}
        <PickupRequestModal
          open={pickupRequestModalOpen}
          onClose={() => {
            setPickupRequestModalOpen(!pickupRequestModalOpen);
          }}
        />
      </PickupRequestContextWrapper>

      <SchedulePickupContextWrapper
        setSchedulePickupModalOpen={setSchedulePickupModalOpen}
      >
        <SchedulePickupModal
          open={schedulePickupModalOpen}
          onClose={() => {
            setSchedulePickupModalOpen(!schedulePickupModalOpen);
          }}
        >
          <SchedulePickupForm
            setSchedulePickupModalOpen={setSchedulePickupModalOpen}
            productReceipt={productReceipt}
          />
        </SchedulePickupModal>
      </SchedulePickupContextWrapper>

      <ValidatePickupCodeModal
        open={validatePickupCodeModalOpen}
        onClose={() => {
          setValidatePickupCodeModalOpen(!validatePickupCodeModalOpen);
        }}
      >
        <ValidatePickupCodeForm
          setValidatePickupCodeModalOpen={setValidatePickupCodeModalOpen}
          productReceipt={productReceipt}
        />
      </ValidatePickupCodeModal>
    </>
  );
};

export default ProductReceiptPickup;
