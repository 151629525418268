import { api } from './baseApi';

type EmailData = {
  email: string;
};

type PasswordResetData = {
  reset_id: string;
  new_password: string;
  new_password_repeated: string;
};

type User = {
  firstname: string;
  lastname: string;
};

type Profile = {
  address: string;
  phone_number: string;
  phone_number_validated: boolean;
  profile_picture: string;
};
interface UserData {
  email: string;
  email_validated: boolean;
  firstname: string;
  lastname: string;
  organization: string;
  user_profile: Profile;
  userid: string;
  branch: {
    branch_id: string;
    name: string;
  };
}

export const userApi = api.injectEndpoints({
  endpoints: builder => ({
    getUser: builder.query<UserData, unknown>({
      query: () => ({
        url: 'users/({userId})/',
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      providesTags: ['user']
    }),
    updateUser: builder.mutation({
      query: (body: User) => ({
        url: 'users/({userId})/',
        method: 'patch',
        headers: {
          'Content-Type': 'application/json'
        },
        data: body
      }),
      invalidatesTags: ['user']
    }),
    updateProfile: builder.mutation({
      query: body => ({
        url: 'users/({userId})/profile/',
        method: 'patch',
        headers: {
          'Content-Type': 'application/json'
        },
        data: body
      }),
      invalidatesTags: ['user']
    }),
    updateUserPassword: builder.mutation({
      query: ({ data }) => ({
        url: 'users/({userId})/passwords/change/',
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: ['user']
    }),
    passwordResetRequest: builder.mutation({
      query: (body: EmailData) => {
        return {
          url: 'users/passwords/reset/request/',
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          data: body
        };
      }
    }),
    passwordResetComplete: builder.mutation({
      query: (body: PasswordResetData) => {
        return {
          url: 'users/passwords/reset/complete/',
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          data: body
        };
      }
    })
  }),
  overrideExisting: false
});

export const {
  useUpdateProfileMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useUpdateUserPasswordMutation,
  usePasswordResetRequestMutation,
  usePasswordResetCompleteMutation,
  usePrefetch
} = userApi;
