import React from 'react';
import { Table } from 'components/table';
import { createColumnHelper } from '@tanstack/react-table';
import type { ColumnDef } from '@tanstack/react-table';
import { Typography } from '@mui/material';
import { formattedNumber } from 'utilities/helperFunc/formatter';

interface BatchReceiptData {
  batch_no: string;
  product: string;
  total_value: number;
  subRows?: BatchReceiptData[];
}

const data: BatchReceiptData[] = [
  {
    batch_no: '#1267483',
    product: 'Cement',
    total_value: 200000000,
    subRows: [
      {
        batch_no: '',
        total_value: 200000000,
        product: ''
      }
    ]
  },
  {
    batch_no: '#1267483',
    product: 'Cement',
    total_value: 200000000,
    subRows: [
      {
        batch_no: '',
        total_value: 900,
        product: ''
      }
    ]
  },
  {
    batch_no: '#1267483',
    product: 'Cement',
    total_value: 200000000
  }
];

const columnHelper = createColumnHelper<BatchReceiptData>();
const columns = [
  columnHelper.accessor('batch_no', {
    header: 'BATCH NO',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('product', {
    header: 'PRODUCT',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('total_value', {
    header: 'TOTAL VALUE',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {formattedNumber(info.getValue(), true)}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  })
] as ColumnDef<BatchReceiptData>[];
// Cast is a workaround due to issues with tansatck table
// See https://github.com/TanStack/table/issues/4302

const BatchReceipts = () => {
  return (
    <Table<BatchReceiptData>
      hover
      columns={columns}
      data={data}
      searchPlaceholderText="Search with product"
      enableFilter={false}
      hideTableHead
      onClickRow={rowData => {
        console.log(rowData);
      }}
      filterColumns={[]}
      getRowCanExpand={() => true}
    />
  );
};

export default BatchReceipts;
