import React, { useState } from 'react';
import SearchableContainer from 'components/search/searchableContainer';

import { Avatar, Box, Stack, Typography } from '@mui/material';
import { Drawer } from 'components/drawer';
import { SvgWrapper } from 'components/svg';
import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';
import chartImage from 'assets/png/chart.png';
import LenderDetail from './lenderDetail';

interface DrawerProps {
  open: boolean;
  onClose: () => void;
}

interface Lender {
  name: string;
  profile_picture: string;
  product_receipts: string;
}

const distributors = [
  {
    name: 'Guaranty Trust Bank',
    profile_picture: '',
    product_receipts: '50 Receipts'
  },
  {
    name: 'Guaranty Trust Bank',
    profile_picture: '',
    product_receipts: '50 Receipts'
  }
];

const DistributorLenders: React.FC<DrawerProps> = ({ open, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderData = (item: Lender) => {
    return (
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        alignItems="center"
        sx={{
          borderRadius: '0.75rem',
          border: '0.063rem solid #F5F6F7',
          background: '#FCFCFD'
        }}
      >
        <Box
          display="flex"
          width="49.2%"
          justifyContent="flex-start"
          padding="1.25rem 0rem"
        >
          <Stack display="flex" flexDirection="row" padding="0rem 1.5rem">
            <Avatar
              src={item.profile_picture}
              sx={{
                width: '2rem',
                height: '2rem',
                mr: '0.75rem',
                justifyContent: 'center',
                border: '0.063rem solid #FCFCFD',
                borderRadius: '12.5rem'
              }}
            />
            <Stack display="flex" justifyContent="center">
              <Typography variant="bodyMediumMedium" color="#475467">
                {item.name}
              </Typography>
            </Stack>
          </Stack>
        </Box>

        <Box display="flex" width="38.6%" justifyContent="flex-start">
          <Typography variant="bodyMediumMedium" color="#475467">
            {item.product_receipts}
          </Typography>
        </Box>

        <Box
          sx={{
            cursor: 'pointer',
            display: 'flex'
          }}
          onClick={() => setIsOpen(true)}
        >
          <SvgWrapper
            icon={ChevronRight}
            width="1rem"
            height="1rem"
            styleOverrides={{ fill: 'none', stroke: 'black' }}
          />
        </Box>
      </Box>
    );
  };

  const renderDrawerHeader = () => {
    return (
      <Box>
        <Stack
          display="flex"
          flexDirection="row"
          padding="1.25rem 1.25rem 1.25rem 0rem"
        >
          <Avatar
            src=""
            sx={{
              width: '3.5rem',
              height: '3.5rem',
              mr: '0.75rem',
              justifyContent: 'center',
              borderRadius: '12.5rem'
            }}
          />
          <Stack display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="h6Bold" color="#344054">
              Suvwe Guaranteed Limited
            </Typography>
            <Typography variant="bodySmallMedium" color="#667085">
              No 3 Ikoyi Road, Lagos
            </Typography>
          </Stack>
        </Stack>
      </Box>
    );
  };

  return (
    <Drawer open={open} onClose={onClose} header={renderDrawerHeader()}>
      <Box padding="0rem 1.25rem">
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          padding="1.25rem"
          margin="1rem 0rem 1.75rem 0rem"
          sx={{
            borderRadius: '0.75rem',
            border: '0.063rem solid #F5F6F7',
            background: '#FCFCFD'
          }}
        >
          <Stack display="flex" flexDirection="column" width="35%">
            <Typography variant="bodySmallMedium" color="#667085">
              Total Product Receipts Issued
            </Typography>
            <Typography variant="h6Bold" color="#475467">
              500
            </Typography>

            <Box
              display="flex"
              padding="1rem 0.75rem"
              flexDirection="column"
              alignItems="flex-start"
              gap="0.25rem"
              mt="1rem"
              sx={{
                borderRadius: '0.75rem',
                border: '0.063rem solid #F2F4F7',
                background: '#F5F6F7'
              }}
            >
              <Typography variant="bodySmallRegular" color="#667085">
                Total Product Receipts Issued
              </Typography>
              <Typography variant="bodySmallSemibold" color="#475467">
                N200,000,000
              </Typography>
            </Box>

            <Box
              display="flex"
              padding="1rem 0.75rem"
              flexDirection="column"
              alignItems="flex-start"
              gap="0.25rem"
              mt="0.5rem"
              sx={{
                borderRadius: '0.75rem',
                border: '0.063rem solid #F2F4F7',
                background: '#F5F6F7'
              }}
            >
              <Typography variant="bodySmallRegular" color="#667085">
                By Guarantee
              </Typography>
              <Typography variant="bodySmallSemibold" color="#475467">
                300
              </Typography>
            </Box>
            <Box
              display="flex"
              padding="1rem 0.75rem"
              flexDirection="column"
              alignItems="flex-start"
              gap="0.25rem"
              mt="0.5rem"
              sx={{
                borderRadius: '0.75rem',
                border: '0.063rem solid #F2F4F7',
                background: '#F5F6F7'
              }}
            >
              <Typography variant="bodySmallRegular" color="#667085">
                Product Receipts used
              </Typography>
              <Typography variant="bodySmallSemibold" color="#475467">
                10/500
              </Typography>
            </Box>
          </Stack>

          <Box
            width="60%"
            ml="1.5rem"
            sx={{
              height: 'inherit',
              borderRadius: '0.75rem 0.75rem 0rem 0rem',
              backgroundImage: `url(${chartImage})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat'
            }}
          />
        </Box>

        <Box padding="1.25rem 1.25rem 1.25rem 0rem" mb="auto">
          <Stack flexDirection="column">
            <Typography variant="bodyMediumSemibold" color="#475467">
              Lenders
            </Typography>
            <Typography variant="bodyMediumRegular" color="#667085">
              Keep track of lenders for this distributor and product receipt
              status
            </Typography>
          </Stack>
        </Box>

        <LenderDetail
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        />

        <SearchableContainer<Lender>
          searchPlaceholderText="Search here"
          data={distributors}
          renderDataList={renderData}
          searchField={['title']}
        />
      </Box>
    </Drawer>
  );
};

export default DistributorLenders;
