import React from 'react';
import { Box, FormGroup, Stack, Typography } from '@mui/material';
import { theme } from 'themes/theme';
import FormSwitch from 'components/form/formSwitch';
import { useForm } from 'react-hook-form';

interface NotificationProps {
  label: string;
  description: string;
}

type FormValues = {
  email: boolean;
  in_app: boolean;
};

const NotificationsSettings: React.FC<NotificationProps> = ({
  label,
  description
}) => {
  const { control } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      email: false,
      in_app: false
    }
  });

  return (
    <Stack
      padding="1rem 0rem"
      direction="row"
      display="flex"
      gap="3rem"
      alignItems="center"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '0.75rem'
        }}
      >
        <Typography
          variant="bodyMediumSemibold"
          color={`${theme.palette.common.subheading}`}
        >
          {label}
        </Typography>
        <Typography
          variant="bodyMediumRegular"
          color={`${theme.palette.common.textColor}`}
        >
          {description}
        </Typography>
      </Box>
      <FormGroup>
        <Stack display="flex" direction="column">
          <FormSwitch<FormValues>
            control={control}
            label="Email"
            name="email"
          />
          <FormSwitch<FormValues>
            control={control}
            label="In App"
            name="in_app"
          />
        </Stack>
      </FormGroup>
    </Stack>
  );
};

export default NotificationsSettings;
