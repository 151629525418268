import {
  canViewAppRoute,
  canViewOnlyInDev,
  whitelistOrganizationType,
  canViewWithPermission,
  canViewOnlyAsAdmin,
  passesOneOf,
  passesAllOf
} from './viewPermissions';

import React, { ReactElement, createElement } from 'react';

import AppLayout from 'layouts/appLayout';
import AuthLayout from 'layouts/authLayout';
import ErrorLayout from 'layouts/errorLayout';
import OnboardingLayout from 'layouts/onboardingLayout';
import SettingsLayout from 'pages/settings/layout/settingsLayout';
import WorkflowsBuilderLayout from 'pages/workflows/builder/layout/workflowsBuilderLayout';

import CompleteRegisterPage from 'pages/auth/completeRegisterPage';
import ForgotPasswordPage from 'pages/auth/forgotPasswordPage';
import LoginPage from 'pages/auth/loginPage';
import RegisterPage from 'pages/auth/registerPage';
import ResetPasswordPage from 'pages/auth/resetPasswordPage';

import { OnboardingCenterPage, OnboardingPage } from 'pages/onboarding';

import Error404Page from 'pages/error/error404Page';

import SettingsPage from 'pages/settings/settingsPage';
import BranchesAndTeamsPage from 'pages/branches-teams/branchesAndTeamsPage';
import DashboardPage from 'pages/dashboard/dashboardPage';
import AnalyticsPage from 'pages/analytics/analyticsPage';
import DevHomePage from 'pages/dev/devHomePage';
import VaultPage from 'pages/product-receipt-vault/vaultPage';
import WorkflowsBuilderPage from 'pages/workflows/builder/workflowBuilderPage';
import WorkflowsPage from 'pages/workflows/workflowsPage';

import ProductReceiptBranchVaultPage from 'pages/product-receipt-vault/productReceiptBranchVaultPage';
import ProductReceiptCentralVaultPage from 'pages/product-receipt-vault/productReceiptCentralVaultPage';
import ProductReceiptInfoPage from 'pages/product-receipt/productReceiptInfoPage';
import AccessRequestsInfoPage from 'pages/settings/permissions/requests/requestsInfoPage';
import PermissionsPage from 'pages/settings/permissions/permissionsPage';

import LoadingPage from 'pages/app/loadingPage';
import ProtectedRoute from './protectedRoute';

import { ReactComponent as WorkflowsIcon } from 'assets/svg/automation.svg';
import { ReactComponent as AnalyticsIcon } from 'assets/svg/barchart.svg';
import { ReactComponent as NotificationsIcon } from 'assets/svg/bell.svg';
import { ReactComponent as CodeSquareIcon } from 'assets/svg/code-square.svg';
import { ReactComponent as BranchesAndTeamsIcon } from 'assets/svg/grid.svg';
import { ReactComponent as HomeIcon } from 'assets/svg/home.svg';
import { ReactComponent as VaultIcon } from 'assets/svg/lock-solid.svg';
import { ReactComponent as SettingsIcon } from 'assets/svg/settings.svg';
import { ReactComponent as MerchantsIcon } from 'assets/svg/users.svg';
import { NavRoute } from 'components/nav/types';
import MerchantsRegisteredToDistributor from 'pages/distributorManagement/distributorsView/MerchantsRegisteredToDistributor';
import Merchants from 'pages/distributorManagement/lendersView/Merchants';
import DistributorsRegisteredToMerchant from 'pages/distributorManagement/merchantsView/DistributorsRegisteredToMerchant';
import { PermissionType } from 'services/enums/permissions';
import { Auth } from 'services/slices/authSlice';
import ProfilePage from 'pages/settings/profile/profilePage';
import OrganizationPage from 'pages/settings/organization/organizationPage';
import NotificationsPage from 'pages/settings/notification/notificationsPage';
import PrivacyPage from 'pages/settings/privacy/privacyPage';

interface Route {
  to: string;
  canView: (authState: Auth) => boolean;
  element: JSX.Element | null;
  requireAuth: boolean;
  children?: Route[];
  showInNav: boolean;
  navPosition?: 'top' | 'bottom';
  navLabel?: string;
  navIcon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  navShowDot?: boolean;
  navShowChip?: boolean;
  navOrder?: number;
}

interface RouteGroup {
  routes: Route[];
}

interface SiteRoute {
  layout: React.FC<React.PropsWithChildren>;
  routeGroups: RouteGroup[];
}

const siteRoutes: SiteRoute[] = [
  {
    layout: AuthLayout,
    routeGroups: [
      {
        routes: [
          {
            to: '/login',
            canView: canViewAppRoute(),
            element: <LoginPage />,
            requireAuth: false,
            showInNav: false
          },
          {
            to: '/register',
            canView: canViewAppRoute(),
            element: <RegisterPage />,
            requireAuth: false,
            showInNav: false
          },
          {
            to: '/complete-registration',
            canView: canViewAppRoute(),
            element: <CompleteRegisterPage />,
            requireAuth: false,
            showInNav: false
          },
          {
            to: '/forgot-password',
            canView: canViewAppRoute(),
            element: <ForgotPasswordPage />,
            requireAuth: false,
            showInNav: false
          },
          {
            to: '/password/reset/:resetId',
            canView: canViewAppRoute(),
            element: <ResetPasswordPage />,
            requireAuth: false,
            showInNav: false
          }
        ]
      }
    ]
  },
  {
    layout: OnboardingLayout,
    routeGroups: [
      {
        routes: [
          {
            to: '/onboarding',
            canView: canViewAppRoute(),
            element: <OnboardingPage />,
            requireAuth: false,
            showInNav: false
          }
        ]
      }
    ]
  },
  {
    layout: SettingsLayout,
    routeGroups: [
      {
        routes: [
          {
            to: '/settings',
            canView: canViewAppRoute(),
            element: <SettingsPage />,
            requireAuth: true,
            showInNav: true,
            navLabel: 'Settings',
            navPosition: 'bottom',
            navOrder: 10,
            navIcon: SettingsIcon,
            children: [
              {
                to: '/settings/profile',
                canView: canViewAppRoute(),
                element: <ProfilePage />,
                requireAuth: true,
                showInNav: false
              },
              {
                to: '/settings/organization',
                canView: canViewAppRoute(),
                element: <OrganizationPage />,
                requireAuth: true,
                showInNav: false
              },
              {
                to: '/settings/notifications',
                canView: canViewAppRoute(),
                element: <NotificationsPage />,
                requireAuth: true,
                showInNav: false
              },
              {
                to: '/settings/permissions/:tab?',
                canView: canViewAppRoute(),
                element: <PermissionsPage />,
                requireAuth: true,
                showInNav: false
              },
              {
                to: '/settings/billing',
                canView: canViewAppRoute(),
                element: <>Contains Billing content</>,
                requireAuth: true,
                showInNav: false
              },
              {
                to: '/settings/integrations',
                canView: canViewAppRoute(),
                element: <>Contains Integrations content</>,
                requireAuth: true,
                showInNav: false
              },
              {
                to: '/settings/privacy-security',
                canView: canViewAppRoute(),
                element: <PrivacyPage />,
                requireAuth: true,
                showInNav: false
              }
            ]
          },
          {
            to: 'settings/permissions/requests/:id/:tab',
            canView: canViewAppRoute(),
            element: <AccessRequestsInfoPage />,
            requireAuth: true,
            showInNav: false
          }
        ]
      }
    ]
  },
  {
    layout: WorkflowsBuilderLayout,
    routeGroups: [
      {
        routes: [
          {
            to: '/workflows-builder',
            canView: passesOneOf([
              canViewOnlyAsAdmin(),
              canViewWithPermission(PermissionType.WORKFLOW_SCHEMA_VIEW)
            ]),
            element: <WorkflowsBuilderPage />,
            requireAuth: true,
            showInNav: false
          }
        ]
      }
    ]
  },
  {
    layout: AppLayout,
    routeGroups: [
      {
        routes: [
          {
            to: '/',
            canView: canViewAppRoute(),
            element: <DashboardPage />,
            requireAuth: true,
            showInNav: true,
            navLabel: 'Dashboard',
            navPosition: 'top',
            navOrder: 1,
            navIcon: HomeIcon
          },
          {
            to: '/onboarding-center',
            canView: canViewAppRoute(),
            element: <OnboardingCenterPage />,
            requireAuth: true,
            showInNav: false
          },
          {
            to: '/product-receipts',
            canView: passesOneOf([
              canViewWithPermission(PermissionType.PRODUCT_RECEIPT_VIEW_VAULT)
            ]),
            element: <VaultPage />,
            requireAuth: true,
            showInNav: true,
            navLabel: 'Vault',
            navPosition: 'top',
            navOrder: 3,
            navIcon: VaultIcon
          },
          {
            to: '/product-receipts/central-vault/:merchantId/:distributorId',
            canView: passesOneOf([
              canViewWithPermission(PermissionType.PRODUCT_RECEIPT_VIEW_VAULT)
            ]),
            element: <ProductReceiptCentralVaultPage />,
            requireAuth: true,
            showInNav: false
          },
          {
            to: '/product-receipts/branch-vault/:merchantId/:distributorId/:branchId',
            canView: passesOneOf([
              canViewWithPermission(PermissionType.PRODUCT_RECEIPT_VIEW_VAULT)
            ]),
            element: <ProductReceiptBranchVaultPage />,
            requireAuth: true,
            showInNav: false
          },
          {
            to: '/product-receipts/:productReceiptId/:tab?',
            canView: passesOneOf([
              canViewWithPermission(PermissionType.PRODUCT_RECEIPT_VIEW_VAULT)
            ]),
            element: <ProductReceiptInfoPage />,
            requireAuth: true,
            showInNav: false
          },
          {
            to: '/merchants',
            canView: passesAllOf([
              canViewOnlyAsAdmin(),
              whitelistOrganizationType(['distributor'])
            ]),
            element: <MerchantsRegisteredToDistributor />,
            requireAuth: true,
            showInNav: true,
            navLabel: 'Merchants',
            navPosition: 'top',
            navOrder: 5,
            navIcon: MerchantsIcon,
            navShowChip: true
          },
          {
            to: '/distributors',
            canView: passesAllOf([
              canViewOnlyAsAdmin(),
              whitelistOrganizationType(['merchant'])
            ]),
            element: <DistributorsRegisteredToMerchant />,
            requireAuth: true,
            showInNav: true,
            navLabel: 'Distributor & Pickup',
            navPosition: 'top',
            navOrder: 5,
            navIcon: MerchantsIcon,
            navShowChip: true
          },
          {
            to: '/merchants',
            canView: passesAllOf([
              canViewOnlyAsAdmin(),
              whitelistOrganizationType(['lender'])
            ]),
            element: <Merchants />,
            requireAuth: true,
            showInNav: true,
            navLabel: 'Merchants',
            navPosition: 'top',
            navOrder: 5,
            navIcon: MerchantsIcon,
            navShowChip: true
          },
          {
            to: '/analytics',
            canView: canViewAppRoute(),
            element: <AnalyticsPage />,
            requireAuth: true,
            showInNav: true,
            navLabel: 'Analytics',
            navPosition: 'top',
            navOrder: 6,
            navIcon: AnalyticsIcon
          },
          {
            to: '/workflows',
            canView: passesOneOf([
              canViewOnlyAsAdmin(),
              canViewWithPermission(PermissionType.WORKFLOW_SCHEMA_VIEW)
            ]),
            element: <WorkflowsPage />,
            requireAuth: true,
            showInNav: true,
            navLabel: 'Workflows',
            navPosition: 'top',
            navOrder: 7,
            navIcon: WorkflowsIcon,
            navShowChip: true
          },
          {
            to: '/branches-teams',
            canView: canViewOnlyAsAdmin(),
            element: <BranchesAndTeamsPage />,
            requireAuth: true,
            showInNav: true,
            navLabel: 'Branches & Teams',
            navPosition: 'top',
            navOrder: 8,
            navIcon: BranchesAndTeamsIcon
          },
          {
            to: '/notifications',
            canView: canViewAppRoute(),
            element: <></>,
            requireAuth: true,
            showInNav: true,
            navLabel: 'Notifications',
            navPosition: 'bottom',
            navOrder: 9,
            navIcon: NotificationsIcon
          },
          {
            to: '/dev',
            canView: canViewOnlyInDev(),
            element: <DevHomePage />,
            requireAuth: true,
            showInNav: true,
            navLabel: 'Dev Home',
            navPosition: 'bottom',
            navOrder: 10,
            navIcon: CodeSquareIcon
          }
        ]
      }
    ]
  }
];

export const getRoutesForReactRouter = (authState: Auth) => {
  const reactRouterRoutes = [];
  siteRoutes.forEach(layout => {
    layout.routeGroups.forEach(routeGroup => {
      routeGroup.routes.forEach(route => {
        if (route.children) {
          // If route has children process them instead
          const childRoutes: { path: string; element: ReactElement }[] =
            route.children
              .map(childRoute => {
                if (childRoute.canView(authState)) {
                  return {
                    path: childRoute.to,
                    element: childRoute.requireAuth ? (
                      <ProtectedRoute>
                        <LoadingPage>
                          {childRoute.element as ReactElement}
                        </LoadingPage>
                      </ProtectedRoute>
                    ) : (
                      (childRoute.element as ReactElement)
                    ),
                    exact: true
                  };
                }
              })
              .filter(Boolean) as {
              path: string;
              element: ReactElement;
            }[];

          if (route.canView(authState)) {
            const parentRoute = {
              path: route.to,
              element: route.requireAuth ? (
                <ProtectedRoute>
                  <LoadingPage>
                    {createElement(layout.layout, {}, route.element)}
                  </LoadingPage>
                </ProtectedRoute>
              ) : (
                createElement(layout.layout, {}, route.element)
              ),
              children: childRoutes,
              exact: true
            };
            reactRouterRoutes.push(parentRoute);
          }
        } else {
          // Only add route if canView Passes
          if (route.canView(authState)) {
            if (route.requireAuth) {
              reactRouterRoutes.push({
                path: route.to,
                element: (
                  <ProtectedRoute>
                    <LoadingPage>
                      {createElement(layout.layout, {}, route.element)}
                    </LoadingPage>
                  </ProtectedRoute>
                ),
                exact: true
              });
            } else {
              reactRouterRoutes.push({
                path: route.to,
                element: createElement(layout.layout, {}, route.element),
                exact: true
              });
            }
          }
        }
      });
    });
  });

  // Push in catchall/error routes last
  reactRouterRoutes.push({
    path: '*',
    element: (
      <ErrorLayout>
        <Error404Page />
      </ErrorLayout>
    )
  });
  return reactRouterRoutes;
};

const addValidNavObjOrSkip = (routes: NavRoute[], route: Route) => {
  if (
    route.navIcon &&
    route.navLabel &&
    route.navPosition &&
    route.navOrder &&
    route.to
  ) {
    const mappedChildren: NavRoute[] = [];
    route.children?.forEach((child: Route) => {
      if (
        child.navLabel &&
        child.navIcon &&
        child.navPosition &&
        child.navOrder &&
        child.to
      ) {
        mappedChildren.push({
          text: child.navLabel,
          icon: child.navIcon,
          showDot: child.navShowDot,
          showChip: child.navShowChip,
          link: child.to,
          position: child.navPosition,
          order: child.navOrder
        });
      }
    });

    const navRoute: NavRoute = {
      icon: route.navIcon,
      text: route.navLabel,
      showDot: route.navShowDot,
      showChip: route.navShowChip,
      link: route.to,
      position: route.navPosition,
      order: route.navOrder,
      children: mappedChildren
    };
    routes.push(navRoute);
  }
};

export const getRoutesForNav = (authState: Auth) => {
  const navRoutes: NavRoute[] = [];

  siteRoutes.forEach(layout => {
    layout.routeGroups.forEach(routeGroup => {
      routeGroup.routes.forEach(route => {
        // Only add route if canView Passes
        if (route.showInNav && route.canView(authState)) {
          addValidNavObjOrSkip(navRoutes, route);
        }
      });
    });
  });

  // sort the nav routes before returning
  navRoutes.sort((a, b) => a.order - b.order);
  return navRoutes;
};

export default siteRoutes;
