import React, { useMemo } from 'react';
import { Table } from 'components/table';

import { createColumnHelper } from '@tanstack/react-table';

import type { ColumnDef } from '@tanstack/react-table';
import { Typography } from '@mui/material';
import { useGetTeamsQuery } from 'services/api/teamApi';
import { TeamApiData } from 'services/types/teams';

interface TableDataT {
  name: string;
}

const columnHelper = createColumnHelper<TableDataT>();
const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
    cell: info => (
      <Typography variant="bodyMediumMedium" color="#667085">
        {info.getValue()}
      </Typography>
    ),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  })
] as ColumnDef<TableDataT>[];
// Cast is a workaround due to issues with tanstack table
// See https://github.com/TanStack/table/issues/4302

const emptyTeamsData: TableDataT[] = [];
const TeamsTable = () => {
  const { data: teams, isLoading } = useGetTeamsQuery({});

  const updatedTeamsList = useMemo(() => {
    if (teams) {
      return teams.map((team: TeamApiData) => ({
        name: team.name
      }));
    }
    return emptyTeamsData;
  }, [teams]);

  return (
    <Table<TableDataT>
      hover
      columns={columns}
      data={updatedTeamsList}
      onClickRow={(rowData: TableDataT) => {
        console.log(`Hi from row ${rowData.name}`);
      }}
      optionsButton
      enableFilter={false}
      filterColumns={['name']}
      searchPlaceholderText="Search for Teams"
      loadingTable={isLoading}
    />
  );
};

export default TeamsTable;
