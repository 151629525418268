import React, { useMemo } from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { Table } from 'components/table';
import { Chip } from 'components/chip';
import { RoleRequestApiData } from 'services/types/permission';
import { useGetRoleRequestsQuery } from 'services/api/permissionsApi';
import _ from 'lodash';

const emptyRoleRequestData: RoleRequestApiData[] = [];

const columnHelper = createColumnHelper<RoleRequestApiData>();
const columns = [
  columnHelper.accessor('user', {
    header: 'USERS',
    cell: info => {
      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <Avatar
            src={info.getValue().profile_picture}
            sx={{
              width: '1.5rem',
              height: '1.5rem',
              mr: '0.75rem',
              justifyContent: 'center',
              borderRadius: '12.5rem'
            }}
          />
          <Stack display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="bodyMediumMedium" color="#344054">
              {info.getValue().firstname} {info.getValue().lastname}
            </Typography>
            <Typography variant="bodyMediumRegular" color="#667085">
              {info.getValue().email}
            </Typography>
          </Stack>
        </Stack>
      );
    },
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('role.name', {
    header: 'ROLE REQUESTED',
    cell: info => <Chip label={info.getValue()} size="sm" icon />,
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  }),
  columnHelper.accessor('approved_on', {
    header: 'STATUS',
    cell: info =>
      chipData.map((chip, index) => {
        const status = _.isNull(info.getValue())
          ? 'Approval Pending'
          : 'Approved';
        return (
          <Box key={index}>
            {status === chip.text && (
              <Chip label={status} size="sm" color={chip.color} icon />
            )}
          </Box>
        );
      }),
    footer: info => info.column.id,
    sortingFn: 'alphanumeric'
  })
] as ColumnDef<RoleRequestApiData>[];
// Cast is a workaround due to issues with tansatck table
// See https://github.com/TanStack/table/issues/4302

const RoleRequestTable = () => {
  const navigate = useNavigate();
  const { data } = useGetRoleRequestsQuery({});

  const roleRequestsData = useMemo(() => {
    if (data) {
      return [...data].reverse();
    }
    return emptyRoleRequestData;
  }, [data]);

  return (
    <Table<RoleRequestApiData>
      hover
      columns={columns}
      data={roleRequestsData}
      optionsButton
      enableFilter={false}
      onClickRow={(rowData: RoleRequestApiData) =>
        navigate(
          `/settings/permissions/requests/${rowData.role_request_id}/details`,
          {
            state: 'role'
          }
        )
      }
      filterColumns={[]}
      searchPlaceholderText="Search with users, roles etc"
    />
  );
};

export default RoleRequestTable;

const chipData: { text: string; color: 'error' | 'success' | 'warning' }[] = [
  {
    text: 'Approved',
    color: 'success'
  },
  {
    text: 'Approval Pending',
    color: 'warning'
  },
  {
    text: 'Rejected',
    color: 'error'
  }
];
