import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as Dot } from 'assets/svg/dot.svg';
import { Button } from 'components/button';
import { Accordion } from 'components/accordion';
import { SvgWrapper } from 'components/svg';
import SearchableContainer from 'components/search/searchableContainer';
import { useGetGroupedPermissionsQuery } from 'services/api/permissionsApi';
import Loading from 'components/loader/loading';

interface Permissions {
  category: string;
  permissions: { [key: string]: string }[];
}

const PermissionsAssigned = ({ userId }: { userId: string }) => {
  const { data: permissions, isLoading: isLoadingPermissions } =
    useGetGroupedPermissionsQuery({ userId: userId });

  // transform the permissions data to the searchable container format
  const permissionData = permissions
    ? Object.keys(permissions).map(category => ({
        category,
        permissions: permissions[category]
      }))
    : [];

  const renderData = (item: Permissions) => {
    return (
      <Box
        sx={{
          backgroundColor: '#FCFCFD',
          borderRadius: '0.75rem',
          border: '0.094rem solid #F2F4F7',
          width: '100%',
          mb: '0.75rem'
        }}
      >
        <Accordion
          key={item.category}
          summary={
            <Stack width="25.6rem">
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                mb="0.25rem"
              >
                <SvgWrapper
                  icon={Dot}
                  width="0.375rem"
                  height="0.375rem"
                  styleOverrides={{ fill: '#3E7DF8' }}
                />
                <Typography variant="captionSmall" color="#667085">
                  {item.category} Permissions
                </Typography>
              </Stack>
            </Stack>
          }
        >
          {item.permissions.map(permissionItem => (
            <Stack
              key={permissionItem.permission_name}
              display="flex"
              direction="row"
              padding="0.75rem 0rem"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack>
                <Typography variant="bodyMediumMedium" color="#344054">
                  {permissionItem.permission_name
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}
                </Typography>
                <Typography variant="bodyMediumMedium" color="#98A2B3">
                  {permissionItem.description}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Accordion>
      </Box>
    );
  };

  if (isLoadingPermissions) {
    return <Loading loading={isLoadingPermissions} />;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          padding: '0.75rem 1.25rem',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: 'center',
          gap: '1rem',
          mb: '0.75rem',
          alignSelf: 'stretch',
          flexWrap: 'wrap',
          width: '100%'
        }}
      >
        <Stack flexDirection="column" maxWidth="60%">
          <Typography variant="bodyMediumSemibold" color="#475467">
            Permissions
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            All permissions assigned to this user
          </Typography>
        </Stack>
      </Box>
      <Box padding="0rem 1.25rem">
        <SearchableContainer<Permissions>
          searchPlaceholderText="Search here"
          data={permissionData}
          renderDataList={renderData}
          searchField={['category']}
        />
      </Box>

      <Stack
        sx={{
          display: 'flex',
          padding: '0.75rem 1.5rem',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          gap: '1rem',
          alignSelf: 'stretch',
          borderRadius: '0rem 0rem 0rem 0.75rem',
          borderTop: '0.063rem solid  #F5F6F7',
          background: '#FCFCFD',
          width: '100%',
          position: 'absolute',
          bottom: 0
        }}
      >
        <Stack direction="row" spacing={1}>
          <Button color="grey" transparent size="md" text="Cancel" />
          <Button color="primary" size="md" text="Save changes" disabled />
        </Stack>
      </Stack>
    </>
  );
};

export default PermissionsAssigned;
