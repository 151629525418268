import React from 'react';
import {
  SvgIcon,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
  Typography,
  Box,
  Radio,
  Checkbox,
  SelectChangeEvent
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { fontFamily2, theme } from 'themes/theme';
import type {
  FormSelectProps,
  FormSelectOption
} from 'components/form/common/formTypes';
import { shadowsTheme } from 'themes/shadows';
import type { FieldValues } from 'react-hook-form';

const BoxChecked = () => {
  return (
    <SvgIcon inheritViewBox>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="20" height="20" rx="4" fill="#2563EB" />
        <path
          d="M6.11133 9.99995L8.88911 12.7777L14.4447 7.22217"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

const BoxUnChecked = () => {
  return (
    <SvgIcon inheritViewBox>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <rect
          x="0.5"
          y="0.5"
          width="19"
          height="19"
          rx="5.5"
          fill="white"
          stroke="#EAECF0"
        />
      </svg>
    </SvgIcon>
  );
};

const RadioChecked = () => {
  return (
    <SvgIcon inheritViewBox>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="20" height="20" rx="10" fill="#3E7DF8" />
        <path
          d="M14.4455 6.66669L8.33442 12.7778L5.55664 10"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

const RadioUnChecked = () => {
  return (
    <SvgIcon inheritViewBox>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <rect
          x="0.5"
          y="0.5"
          width="19"
          height="19"
          rx="9.5"
          fill="white"
          stroke="#D0D5DD"
        />
      </svg>
    </SvgIcon>
  );
};

export default function FormSelect<FormValueT extends FieldValues>({
  label,
  name,
  control,
  options,
  multiselect,
  renderSelected,
  size,
  placeholder,
  onChange: onChangeCallBack,
  ...rest
}: FormSelectProps<FormValueT>) {
  const handleOnChange = (
    event: SelectChangeEvent<unknown>,
    onChange: (...event: unknown[]) => void
  ) => {
    if (multiselect) {
      const value = event.target.value as FormSelectOption['value'][];
      onChange(value);
    } else {
      const value = event.target.value as FormSelectOption['value'];
      onChange(value);
    }

    if (onChangeCallBack) {
      onChangeCallBack();
    }
  };

  const isChecked = (fieldValue: string | string[], value: string) => {
    if (multiselect) {
      return fieldValue.includes(value);
    } else {
      return fieldValue === value;
    }
  };

  const Select = () => {
    return (
      <SvgIcon inheritViewBox>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          style={{ marginRight: '1rem' }}
        >
          <path
            d="M5 7.5L10 12.5L15 7.5"
            stroke="#566474"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </SvgIcon>
    );
  };

  return (
    <FormControl
      fullWidth
      sx={{
        paddingBottom: { xs: size === 'small' ? 0 : 3 },
        '& .css-13nvzlm-MuiFormHelperText-root': {
          fontSize: '13px',
          ml: 0
        }
      }}
    >
      <FormLabel htmlFor={name}>
        <Typography variant="inputLabel" color="#667085">
          {label}
        </Typography>
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value, ...otherProps },
          fieldState: { error, invalid, isTouched }
        }) => (
          <TextField
            id={name}
            select
            error={invalid && isTouched}
            type="text"
            helperText={error?.message}
            {...rest}
            {...otherProps}
            size={size}
            FormHelperTextProps={{
              sx: {
                ml: '0px',
                mr: '0px',
                fontFamily: fontFamily2,
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '19px',
                letterSpacing: '-0.025em',
                width: '100%',
                textAlign: 'left',
                color: `${theme.palette.common.error}`
              }
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: `${size === 'small' ? '2.75rem' : '3.2rem'}`,
                borderRadius: '0.5rem',
                border: '1.5px solid #EAECF0',
                background: '#FEFFFF',
                boxShadow: '0px 4px 4px 0px rgba(220, 220, 220, 0.02)',
                '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent'
                },
                '&.Mui-focused fieldset': {
                  borderColor: `${theme.palette.common.btnColor}`,
                  boxShadow: `${theme.palette.common.boxShadow}`
                }
              }
            }}
            SelectProps={{
              IconComponent: Select,
              sx: {
                color: '#667085'
              },
              MenuProps: {
                sx: {
                  '& .MuiMenu-paper': {
                    borderRadius: '0rem 0rem 0.75rem 0.75rem',
                    backgroundColor: '#FFF',
                    boxShadow: shadowsTheme.xxSmallShadowSoft,
                    overflowY: 'auto',
                    maxHeight: '15rem'
                  }
                }
              },
              multiple: multiselect ?? false,
              renderValue: value => {
                if (renderSelected === undefined) {
                  return placeholder;
                }
                if (value === '') {
                  value = null;
                  return placeholder;
                }
                if (multiselect) {
                  const renderedValues: string[] = value as string[];
                  return renderedValues.map(val => {
                    const option = options.find(option => option.value === val);
                    return option ? renderSelected(option.title) : '';
                  });
                } else {
                  const renderedValue: string = value as string;
                  const option = options.find(
                    option => option.value === renderedValue
                  );
                  return option ? renderSelected(option.title) : '';
                }
              },

              onChange: event => {
                handleOnChange(event, onChange);
              },
              value: value
            }}
          >
            {options?.map((option: FormSelectOption) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  padding: '0.75rem',
                  '&.Mui-selected': {
                    backgroundColor: '#FFF',
                    '&.Mui-focusVisible': { backgroundColor: '#F9F9FA' }
                  },
                  '&.Mui-focusVisible': { backgroundColor: '#F9F9FA' }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'start'
                  }}
                >
                  <Typography variant="bodyMediumMedium" color="#344054">
                    {option.title}
                  </Typography>
                  {option.subtitle && (
                    <Typography variant="bodyMediumRegular" color="#98A2B3">
                      {option.subtitle}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ marginLeft: 'auto' }}>
                  {multiselect ? (
                    <Checkbox
                      sx={{
                        width: '1.25rem',
                        height: '1.25rem',
                        borderRadius: '0.25rem'
                      }}
                      checked={isChecked(value, option.value)}
                      checkedIcon={<BoxChecked />}
                      icon={<BoxUnChecked />}
                    />
                  ) : (
                    <Radio
                      sx={{
                        width: '1.25rem',
                        height: '1.25rem'
                      }}
                      checked={isChecked(value, option.value)}
                      checkedIcon={<RadioChecked />}
                      icon={<RadioUnChecked />}
                    />
                  )}
                </Box>
              </MenuItem>
            ))}
          </TextField>
        )}
      />
    </FormControl>
  );
}
