export const shadowsTheme = {
  xxSmallShadowSoft:
    'box-shadow: 0rem 0.09375rem 0.25rem -0.0625rem rgba(20, 28, 40, 0.05)',
  xSmallShadowSoft:
    '0rem 0.3125rem 0.8125rem -0.3125rem rgba(16, 25, 40, 0.05), 0rem 0.125rem 0.25rem -0.0625rem rgba(16, 25, 40, 0.02)',
  buttonShadow:
    'box-shadow: 0rem 0.09375rem 0.25rem -0.0625rem rgba(182, 182, 182, 0.07)',
  iconShadow:
    '0px 4px 4px 0px rgba(191, 191, 191, 0.25), 0px 0px 0px 3px rgba(232, 240, 255, 0.17), 0px 0px 4px 0px rgba(237, 237, 237, 0.25), 0px 0px 4px 0px rgba(177, 187, 197, 0.25) inset, 0px 0px 0px 6px rgba(222, 233, 255, 0.20)'
};
