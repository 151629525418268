import React, { useState } from 'react';
import {
  Box,
  Tab as MUITab,
  Tabs as MUITabs,
  Paper,
  Typography
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { theme } from 'themes/theme';
import RequestDetails from './details/requestDetails';
import RequestInfoTabLayout from './requestInfoTabLayout';
import RequestWorkflows from './workflows/requestWorkflows';
import {
  useAssignRoleMutation,
  useGetPermissionsQuery,
  useGetRoleRequestQuery
} from 'services/api/permissionsApi';
import _ from 'lodash';
import { Button } from 'components/button';
import { PermissionType } from 'services/enums/permissions';
import ShowInfo from 'components/info/showInfo';
import { Modal } from 'components/modal';
import { ReactComponent as InfoWarning } from 'assets/svg/infoWarning.svg';

const tabList = ['details', 'workflow'];

const AccessRequestsInfoPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, tab } = useParams();
  const currentTab = tabList.findIndex(item => tab === item);

  const { data: permissions } = useGetPermissionsQuery({});
  const [assignRoleModalOpen, setAssignRoleModalOpen] =
    useState<boolean>(false);

  const { data } = useGetRoleRequestQuery({
    roleRequestId: _.toString(id)
  });

  const [assignRole, { isLoading }] = useAssignRoleMutation();

  const handleChange = (newTabIndex: number) => {
    const lastSlashIndex = location.pathname.lastIndexOf('/');
    const basePath = location.pathname.substring(0, lastSlashIndex);
    navigate(`${basePath}/${tabList[newTabIndex]}`);
  };

  const isActiveTab = (tabId: string) => {
    return tabId === tabList[currentTab];
  };

  const onClickAssignRole = () => {
    assignRole({
      roleRequestId: data?.role_request_id || ''
    })
      .unwrap()
      .then(() => {
        ShowInfo({
          message: 'Role was assigned successfully.',
          type: 'success',
          componentType: 'toast',
          hideProgressBar: false
        });
        setAssignRoleModalOpen(false);
      })
      .catch(error => {
        ShowInfo({
          message: 'Oops! Something went wrong.',
          subText: `${Object.values(error.data)[0]}`,
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
      });
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Paper
        elevation={0}
        sx={{
          padding: '1.25rem',
          borderRadius: '0.75rem',
          backgroundColor: '#FFF',
          position: 'relative',
          border: '.0625rem solid #F5F6F7',
          margin: '0 auto',
          maxWidth: '75%'
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            background: '#fff'
          }}
        >
          <MUITabs
            orientation="horizontal"
            value={Math.max(0, currentTab)}
            onChange={(e, value) => handleChange(value)}
            sx={{
              borderBottom: 1,
              borderColor: '#F5F6F7',
              marginBottom: '1.75rem',
              display: 'flex',
              justifyContent: 'space-between',
              position: 'relative'
            }}
          >
            <MUITab
              label={
                <Typography
                  variant="bodyMediumMedium"
                  sx={{ color: theme.palette.common.labelColor }}
                >
                  Details
                </Typography>
              }
              sx={{
                borderBottom: `${isActiveTab('details') ? '.0625rem solid #3E7DF8' : 'none'}`
              }}
            />
            <MUITab
              label={
                <Typography
                  variant="bodyMediumMedium"
                  sx={{ color: theme.palette.common.labelColor }}
                >
                  Workflow
                </Typography>
              }
              sx={{
                borderBottom: `${isActiveTab('workflow') ? '.0625rem solid #3E7DF8' : 'none'}`
              }}
            />

            {isActiveTab('workflow') &&
              data?.role_request_workflow?.completed && (
                <Box
                  sx={{
                    width: 'fit-content',
                    position: 'absolute',
                    zIndex: 1,
                    right: 0,
                    top: 0
                  }}
                >
                  <Button
                    color="primary"
                    size="sm"
                    iconPosition="start"
                    text="Assign Role"
                    onClick={() => {
                      if (
                        !permissions ||
                        !permissions.includes(PermissionType.ROLE_ASSIGN)
                      ) {
                        ShowInfo({
                          message: 'Cannot assign role',
                          subText: 'You do not have permissions to do this!',
                          type: 'error',
                          componentType: 'toast',
                          hideProgressBar: false
                        });
                        return;
                      }
                      setAssignRoleModalOpen(true);
                    }}
                    disabled={!_.isNull(data.approved_on)}
                  />
                </Box>
              )}
          </MUITabs>
          {data && (
            <RequestInfoTabLayout>
              <Box
                role="tabpanel"
                hidden={!isActiveTab('details')}
                id="simple-tabpanel-details"
                aria-labelledby="simple-tab-details"
                sx={{ width: '100%' }}
              >
                <RequestDetails roleRequest={data} />
              </Box>
              <Box
                role="tabpanel"
                hidden={!isActiveTab('workflow')}
                id="simple-tabpanel-workflow"
                aria-labelledby="simple-tab-workflow"
                sx={{ width: '100%' }}
              >
                <RequestWorkflows roleRequest={data} />
              </Box>
            </RequestInfoTabLayout>
          )}
        </Box>
        <Modal
          open={assignRoleModalOpen}
          onClose={() => setAssignRoleModalOpen(false)}
          text="Assign Role"
          subText={`You are about to assign this Role to ${data?.user.firstname} ${data?.user.lastname}. Do you wish to proceed? `}
          src={InfoWarning}
          onClick={() => {
            onClickAssignRole();
          }}
          btnProps={{
            text: 'Proceed',
            color: 'primary',
            loadingIcon: isLoading
          }}
          modalOverrideProps={{ marginLeft: '20%' }}
          fill="#F9C865"
          svgColor="#FEF8EC"
        />
      </Paper>
    </Box>
  );
};

export default AccessRequestsInfoPage;
