import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type App = {
  shouldReload: boolean;
  isLoaded: boolean;
  lastLoadTime: number;
};

const initialState: App = {
  shouldReload: false,
  isLoaded: false,
  lastLoadTime: 0
};

interface AppLoadedActionPayload {
  lastLoadTime: number;
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppLoaded(state: App, action: PayloadAction<AppLoadedActionPayload>) {
      return {
        ...initialState,
        shouldReload: false,
        isLoaded: true,
        lastLoadTime: action.payload.lastLoadTime
      };
    },
    setAppShouldLoad() {
      return { ...initialState, isLoaded: false, lastLoadTime: 0 };
    },
    setAppShouldReLoad() {
      return { ...initialState, shouldReload: true };
    }
  }
});

export const { setAppLoaded, setAppShouldLoad, setAppShouldReLoad } =
  appSlice.actions;
export default appSlice;
