export enum PermissionType {
  UNKNOWN = 'UNKNOWN',

  // Role specific permissions
  ROLE_UPDATE = 'role_update',
  ROLE_ASSIGN = 'role_assign',
  ROLE_ASSIGN_REQUEST = 'role_assign_request',

  // Organization specific permissions
  ORGANIZATION_UPDATE_MEMBER = 'organization_update_member',
  ORGANIZATION_UPDATE_TEAM = 'organization_update_team',
  ORGANIZATION_UPDATE_TEAM_MEMBER = 'organization_update_team_member',
  ORGANIZATION_UPDATE_TEAM_PERMISSIONS = 'organization_update_team_permissions',
  ORGANIZATION_UPDATE_BRANCH = 'organization_update_branch',
  ORGANIZATION_UPDATE_BRANCH_MEMBER = 'organization_update_branch_member',
  ORGANIZATION_VIEW_ALL_BRANCHES = 'organization_view_all_branches',
  ORGANIZATION_VIEW_MEMBER_PERMISSIONS = 'organization_view_member_permissions',

  // Workflow permissions
  WORKFLOW_VIEW = 'workflow_view',
  WORKFLOW_SCHEMA_UPDATE = 'workflow_schema_update',
  WORKFLOW_SCHEMA_PUBLISH = 'workflow_schema_publish',
  WORKFLOW_SCHEMA_VIEW = 'workflow_schema_view',

  // Product Specific Permissions
  PRODUCT_UPDATE = 'product_update',
  PRODUCT_CATEGORY_UPDATE = 'product_category_update',

  // Product receipt specific permissions
  PRODUCT_RECEIPT_UPDATE = 'product_receipt_update',
  PRODUCT_RECEIPT_VIEW_VAULT = 'product_receipt_view_vault',
  PRODUCT_RECEIPT_START_BRANCH_ASSIGNMENT_WORKFLOW = 'product_receipt_start_branch_assignment_workflow',
  PRODUCT_RECEIPT_COMPLETE_BRANCH_ASSIGNMENT = 'product_receipt_complete_branch_assignment',
  PRODUCT_RECEIPT_START_ENDORSEMENT_WORKFLOW = 'product_receipt_start_endorsement_workflow',
  PRODUCT_RECEIPT_COMPLETE_ENDORSEMENT = 'product_receipt_complete_endorsement',
  PRODUCT_RECEIPT_GENERATE_PICKUP_CODE = 'product_receipt_generate_pickup_code',
  PRODUCT_RECEIPT_VIEW_PICKUP_REQUESTS = 'product_receipt_view_pickup_requests',
  PRODUCT_RECEIPT_UPDATE_PICKUP_REQUEST = 'product_receipt_update_pickup_request',
  PRODUCT_RECEIPT_VIEW_PICKUP_DEPOTS = 'product_receipt_view_pickup_depots',
  PRODUCT_RECEIPT_SCHEDULE_PRODUCT_PICKUP = 'product_receipt_schedule_product_pickup',
  PRODUCT_RECEIPT_VALIDATE_PICKUP_CODE = 'product_receipt_validate_pickup_code',
  PRODUCT_RECEIPT_RELEASE_PRODUCTS = 'product_receipt_release_products',
  PRODUCT_RECEIPT_VIEW_HISTORY = 'product_receipt_view_history'
}

export enum PermissionTypeGroup {
  ROLES = 'roles',
  ORGANIZATION = 'organization',
  PRODUCT_RECEIPT = 'product_receipt',
  WORKFLOW = 'workflow',
  PRODUCT = 'product'
}
