import React, { createContext, useState, PropsWithChildren } from 'react';

type ScheduleFormProps = {
  pickup_date: string;
  depot: string;
};

export const SchedulePickupDataValues: ScheduleFormProps = {
  pickup_date: '',
  depot: ''
};

type SchedulePickupContextTypes = {
  data: ScheduleFormProps;
  setData: React.Dispatch<React.SetStateAction<ScheduleFormProps>>;
  setSchedulePickupModalOpen: SchedulePickupContextWrapperProps['setSchedulePickupModalOpen'];
};

const SchedulePickupContextDefaults: SchedulePickupContextTypes = {
  data: SchedulePickupDataValues,
  setSchedulePickupModalOpen: () => undefined,
  setData: () => undefined
};

type SchedulePickupContextWrapperProps = {
  setSchedulePickupModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SchedulePickupContext = createContext<SchedulePickupContextTypes>(
  SchedulePickupContextDefaults
);

const SchedulePickupContextWrapper = ({
  children,
  setSchedulePickupModalOpen
}: PropsWithChildren<SchedulePickupContextWrapperProps>) => {
  const [data, setData] = useState<ScheduleFormProps>(SchedulePickupDataValues);

  const values: SchedulePickupContextTypes = {
    setSchedulePickupModalOpen,
    data,
    setData
  };

  return (
    <SchedulePickupContext.Provider value={values}>
      {children}
    </SchedulePickupContext.Provider>
  );
};

export default SchedulePickupContextWrapper;
