import { createBrowserRouter } from 'react-router-dom';
import { getRoutesForReactRouter } from './siteMaps';
import { Auth } from 'services/slices/authSlice';
export { getRoutesForNav } from './siteMaps';

const getRouter = (authState: Auth) => {
  return createBrowserRouter(getRoutesForReactRouter(authState));
};

export default getRouter;
