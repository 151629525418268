import React, { useState } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { ReactComponent as Truck } from 'assets/svg/truck.svg';
import gif from 'assets/animated/ellipse.gif';
import { FormInput } from 'components/form';
import { Button } from 'components/button';
import { SvgWrapper } from 'components/svg';
import { shadowsTheme } from 'themes/shadows';
import { validatePickupCodeValidator } from '../validator/pickupValidators';
import ShowInfo from 'components/info/showInfo';
import { ProductReceiptApiData } from 'services/types/productReceipt';
import {
  useReleaseProductReceiptMutation,
  useValidatePickupCodeMutation
} from 'services/api/productReceiptApi';
import { formattedDate } from 'utilities/helperFunc/formatter';

type FormValues = {
  pickup_code: string;
};

type FormProps = {
  setValidatePickupCodeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  productReceipt: ProductReceiptApiData;
};

const ValidatePickupCodeForm = ({
  setValidatePickupCodeModalOpen,
  productReceipt
}: FormProps) => {
  const productReceiptId = productReceipt.product_receipt_id;
  const customer = productReceipt.pickup_request?.customer;
  const [pickupCode, setPickupCode] = useState<string>('');
  const [validatePickup, { isLoading, isSuccess }] =
    useValidatePickupCodeMutation();

  const [releaseProductReceipt, { isLoading: releaseLoading }] =
    useReleaseProductReceiptMutation();

  const releaseFn = () => {
    const id = productReceipt.product_receipt_id;

    return releaseProductReceipt({
      productReceiptId: id,
      data: {
        pickup_code: pickupCode
      }
    })
      .unwrap()
      .then(() => {
        ShowInfo({
          message: 'Released ',
          subText: 'Products have been released',
          type: 'success',
          componentType: 'toast',
          hideProgressBar: false
        });
        setValidatePickupCodeModalOpen(false);
      })
      .catch(() => {
        ShowInfo({
          message: 'Request failed',
          subText: 'Products not Released!',
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
        setValidatePickupCodeModalOpen(false);
      });
  };

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      pickup_code: ''
    },
    resolver: yupResolver(
      validatePickupCodeValidator
    ) as unknown as Resolver<FormValues>
  });

  const onSubmit: SubmitHandler<FormValues> = async data => {
    setPickupCode(data.pickup_code);
    productReceiptId &&
      validatePickup({
        productReceiptId: productReceiptId,
        data: {
          pickup_code: data.pickup_code
        }
      })
        .unwrap()
        .then(() => {
          ShowInfo({
            message: 'Validation Successful',
            subText: 'Pickup code has been validated!',
            type: 'success',
            componentType: 'toast',
            hideProgressBar: false
          });
        })
        .catch(() => {
          ShowInfo({
            message: 'Validation failed',
            subText: 'Pickup code is incorrect!',
            type: 'error',
            componentType: 'toast',
            hideProgressBar: false
          });
          setValidatePickupCodeModalOpen(false);
        });
  };

  return (
    <>
      {isSuccess ? (
        <Box
          sx={{
            padding: '1.25rem 0rem'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 'inherit'
              }}
            >
              <Box component="img" src={gif} />
              <Typography variant="bodyLargeSemibold" color="#344054">
                Pickup code Verified!
              </Typography>
              <Typography variant="bodyMediumRegular" color="#667085">
                Pickup code has been verified successfully
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              margin: '1rem .75rem',
              padding: '1.25rem 2.5rem',
              backgroundColor: 'rgba(102, 112, 133, 0.02)',
              borderRadius: '0.5rem',
              border: '1px solid #F5F6F7',
              display: 'flex',
              flexDirection: 'column',
              gap: '1.25rem'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#98A2B3">
                PRC Code
              </Typography>
              <Typography variant="bodyMediumMedium" color="#475467">
                {customer?.organization_id}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#98A2B3">
                Product
              </Typography>
              <Typography variant="bodyMediumMedium" color="#475467">
                {productReceipt.product.name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#98A2B3">
                Quantity
              </Typography>
              <Typography variant="bodyMediumMedium" color="#475467">
                {productReceipt.total_quantity}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#98A2B3">
                Pickup Date
              </Typography>
              <Typography variant="bodyMediumMedium" color="#475467">
                {formattedDate(
                  productReceipt.pickup_request?.pickup_date || '',
                  'DD MMMM YYYY'
                )}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderTop: '0.0625rem solid #F5F6F7',
              padding: '1.25rem 1.5rem',
              marginBottom: '0.25rem'
            }}
          >
            <Button
              submit
              color="grey"
              transparent
              size="md"
              text="Close"
              width="48%"
              onClick={() => setValidatePickupCodeModalOpen(false)}
            />

            <Button
              submit
              color="primary"
              size="md"
              text="Release"
              width="48%"
              onClick={() => releaseFn()}
              loadingIcon={releaseLoading}
            />
          </Box>
        </Box>
      ) : (
        <>
          <Stack
            display="flex"
            direction="column"
            alignItems="flex-start"
            sx={{
              padding: '1.25rem 1.25rem 1rem 1.25rem',
              borderBottom: '2px solid #FCFCFD',
              backgroundColor: '#FCFCFD',
              borderRadius: '0.75rem 0.75rem 0rem 0rem'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '2.25rem',
                width: '2.25rem',
                borderRadius: '6.25rem',
                backgroundColor: '#F8FBFE',
                padding: '0.5rem',
                boxShadow: shadowsTheme.iconShadow,
                marginBottom: '1rem'
              }}
            >
              <SvgWrapper
                icon={Truck}
                width="1.25rem"
                height="1.25rem"
                styleOverrides={{
                  opacity: 0.8,
                  fill: '#C3D7FD'
                }}
              />
            </Box>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center'
              }}
            >
              <Typography variant="h7Semibold" color="#344054">
                Pickup Code
              </Typography>
              <Typography variant="bodyMediumRegular" color="#667085">
                Enter pickup code to verify and complete pickup order
              </Typography>
            </Stack>
          </Stack>

          <Box
            sx={{
              mt: '1rem',
              padding: '1.25rem'
            }}
          >
            <FormInput<FormValues>
              control={control}
              name="pickup_code"
              label="PICKUP CODE"
            />
          </Box>

          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderTop: '0.0625rem solid #F5F6F7',
                padding: '1.25rem 1.5rem',
                marginBottom: '0.25rem'
              }}
            >
              <Button
                color="grey"
                transparent
                size="lg"
                text="Back"
                width="48%"
                onClick={() => setValidatePickupCodeModalOpen(false)}
              />
              <Button
                submit
                color="primary"
                size="lg"
                disabled={!isValid}
                text="Verify"
                width="48%"
                loadingIcon={isLoading}
              />
            </Box>
          </form>
        </>
      )}
    </>
  );
};

export default ValidatePickupCodeForm;
