import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress';

interface ProgressProps {
  value: number;
  height: number;
  color: string;
  borderRadius: number;
}

const LinearProgressBar = ({
  value,
  height,
  color,
  borderRadius
}: ProgressProps) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: height,
    borderRadius: borderRadius,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200]
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: borderRadius,
      backgroundColor: color
    }
  }));

  return (
    <Stack spacing={2} sx={{ flexGrow: 1 }}>
      <BorderLinearProgress variant="determinate" value={value} />
    </Stack>
  );
};

export default LinearProgressBar;
