import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

import WorkflowsBuilderStepsGroup from './workflowBuilderStepsGroup';

import { shadowsTheme } from 'themes/shadows';
import { SvgWrapper } from 'components/svg';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/svg/minus.svg';
import { ReactComponent as FourRoundCornersIcon } from 'assets/svg/four-round-corners.svg';
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef
} from 'react-zoom-pan-pinch';
import _ from 'lodash';

import WorkflowVersionResetModal from './workflowVersionResetModal';

const WorkflowsBuilderPage = () => {
  const [currentZoom, setCurrentZoom] = useState<number>(1);

  const handleZoomed = (ref: ReactZoomPanPinchRef) => {
    setCurrentZoom(ref.state.scale);
  };

  return (
    <>
      <WorkflowVersionResetModal />
      <TransformWrapper
        initialScale={1}
        minScale={0.5}
        maxScale={1}
        onZoomStop={handleZoomed}
        onTransformed={handleZoomed}
        wheel={{ disabled: true, excluded: ['input', 'textarea'] }}
        doubleClick={{ disabled: true, excluded: ['input', 'textarea'] }}
        panning={{ disabled: true, excluded: ['input', 'textarea'] }}
        pinch={{ disabled: true, excluded: ['input', 'textarea'] }}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <Box
            sx={{
              marginY: '3rem',
              maxHeight: '94%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1
            }}
          >
            <TransformComponent
              wrapperStyle={{ width: '100%', overflowY: 'auto' }}
              contentStyle={{ width: '100%' }}
            >
              <WorkflowsBuilderStepsGroup />
            </TransformComponent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '0.75rem',
                border: '1px solid #F2F4F7',
                opacity: '0.8',
                backgroundColor: '#FFF',
                boxShadow: shadowsTheme.buttonShadow,
                padding: '0.5rem',
                position: 'fixed',
                bottom: '2rem',
                right: '2rem'
              }}
            >
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                onClick={() => zoomOut(0.25, 0)}
              >
                <SvgWrapper
                  icon={MinusIcon}
                  width="1.25rem"
                  height="1.25rem"
                  styleOverrides={{
                    stroke: '#98A2B3'
                  }}
                />
              </Box>
              <Typography
                variant="bodyMediumSemibold"
                color="#475467"
                sx={{ marginX: '1rem' }}
              >
                {_.round(currentZoom * 100)}%
              </Typography>
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                onClick={() => zoomIn(0.25, 0)}
              >
                <SvgWrapper
                  icon={PlusIcon}
                  width="1.25rem"
                  height="1.25rem"
                  styleOverrides={{
                    stroke: '#98A2B3',
                    marginRight: '1.75rem'
                  }}
                />
              </Box>
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                onClick={() => resetTransform(0)}
              >
                <SvgWrapper
                  icon={FourRoundCornersIcon}
                  width="1.25rem"
                  height="1.25rem"
                  styleOverrides={{
                    stroke: '#98A2B3'
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}
      </TransformWrapper>
    </>
  );
};

export default WorkflowsBuilderPage;
