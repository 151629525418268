import { Scale } from 'chart.js';
import { CoreScaleOptions } from 'chart.js';
import { formattedDate } from 'utilities/helperFunc/formatter';

export function formatDateAxis(
  value: string | number,
  chart: Scale<CoreScaleOptions>
): string {
  return formattedDate(chart.getLabelForValue(Number(value)), 'MMM D');
}

export function formatPrcQuantityAxis(value: string | number): string {
  if (typeof value === 'number') {
    if (value >= 1000000000) {
      return '₦' + (value / 1000000000).toFixed(1) + 'B';
    } else if (value >= 1000000) {
      return '₦' + (value / 1000000).toFixed(1) + 'M';
    } else if (value >= 100000) {
      return '₦' + (value / 1000).toFixed(1) + 'K';
    } else {
      return '₦' + value.toString();
    }
  }
  return '₦' + value.toString();
}
