import React from 'react';
import AuthNavbar from 'pages/auth/common/authNav';
import waves from 'assets/svg/waves.svg';
import { theme } from 'themes/theme';
import { Box } from '@mui/material';
import { useMatch } from 'react-router-dom';

const AuthLayout: React.FunctionComponent<React.PropsWithChildren> = ({
  children
}) => {
  const match = useMatch('/complete-registration');

  return (
    <Box
      sx={{
        height: '100%',
        backgroundImage: `url(${waves})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: `${theme.palette.common.layoutBackground}`
      }}
    >
      {match ? null : <AuthNavbar />}

      <Box>{children}</Box>
    </Box>
  );
};

export default AuthLayout;
