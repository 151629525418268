import React, { useContext, useEffect } from 'react';
import fileupload from 'assets/svg/fileupload.svg';
import ellipse from 'assets/svg/ellipse.svg';
import { Button } from 'components/button';
import { theme } from 'themes/theme';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography, Stack, Avatar } from '@mui/material';
import { useForm, Resolver, SubmitHandler } from 'react-hook-form';
import FileView from 'components/common/fileView';
import { OnboardingContext } from '../context/onboardingContext';
import { orgStepFormValidator4 } from '../validators/onboardingValidators';
import { FormFileInput, FormInput } from 'components/form';

type FormValues = {
  logo?: unknown;
  business_name: string;
  street_address: string;
  postal_code: string;
  state: string;
  industry: string;
  locality: string;
};

const SetupOrganization = () => {
  const { handleNextStep, handlePrevStep, data, preview, setPreview } =
    useContext(OnboardingContext);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      logo: data.logo,
      business_name: data.business_name,
      street_address: data.street_address,
      postal_code: data.postal_code,
      industry: data.industry,
      state: data.state,
      locality: data.locality
    },
    resolver: yupResolver(
      orgStepFormValidator4
    ) as unknown as Resolver<FormValues>
  });

  const watchLogo = watch('logo', null);

  useEffect(() => {
    setPreview(watchLogo);
  }, [watchLogo]);

  const onSubmit: SubmitHandler<FormValues> = data => {
    handleNextStep(data);
  };

  return (
    <Box sx={{ width: { lg: '29.25rem' } }}>
      <Box width="inherit" sx={{ mb: '2rem', textAlign: 'center' }}>
        <Typography
          variant="h5"
          sx={{
            color: `${theme.palette.common.heading}`
          }}
        >
          Setup Organization
        </Typography>
        <Typography
          variant="subtitle2"
          width="inherit"
          sx={{
            color: `${theme.palette.common.labelColor}`,
            fontWeight: 400,
            lineHeight: '1.2rem',
            letterSpacing: '-0.02rem'
          }}
        >
          Create your organization to invite new members and assign them to
          teams
        </Typography>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {!preview ? (
          <Stack width="inherit" justifyContent="space-between" direction="row">
            <Stack
              width="13.68rem"
              alignItems="center"
              direction="row"
              gap="1rem"
            >
              <Avatar src={fileupload} />
              <Stack direction="column">
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: `${theme.palette.common.heading}`,
                    fontWeight: 600
                  }}
                >
                  Upload Org Logo
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: `${theme.palette.common.textColor}`,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '10rem'
                  }}
                >
                  <span style={{ fontWeight: 400 }}>PDF format</span>
                  <Box component="img" src={ellipse} />
                  <span>Max. 5MB</span>
                </Typography>
              </Stack>
            </Stack>

            <FormFileInput<FormValues>
              control={control}
              name="logo"
              label="Upload"
              setFile={(file: File | null) => setValue('logo', file)}
              supportedFiles="application/pdf"
              fileInputButtonStyle={{
                border: '1px solid #0D5EBA',
                borderRadius: '.5rem',
                color: `${theme.palette.common.white}`,
                backgroundColor: '#0D5EBA',
                padding: '.5rem .75rem',
                width: '6rem',
                fontWeight: 600,
                lineHeight: '1.26rem',
                letterSpacing: '-0.01313rem',
                '&:hover': {
                  border: '1px solid #0D5EBA',
                  color: `${theme.palette.common.white}`,
                  backgroundColor: '#0D5EBA'
                }
              }}
            />
          </Stack>
        ) : (
          <FileView preview={preview} removeFile={() => setPreview(null)} />
        )}

        <Box width="inherit" sx={{ mt: '1.5rem' }}>
          <FormInput<FormValues>
            control={control}
            name="business_name"
            label="BUSINESS"
          />

          <FormInput<FormValues>
            control={control}
            name="street_address"
            label="STREET ADDRESS"
          />

          <Stack width="100%" direction="column">
            <Stack width="100%" gap=".75rem" direction="row">
              <FormInput<FormValues>
                control={control}
                name="postal_code"
                label="POSTAL"
              />

              <FormInput<FormValues>
                control={control}
                name="industry"
                label="INDUSTRY"
              />
            </Stack>
            <Stack width="100%" gap=".75rem" direction="row">
              <FormInput<FormValues>
                control={control}
                name="state"
                label="STATE"
              />
              <FormInput<FormValues>
                control={control}
                name="locality"
                label="LOCALITY/AREA"
              />
            </Stack>
          </Stack>

          <Button
            submit
            color="primary"
            size="lg"
            disabled={!isValid}
            text="Next"
            width="100%"
            styleOverrides={{ marginBottom: '0.25rem' }}
          />
          <Button
            color="grey"
            outline
            size="lg"
            onClick={() => handlePrevStep()}
            text="Back"
            width="100%"
          />
        </Box>
      </form>
    </Box>
  );
};

export default SetupOrganization;
