import type { RawAxiosRequestHeaders } from 'axios';
import { isProductionEnvironment } from 'utilities/environment';

export const updateHeaderWithDevToken = (
  headers: RawAxiosRequestHeaders,
  token: string | null
) => {
  if (!isProductionEnvironment()) {
    // If token and headers are set, add to headers config
    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }
  }

  return headers;
};
