export const AccordionTheme = {
  styleOverrides: {
    root: {
      borderRadius: '0.75rem',
      border: '0.063rem solid #F5F6F7',
      backgroundColor: '#FCFCFD',
      boxShadow: 'none',
      width: '100%'
    }
  }
};
