import React from 'react';

import { Drawer, Stack, Typography } from '@mui/material';
import { TabContainer } from 'components/tabs';
import GeneralNotifications from './generalNotifications';
import { SvgWrapper } from 'components/svg';
import { ReactComponent as Opened } from 'assets/svg/opened.svg';
import { ReactComponent as Filter } from 'assets/svg/filterLines.svg';

interface DrawerProps {
  open: boolean;
  onClose: () => void;
}

const NotificationsDrawer: React.FC<DrawerProps> = ({ open, onClose }) => {
  const tabs = [
    {
      labelText: 'General',
      content: <GeneralNotifications />
    }
  ];

  const getStartingPoint = () => {
    const width = window.innerWidth;
    return (width / 12) * 2.3;
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          height: '100%',
          width: '29%',
          left: getStartingPoint(),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          boxShadow: 'none',
          border: '0.063rem solid #F2F4F7'
        }
      }}
      ModalProps={{
        keepMounted: false,
        sx: {
          '& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop': {
            background: 'none'
          }
        }
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        width="100%"
        padding="1rem 1.5rem"
        spacing={1}
        justifyContent="space-between"
      >
        <Typography variant="bodyMediumSemibold" color="#475467">
          Activity Feed
        </Typography>
        <Stack direction="row" alignItems="center">
          <SvgWrapper
            icon={Opened}
            width="1rem"
            height="1rem"
            styleOverrides={{ fill: '#98A2B3', mr: '0.5rem' }}
          />
          <Typography variant="bodyMediumMedium" color="#475467">
            Mark all as read
          </Typography>
          <SvgWrapper
            icon={Filter}
            width="0.75rem"
            height="0.75rem"
            styleOverrides={{ fill: 'none', stroke: '#667085', ml: '1.25rem' }}
          />
        </Stack>
      </Stack>
      <TabContainer
        variant="fullWidth"
        tabs={tabs}
        styleOverrides={{
          height: 'inherit'
        }}
      />
    </Drawer>
  );
};

export default NotificationsDrawer;
