import React from 'react';
import {
  Box,
  Typography,
  Stack,
  Card,
  CardMedia,
  CardContent
} from '@mui/material';
import { theme } from 'themes/theme';
import workflow from 'assets/png/onboarding-center/workflow.png';
import roles from 'assets/png/onboarding-center/roles.png';
import { ReactComponent as UsersIcon } from 'assets/svg/users.svg';
import { ReactComponent as WorkflowIcon } from 'assets/svg/workflow.svg';
import { ReactComponent as ChevronRightDoubleIcon } from 'assets/svg/chevron-right-double.svg';
import { PendingActions } from './pendingActions';
import { useGetUserQuery } from 'services/api/userApi';
import { SvgWrapper } from 'components/svg';
import { Button } from 'components/button';

const OnboardingCenterPage = () => {
  const { data } = useGetUserQuery({});

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Stack gap=".25rem" width="inherit" sx={{ textAlign: 'center' }}>
        <Typography
          variant="subtitle2"
          sx={{
            color: `${theme.palette.common.subheading}`,
            fontWeight: 600,
            lineHeight: '1.05rem',
            letterSpacing: '0.07rem',
            textTransform: 'uppercase'
          }}
        >
          Welcome {data?.firstname}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: `${theme.palette.common.textColor}`
          }}
        >
          Let’s setup your TafiCasa experience with these steps
        </Typography>
      </Stack>
      <Box
        sx={{
          marginTop: '53px',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'stretch'
        }}
      >
        <Card
          sx={{
            display: 'flex',
            height: '172px',
            width: '48%',
            marginRight: '0.5%',
            borderRadius: '12px',
            border: '1.5px solid #F2F4F7',
            backgroundColor: '#FCFCFD',
            boxShadow: 'none'
          }}
        >
          <CardContent
            sx={{
              width: '100%',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignSelf: 'stretch'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '16px'
              }}
            >
              <SvgWrapper
                icon={WorkflowIcon}
                width="1rem"
                height="1rem"
                styleOverrides={{ fill: '#667085' }}
              />
              <Typography
                variant="bodyLargeSemibold"
                component="div"
                color="#475467"
                sx={{ marginLeft: '8px' }}
              >
                Workflows
              </Typography>
            </Box>
            <Typography
              sx={{ marginBottom: '12px', width: '225px', height: '40px' }}
              variant="bodyMediumMedium"
              color="#98A2B3"
              component="div"
            >
              Replicate your existing processes using workflows for efficiency
            </Typography>
            <Button color="grey" transparent size="sm" text="Learn More" />
          </CardContent>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <CardMedia
              component="img"
              sx={{
                height: '100%',
                width: '100%'
              }}
              src={workflow}
              alt="workflows"
            />
          </Box>
        </Card>
        <Card
          sx={{
            display: 'flex',
            height: '172px',
            width: '48%',
            marginLeft: '0.5%',
            borderRadius: '12px',
            border: '1.5px solid #F2F4F7',
            backgroundColor: '#FCFCFD',
            boxShadow: 'none'
          }}
        >
          <CardContent
            sx={{
              width: '100%',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignSelf: 'stretch'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '16px'
              }}
            >
              <SvgWrapper
                icon={UsersIcon}
                width="1rem"
                height="1rem"
                styleOverrides={{ fill: '#667085' }}
              />
              <Typography
                variant="bodyLargeSemibold"
                component="div"
                color="#475467"
                sx={{ marginLeft: '0.5rem' }}
              >
                Roles
              </Typography>
            </Box>
            <Typography
              sx={{ marginBottom: '12px', width: '225px', height: '40px' }}
              variant="bodyMediumMedium"
              color="#98A2B3"
              component="div"
            >
              Define permissions and control user access with ease using roles
            </Typography>
            <Button color="grey" transparent size="sm" text="Learn More" />
          </CardContent>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <CardMedia
              component="img"
              sx={{
                height: '100%',
                width: '100%'
              }}
              src={roles}
              alt="roles"
            />
          </Box>
        </Card>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'stretch',
          padding: '8px',
          backgroundColor: '#FFF',
          marginTop: '12px',
          width: '100%',
          border: '1px solid #F2F4F7',
          borderRadius: '8px'
        }}
      >
        <Typography
          variant="bodyMediumSemibold"
          component="div"
          color="#667085"
        >
          See all tutorials to get you started
        </Typography>
        <Button
          color="primary"
          transparent
          size="sm"
          text="Go to learning center"
          icon={ChevronRightDoubleIcon}
          iconPosition="end"
        />
      </Box>
      <PendingActions />
    </Box>
  );
};

export default OnboardingCenterPage;
