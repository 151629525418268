import { SvgIcon } from '@mui/material';
import React from 'react';
import type { SxProps } from '@mui/material';
import _ from 'lodash';

interface SvgProps {
  height: string;
  width: string;
  color?: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  styleOverrides?: SxProps;
}

/**
 * Note if you are using this wrapper and the SVG colors do not
 * change or appears faded please makes sure the svg file does
 * not contain any fill, stroke or opacity attributes in the child path elements
 *
 * Also make sure that on the top level svg element the fill value is set to 'none' and
 * it does not contain any opacity attribute.
 */
const SvgWrapper: React.FC<SvgProps> = ({
  height,
  width,
  color,
  icon,
  styleOverrides
}) => {
  const style = {
    fill: color,
    height: height,
    width: width
  };

  // If style overrides are passed then use to override existing styles
  if (styleOverrides) {
    _.assign(style, styleOverrides);
  }
  return (
    <SvgIcon
      component={icon}
      htmlColor={color}
      height={height}
      width={width}
      inheritViewBox
      sx={style}
    />
  );
};
export default SvgWrapper;
