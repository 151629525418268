import React from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { ReactComponent as Dot } from 'assets/svg/dot.svg';
import { ReactComponent as Stars } from 'assets/custom-svg/stars.svg';
import { SvgWrapper } from 'components/svg';
import { Chip } from 'components/chip';
import { RoleRequestApiData } from 'services/types/permission';

const RequestDetails = ({
  roleRequest
}: {
  roleRequest: RoleRequestApiData;
}) => {
  return (
    <Box
      sx={{
        width: 'inherit',
        maxWidth: '80rem',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Paper
        elevation={0}
        sx={{
          padding: '1.25rem',
          gap: '1rem',
          width: 'auto'
        }}
      >
        <Stack
          width="30rem"
          direction="row"
          display="flex"
          justifyContent="space-between"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '2.25rem',
              width: '2.25rem',
              borderRadius: '6.25rem',
              backgroundColor: '#FBFCFE',
              padding: '0.5rem',
              marginBottom: '1rem'
            }}
          >
            <SvgWrapper
              icon={Stars}
              width="1.25rem"
              height="1.25rem"
              styleOverrides={{ opacity: 0.8 }}
              color="#2393C4"
            />
          </Box>

          <Chip label="Role Request" size="sm" icon />
        </Stack>

        <Stack
          width="30rem"
          direction="row"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{
            mt: '0.5rem'
          }}
        >
          <Stack
            direction="column"
            display="flex"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="h6Bold" color="#475467">
              {roleRequest.role.name}
            </Typography>

            <Stack
              width="100%"
              direction="column"
              display="flex"
              justifyContent="space-between"
              mt="1rem"
            >
              {roleRequest.role.permissions.map(permission => {
                return (
                  <Box
                    key={permission}
                    sx={{
                      display: 'flex',
                      padding: '.75rem 0.5rem',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      mb: '.5rem',
                      background: '#FBFCFC',
                      borderRadius: '.5rem',
                      width: 'inherit'
                    }}
                  >
                    <SvgWrapper
                      width=".5rem"
                      height=".5rem"
                      icon={Dot}
                      styleOverrides={{
                        mr: '1rem',
                        fill: '#EAECF0'
                      }}
                    />
                    <Typography variant="bodyMediumMedium" color="#475467">
                      {permission.replace(/_/g, ' ')}
                    </Typography>
                  </Box>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};

export default RequestDetails;
