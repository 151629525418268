import React, { useEffect } from 'react';
import { Box, Tab as MUITab, Tabs as MUITabs, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as User } from 'assets/svg/user.svg';
import { ReactComponent as Users } from 'assets/svg/users-solid.svg';
import { ReactComponent as LockOpen } from 'assets/svg/lock-open-solid.svg';
import { SvgWrapper } from 'components/svg';
import PermissionsTable from './tables/permissionsTable';
import RolesTable from './tables/rolesTable';
import AccessRequestsPage from './requests/accessRequestsPage';
import _ from 'lodash';

const tabList = ['users', 'roles', 'requests'];

const PermissionsPage = () => {
  const navigate = useNavigate();
  const { tab } = useParams();
  const location = useLocation();
  const currentTab = tabList.findIndex(tab => location.pathname.endsWith(tab));

  const handleChange = (newTabIndex: number) => {
    navigate(`/settings/permissions/${tabList[newTabIndex]}`);
  };

  const isActiveTab = (tabId: string) => {
    return tabId === tabList[currentTab];
  };

  useEffect(() => {
    if (!tab) {
      navigate('/settings/permissions/users', { replace: true });
    }
  }, [navigate, tab]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <MUITabs
        orientation="vertical"
        value={Math.max(0, currentTab)}
        onChange={(e, value) => handleChange(value)}
        sx={{
          marginBottom: '1.75rem',
          display: 'flex',
          justifyContent: 'space-between',
          width: '15%',
          '& .MuiTabs-indicator': {
            backgroundColor: 'transparent'
          }
        }}
      >
        {tabList.map((tabId, index) => (
          <MUITab
            key={tabId}
            label={
              <Box
                sx={{
                  display: 'flex',
                  gap: '.5rem',
                  alignItems: 'flex-start',
                  width: '100%'
                }}
              >
                <SvgWrapper
                  icon={
                    tabId === 'users'
                      ? User
                      : tabId === 'roles'
                        ? Users
                        : LockOpen
                  }
                  width="1rem"
                  height="1rem"
                  styleOverrides={{
                    fill: currentTab === index ? '#3E7DF8' : '#D0D5DD'
                  }}
                />
                <Typography variant="bodyMediumMedium">
                  {tabId === 'users'
                    ? 'Users'
                    : tabId === 'requests'
                      ? 'Access Requests'
                      : _.startCase(tabId)}
                </Typography>
              </Box>
            }
            disableRipple
          />
        ))}
      </MUITabs>

      <Box
        sx={{
          maxHeight: '100%',
          overflowY: 'auto',
          paddingBottom: '4rem',
          width: 'inherit',
          padding: {
            lg: '0rem 6rem',
            xl: '0rem 10rem'
          }
        }}
      >
        <Box
          role="tabpanel"
          hidden={!isActiveTab('users')}
          sx={{ width: '100%' }}
        >
          <PermissionsTable />
        </Box>
        <Box
          role="tabpanel"
          hidden={!isActiveTab('roles')}
          sx={{ width: '100%' }}
        >
          <RolesTable />
        </Box>
        <Box
          role="tabpanel"
          hidden={!isActiveTab('requests')}
          sx={{ width: '100%' }}
        >
          <AccessRequestsPage />
        </Box>
      </Box>
    </Box>
  );
};

export default PermissionsPage;
